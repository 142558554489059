export const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");

    if (number.length < 4) return number;
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};
export const stripPhoneNumberAutoFormat = (phoneNumber) => {
    const strippedBeginningParentheses = phoneNumber.replace(/\(/g, '')
    const strippedEndingParentheses = strippedBeginningParentheses.replace(/\)/g, '')
    const strippedSpaces = strippedEndingParentheses.replace(/\s/g, '')
    const strippedDashes = strippedSpaces.replace(/-/g, '')

    return strippedDashes;
}
export const formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        let intlCode = (match[1] ? '1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}
export const formatPhoneNumberDashes = (phoneNumberString) => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
    }
    return null;
}

export const stripSsnAutoFormat = (ssn) => {
    const strippedBeginningParentheses = ssn.replace(/\(/g, '')
    const strippedEndingParentheses = strippedBeginningParentheses.replace(/\)/g, '')
    const strippedSpaces = strippedEndingParentheses.replace(/\s/g, '')
    const strippedDashes = strippedSpaces.replace(/-/g, '')

    return strippedDashes;
}
export const ssnAutoFormat = (ssn) => {
    const number = ssn.trim().replace(/[^0-9]/g, "");
    if (number.length < 3) {
        return number
    };
    if (number.length < 6) {
        return number.replace(/(\d{3})(\d{1})/, "$1-$2");
    }
    if (number.length < 9) {
        return number.replace(/(\d{3})(\d{2})(\d{1})/, "$1-$2-$3");
    }
    return number.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
};