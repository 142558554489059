// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ReactCalendar from 'react-calendar';
import moment from 'moment';
// Components
import ConditionalRender from '../components/ConditionalRender';

// Props of the Component
const propTypes = {
    onChange: PropTypes.func,
    onSelectRender: PropTypes.func,
    sideWindow: PropTypes.func,
    dateKey: PropTypes.string, 
    formatFunction: PropTypes.func
};
const defaultProps = {
    onChange: () => {},
    onSelectRender: () => { return <></> },
    sideWindow: () => { return null },
    dateKey: "date",
    formatFunction: (value) => { return value }
};

function Calendar({ onChange, availableDates, onSelectRender, sideWindow, dateKey, formatFunction }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [activeDate, setActiveDate] = useState("");
    const [defaultDate, setDefaultDate] = useState(null);

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Calendar/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Calendar/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Calendar.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Calendar.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Calendar/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Calendar/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        if (moment().toDate() !== moment(availableDates?.[0]?.[dateKey]).toDate()) {
            setDefaultDate(moment(availableDates?.[0]?.[dateKey]))
        }
    }, [availableDates])

    return (
        <div className={classNames.container}>
            <ConditionalRender 
                falseReturn={
                    <ReactCalendar 
                        className={classNames.calendar}
                        next2Label={null} 
                        prev2Label={null} 
                        minDetail="month" 
                        onChange={(value, event) => { 
                            const mmddyyyy = formatFunction(value)
                            
                            if (availableDates.filter(value => formatFunction(value[dateKey]) === mmddyyyy).length > 0) {
                                setActiveDate(mmddyyyy)
                                onChange(value, event) 
                            }
                        }} 
                        formatShortWeekday={(locale, date) => { 
                            const day = new Date(date).toLocaleDateString(locale, { weekday: "short" }).toUpperCase();
                            return day
                        }}
                        tileClassName={({ activeStartDate, date, view }) => {
                            const mmddyyyy = formatFunction(date)
                            
                            if (activeDate === mmddyyyy) {
                                return "active-date"
                            } else if (availableDates.filter(value => formatFunction(value[dateKey]) === mmddyyyy).length > 0) {
                                return "available-date"
                            } else {
                                return ""
                            }
                        }}
                    />
                }
                condition={defaultDate !== null}
            >
                <ReactCalendar 
                    className={classNames.calendar}
                    defaultActiveStartDate={defaultDate?.toDate()}
                    next2Label={null} 
                    prev2Label={null} 
                    minDetail="month" 
                    onChange={(value, event) => { 
                        const mmddyyyy = formatFunction(value)
                        
                        if (availableDates.filter(value => formatFunction(value[dateKey]) === mmddyyyy).length > 0) {
                            setActiveDate(mmddyyyy)
                            onChange(value, event) 
                        }
                    }} 
                    formatShortWeekday={(locale, date) => { 
                        const day = new Date(date).toLocaleDateString(locale, { weekday: "short" }).toUpperCase();
                        return day
                    }}
                    tileClassName={({ activeStartDate, date, view }) => {
                        const mmddyyyy = formatFunction(date)
                        
                        if (activeDate === mmddyyyy) {
                            return "active-date"
                        } else if (availableDates.filter(value => formatFunction(value[dateKey]) === mmddyyyy).length > 0) {
                            return "available-date"
                        } else {
                            return ""
                        }
                    }}
                />
            </ConditionalRender>
            <ConditionalRender condition={activeDate !== ""}>
                {onSelectRender(activeDate)}
            </ConditionalRender>
            <ConditionalRender condition={sideWindow(activeDate) !== null}>
                {sideWindow(activeDate)}
            </ConditionalRender>
        </div>
    );
}

Calendar.propTypes = propTypes;
Calendar.defaultProps = defaultProps;

export default Calendar;
