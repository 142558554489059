// Packages
import React from 'react'
// Helpers
import initialState from './initialState';

const ConfigReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CONFIG':
            return action.payload
        case 'CLEAR':
            return initialState;
        default:
            return state;
    }
};

export default ConfigReducer;
