// Helpers
import initialState from './initialState'

const QueryParamsReducer = (state, action) => {
    switch (action.type) {
        case 'SET':
            return action.payload
        case 'SET_DEFAULT':
            return initialState
        default:
            return state
    }
}

export default QueryParamsReducer