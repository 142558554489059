// Packages
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// UI
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
import Button from '../../../ui/Button';
import DisplayPhoneNumber from '../../../ui/DisplayPhoneNumber';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import FreeconnectCallNowContent from '../../../ui/FreeconnectCallNowContent';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    onNext: () => { },
    onPrev: () => { },
};

function CallNow({ step, onNext, onPrev }) {
    const { userId, company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const environmentState = useContext(EnvironmentContext)[0];
    const [callNumber, setCallNumber] = useState("0")
    const callNumberOptions = [
        "2059056031", // optimum
        "2292100826" // windstream
    ]

    useEffect(() => {
        import(/* webpackMode: "eager" */ `../styles/steps/CallNow/index.less`).catch((error) => {
            console.log(error)
        });
        import(/* webpackMode: "eager" */ `../styles/steps/CallNow/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch((error) => console.log(error))
    }, [company]);

    useEffect(() => {
        let body = { userId }
        const headers = {
            Authorization: company,
            "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
        }
        fetch(environmentState.backendUrlToSubmitData, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        })
            .catch((error) => {
                console.error(error);
            });
    }, [])

    useEffect(() => {
        if (formData.serviceableCompany === 'optimum') {
            setCallNumber(callNumberOptions[0])
        }
        if (formData.serviceableCompany === 'windstream') {
            setCallNumber(callNumberOptions[1])
        } else {
            setCallNumber(callNumberOptions[0])
        }
    }, [formData.serviceableCompany])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', flexGrow: '1' }}>
            <div>
                <HeadingText>Last Step</HeadingText>
                <SubHeadingText>Now that we have all the boring stuff out of the way, call us at the number below to pick an installation date for your new FREE Internet.</SubHeadingText>
                <FreeconnectCallNowContent companyProp={formData.serviceableCompany} />
            </div>
            <div className={classNames.bottom}>
                <Button
                    style="nextNoBottomMargin"
                    onClick={() => { 
                        const headers = {
                            Authorization: company,
                            "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                        }
                        fetch(environmentState.backendUrlToSetData, {
                            method: 'POST',
                            headers,
                            body: JSON.stringify({ userId, clicked_demo_call_btn: true })
                        })
                        window.open(`tel:${callNumber}`); 
                    }}>CALL NOW</Button>
                    <DisplayPhoneNumber onClick={() => {
                        const headers = {
                            Authorization: company,
                            "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                        }
                        fetch(environmentState.backendUrlToSetData, {
                            method: 'POST',
                            headers,
                            body: JSON.stringify({ userId, clicked_demo_call_btn: true })
                        })
                    }}>{callNumber}</DisplayPhoneNumber>
            </div>
        </div>
    );
}

CallNow.propTypes = propTypes;
CallNow.defaultProps = defaultProps;

export default CallNow;