// Packages
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import Loading from './Loading';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};
const defaultProps = {
    children: null,
};

function LoadingBlocker({ children }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/LoadingBlocker/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/LoadingBlocker/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/LoadingBlocker.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/LoadingBlocker.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/LoadingBlocker/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/LoadingBlocker/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <>
            <div className={classNames.blocker} />
            <div className={classNames.loadingBlocker}>
                <Loading size="large" />
                <ConditionalRender condition={children}>
                    {children}
                </ConditionalRender>
            </div>
        </>
    );
}

LoadingBlocker.propTypes = propTypes;
LoadingBlocker.defaultProps = defaultProps;

export default LoadingBlocker;
