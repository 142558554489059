// Helpers
import initialState from './initialState';

const EnvironmentReducer = (state, action) => {
    switch (action.type) {
        case 'SET_INITIAL':
            return initialState;
        case 'SET_BACKEND_URL_TO_GET_DATA':
            return { ...state, backendUrlToGetData: action.payload };
        case 'SET_BACKEND_URL_TO_SET_DATA':
            return { ...state, backendUrlToSetData: action.payload };
        case 'SET_BACKEND_URL_TO_SUBMIT_DATA':
            return { ...state, backendUrlToSubmitData: action.payload };
        case 'SET_DISCLOSURES_BACKEND_URL_TO_GET_DATA':
            return { ...state, disclosuresBackendUrlToGetData: action.payload };
        case 'SET_SMARTY_URL':
            return { ...state, smartyUrl: action.payload };
        case 'SET_VCARE_URL':
            return { ...state, vcareUrl: action.payload };
        case 'SET_MARKETPLACE_SERVICEABILITY_URL':
            return { ...state, marketplaceServiceabilityBackendUrlToCheck: action.payload };
        case 'SET_KORE_SET_URL':
            return { ...state, backendUrlToSetDataForKore: action.payload };
        case 'SET_SECURE_PII_URL':
            return { ...state, backendUrlToSetDataForSecurePii: action.payload };
        case 'SET_FRONTIER_PREDICTIVE_SEARCH_URL':
            return { ...state, frontierPredictiveSearchUrl: action.payload };
        case 'SET_FRONTIER_GET_OFFERS_BY_ADDRESS_URL':
            return { ...state, frontierGetOffersByAddressUrl: action.payload };
        case 'SET_FRONTIER_ELIGIBILITY_CHECK_URL':
            return { ...state, frontierEligibilityCheckUrl: action.payload };
        case 'SET_FRONTIER_GET_OFFERS_LOW_INCOME_URL':
            return { ...state, frontierGetOffersLowIncomeUrl: action.payload };
        case 'SET_FRONTIER_QUOTE_ITEM_POST_URL':
            return { ...state, frontierQuoteItemPostUrl: action.payload };
        case 'SET_FRONTIER_QUOTE_ITEM_PUT_URL':
            return { ...state, frontierQuoteItemPutUrl: action.payload };
        case 'SET_FRONTIER_GET_DISCLOSURES_URL':
            return { ...state, frontierGetDisclosuresUrl: action.payload };
        case 'SET_FRONTIER_ACCEPT_DISCLOSURES_URL':
            return { ...state, frontierAcceptDisclosuresUrl: action.payload };
        case 'SET_FRONTIER_CREATE_QUOTE_URL':
            return { ...state, frontierCreateQuoteUrl: action.payload };
        case 'SET_FRONTIER_LOW_INCOME_BY_APP_URL':
            return { ...state, frontierLowIncomeByAppUrl: action.payload };
        case 'SET_FRONTIER_LOW_INCOME_VERIFICATION_URL':
            return { ...state, frontierLowIncomeVerificationUrl: action.payload };
        case 'SET_FRONTIER_FINISH_QUOTE_CREATE_URL':
            return { ...state, frontierFinishQuoteCreateUrl: action.payload };
        case 'SET_FRONTIER_CLOSE_SELECT_OFFERS_URL':
            return { ...state, frontierCloseSelectOffersUrl: action.payload };
        case 'SET_FRONTIER_CUSTOMER_CREDIT_CHECK_URL':
            return { ...state, frontierCustomerCreditCheckUrl: action.payload };
        case 'SET_FRONTIER_CUSTOMER_IDENTITY_VERIFICATION_URL':
            return { ...state, frontierCustomerIdentityVerificationUrl: action.payload };
        case 'SET_FRONTIER_VERIFY_CUSTOMER_PREVIOUS_ADDRESS_URL':
            return { ...state, frontierVerifyCustomerPreviousAddressUrl: action.payload };
        case 'SET_FRONTIER_CUSTOMER_CREDIT_BUNDLE_URL':
            return { ...state, frontierCustomerCreditBundleUrl: action.payload };
        case 'SET_FRONTIER_GET_QUOTE_URL':
            return { ...state, frontierGetQuoteUrl: action.payload };
        case 'SET_FRONTIER_GET_DEPOSIT_REQUIREMENTS_URL':
            return { ...state, frontierGetDepositRequirementsUrl: action.payload };
        case 'SET_FRONTIER_GET_SCHEDULE_URL':
            return { ...state, frontierGetScheduleUrl: action.payload };
        case 'SET_FRONTIER_FINISH_RESERVE_SCHEDULE_URL':
            return { ...state, frontierFinishReserveScheduleUrl: action.payload };
        case 'SET_FRONTIER_SUBMIT_CUSTOMER_JOURNEY_URL':
            return { ...state, frontierSubmitCustomerJourneyUrl: action.payload };
        case 'SET_FRONTIER_GET_OFFERS_DISPLAY_URL':
            return { ...state, frontierGetOffersDisplay: action.payload }
        case 'SET_LIVE_VOX_API_URL':
            return { ...state, liveVoxApiUrl: action.payload };
        case 'SET_OPTIMUM_SERVICEABILITY':
            return { ...state, optimumServiceability: action.payload };
        case 'SET_ACP_ID':
            return { ...state, acpId: action.payload };
        case 'SET_FCUS_SERVICEABILITY_CHECK':
            return { ...state, fcusServiceability: action.payload };
        case 'SET_SPECTRUM_SERVICEABILITY_CHECK':
            return { ...state, spectrumServiceability: action.payload };
        case 'SET_FIXED_REDIRECT_URL':
            return { ...state, fixedRedirect: action.payload };
        case 'SET_ACTIVE_PROSPECT_URL':
            return { ...state, activeProspect: action.payload };
        case 'SET_GET_PARAMETER_URL':
            return { ...state, backendUrlGetParameter: action.payload };
        case 'SET_CNX1_URL':
            return { ...state, backendUrlCNX1: action.payload };
        case 'SET_PDF_GEN':
            return { ...state, pdfGenUrl: action.payload };
        case 'SET_UTILITY_URL':
            return { ...state, utilityApi: action.payload }
        case 'SET_ACE_DB_URL':
            return { ...state, aceUsersDbApi: action.payload }
        case 'SET_KLAVIYO_URL':
            return { ...state, backendUrlKlaviyo: action.payload }
        case 'SET_META_CONVERSION_API':
            return { ...state, metaConversionApi: action.payload }
        case 'SET_CTAM_REPORTING_URL':
            return { ...state, backendUrlCTAMReporting: action.payload };
        case 'SET_COMPLIANCE_ORDER_SUBMISSION_URL':
            return { ...state, backendUrlComplianceOrderSubmission: action.payload };
        case 'SET_SES_EMAIL_URL':
            return { ...state, backendUrlSesEmail: action.payload };
        default:
            return state;
    }
};

export default EnvironmentReducer;
