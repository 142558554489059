// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Props of the Component
const propTypes = {
    className: PropTypes.string,
    onCheck: PropTypes.func,
    initialValue: PropTypes.bool
};
const defaultProps = {
    children: "",
    className: '',
    onCheck: () => {},
    initialValue: false
};

function BodyText({ className, onCheck, initialValue }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [checked, setChecked] = useState(initialValue);

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Switch/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Switch/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Switch.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Switch.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Switch/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Switch/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <label className={`${classNames.label} ${className}`}>
            <input 
                checked={checked}
                type="checkbox" 
                onChange={() => { 
                    onCheck(!checked) 
                    setChecked(prev => !prev)
                }} 
            />
            <span className={classNames.slider} />
        </label>
    );
}

BodyText.propTypes = propTypes;
BodyText.defaultProps = defaultProps;

export default BodyText;
