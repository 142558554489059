// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ConditionalRender from '../components/ConditionalRender';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
// UI
import BodyText from './BodyText'

// Props of the Component
const propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    setValueTo: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]))),
    initialValue: PropTypes.string,
    setIsValidStateTo: PropTypes.bool,
    setValidationMessageTo: PropTypes.string,
    fullWidth: PropTypes.bool,
    noBottomMargin: PropTypes.bool
};
const defaultProps = {
    onChange: () => {},
    children: '',
    setValueTo: undefined,
    className: '',
    inputClassName: '',
    options: [],
    initialValue: '',
    setIsValidStateTo: null,
    setValidationMessageTo: null,
    fullWidth: false,
    noBottomMargin: false
};

// TODO: left margin for label
function DropDown({ onChange, name, children, setValueTo, className, inputClassName, options, initialValue, setIsValidStateTo, setValidationMessageTo, fullWidth, noBottomMargin }) {
    const { company } = useParams();
    const [value, setValue] = useState(initialValue);
    const [isValidState, setIsValidState] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');

    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/DropDown/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/DropDown/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/DropDown.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/DropDown.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/DropDown/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/DropDown/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        if (setValueTo !== undefined) {
            setValue(setValueTo);
        }
    }, [setValueTo]);

    useEffect(() => {
        if (setIsValidStateTo !== null) {
            setIsValidState(setIsValidStateTo)
        }
    }, [setIsValidStateTo])
    useEffect(() => {
        if (setValidationMessageTo !== null) {
            setValidationMessage(setValidationMessageTo)
        }
    }, [setValidationMessageTo])

    return (
        <ConditionalRender
            condition={children}
            falseReturn={
                <div className={`${classNames.label} ${noBottomMargin ? classNames.noBottomMargin : ''} ${className}`}>
                    <select
                        className={`${classNames.dropdown} ${fullWidth ? classNames.fullWidth : ""} ${inputClassName} ${!isValidState ? classNames.dropDownError : ""}`}
                        type="text"
                        name={name}
                        value={value}
                        onChange={(event) => {
                            onChange(event.target.value);
                            setValue(event.target.value);
                        }}
                    >
                        {options.map((optionObject, i) => (
                            <option key={i} value={optionObject.value}>{optionObject.name}</option>
                        ))}
                    </select>
                    <ConditionalRender condition={!isValidState}>
                        <BodyText className={classNames.validationMessage}>
                            <div style={{ display: "flex" }}>
                                <div style={{  height: "1em", width: "1em", marginTop: "2px" }}><AiOutlineExclamationCircle /></div><div>&nbsp;</div>{validationMessage}
                            </div>
                        </BodyText>
                    </ConditionalRender>
                </div>
            }
        >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className={`${classNames.label} ${noBottomMargin ? classNames.noBottomMargin : ''} ${className}`}>
                <div className={classNames.labelText}>{children}</div>
                <select
                    className={`${classNames.dropdown} ${fullWidth ? classNames.fullWidth : ""} ${inputClassName} ${!isValidState ? classNames.dropDownError : ""}`}
                    type="text"
                    name={name}
                    value={value}
                    onChange={(event) => {
                        onChange(event.target.value);
                        setValue(event.target.value);
                    }}
                >
                    {options.map((optionObject, i) => (
                        <option key={i} value={optionObject.value}>{optionObject.name}</option>
                    ))}
                </select>
                <ConditionalRender condition={!isValidState}>
                    <BodyText className={classNames.validationMessage}>
                        <div style={{ display: "flex" }}>
                            <div style={{  height: "1em", width: "1em", marginTop: "2px" }}><AiOutlineExclamationCircle /></div><div>&nbsp;</div>{validationMessage}
                        </div>
                    </BodyText>
                </ConditionalRender>
            </label>
        </ConditionalRender>
    );
}

DropDown.propTypes = propTypes;
DropDown.defaultProps = defaultProps;

export default DropDown;
