const classNames = {
    label: 'label-checkbox',
    checkbox: 'checkbox',
    checkmark: 'checkmark',
    errorLabel: 'label-checkbox-error',
    text: 'label-text-checkbox',
    errorCheckbox: '',
    validationMessage: 'checkbox-validation-message',
    container: 'container-checkbox',
    popUpContent: 'popup-content-textfield',
    infoButton: 'textfield-info-button'
};

export default classNames;
