// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender'
// UI
import LoadingBlocker from '../../../ui/LoadingBlocker'
import SuperHeadingText from '../../../ui/SuperHeadingText';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';


// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function LoadingStep({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [startedTimeout, setStartedTimeout] = useState(false);
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [isLoading, isLoadingDispatch] = useContext(IsLoadingContext);
    const [steps, stepsDispatch] = useContext(StepsContext);
    const environment = useContext(EnvironmentContext)[0];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Loading/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Loading/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    useEffect(() => {
        if (!startedTimeout) {
            setTimeout(() => {
                onNext()
            }, 3500);
            setStartedTimeout(true)
        }
    }, [])

    return (
        <LoadingBlocker>
            <div className={classNames.loadingAnimation}>
                <div>
                    <SuperHeadingText>Getting best offers...</SuperHeadingText>
                    <SuperHeadingText>Fetching the free...</SuperHeadingText>
                    <SuperHeadingText>Confirming service...</SuperHeadingText>
                    <SuperHeadingText>Getting best offers...</SuperHeadingText>
                </div>
            </div>
        </LoadingBlocker>
    )
}

LoadingStep.propTypes = propTypes;
LoadingStep.defaultProps = defaultProps;

export default LoadingStep;