// Packages
import React from 'react';
// Steps
import Address from '../../../default/steps/Address';
import DetermineCompany from '../../steps/DetermineCompany';
import Programs from '../../../default/steps/Programs';
import CheckPrograms from '../../steps/CheckPrograms';
import PII from '../../../default/steps/PII';
import ConfirmAddress from '../../steps/ConfirmAddress';
import Disclosures from '../../steps/Disclousres';
import Congrats from '../../steps/Congrats';
import LoadingStep from '../../steps/Loading';
import WaitForServiceability from '../../steps/WaitForServiceability';
import FrontierCommunication from '../../steps/FrontierCommunication';
import FrontierNV from '../../steps/FrontierNV';
import FrontierOffers from '../../steps/FrontierOffers';
import FrontierScheduling from '../../steps/FrontierScheduling';
import FrontierSuccess from '../../steps/FrontierSuccess';
import CallNow from '../../steps/CallNow';
import FrontierError from '../../steps/FrontierError';
import FrontierSecurityQuestions from '../../steps/FrontierSecurityQuestions';
import FrontierPositiveId from '../../steps/FrontierPositiveId';
import FrontierPreviousAddress from '../../steps/FrontierPreviousAddress';
// Helpers
import { addressRequest, piiRequestFreeconnect, programsRequest, frontierCommunicationRequestFreeconnect, frontierAcceptDisclosures, frontierFinishReserveSchedule, frontierCustomerIdentityVerification, frontierVerifyCustomerPreviousAddress} from '../../../../helpers/backendRequestGenerators';

const steps = async (company) => {
    let AddressComponent = { default: Address };
    let DetermineCompanyComponent = { default: DetermineCompany };
    let ProgramsComponent = { default: Programs };
    let CheckProgramsComponent = { default: CheckPrograms };
    let PIIComponent = { default: PII };
    let ConfirmAddressComponent = { default: ConfirmAddress };
    let DisclosuresComponent = { default: Disclosures };
    let CongratsComponent = { default: Congrats }
    let LoadingComponent = { default: LoadingStep }
    let WaitForServiceabilityComponent = { default: WaitForServiceability }

    // Address
    try {
        AddressComponent = await import(/* webpackPreload: true */ `../../../../companies/${company}/steps/Address.jsx`)
    } catch (error) {
        // Do Nothing
    }

    // Programs
    try {
        ProgramsComponent = await import(/* webpackPreload: true */ `../../../../companies/${company}/steps/Programs.jsx`)
    } catch (error) {
        // Do Nothing
    }

    // PII
    try {
        PIIComponent = await import(/* webpackPreload: true */ `../../../../companies/${company}/steps/PII.jsx`)
    } catch (error) {
        // Do Nothing
    }

    return {
        metaData: { 
            ProgressStepperStyle: true, 
            subtractFromStepsLength: 2, 
            returnAmountToSubtractFromBarOnSplit: (formData, stepsDispatch) => {
                let tempStepsAdded = []
                let tempStepsAddedThatDontMoveBar = 0

                // if (formData.serviceableCompany === 'optimum') {
                //     tempStepsAdded = optimumSteps
                //     tempStepsAddedThatDontMoveBar = 0
                    
                //     stepsDispatch({
                //         type: 'APPEND_STEP',
                //         payload: optimumSteps,
                //     });
                //     stepsDispatch({
                //         type: 'APPENDED_DYNAMIC_STEPS'
                //     });
                    
                // } else if (formData.serviceableCompany === 'frontier') {
                if (formData.serviceableCompany === 'frontier') {
                    tempStepsAdded = baseFrontierSteps
                    tempStepsAddedThatDontMoveBar = 2

                    stepsDispatch({
                        type: 'APPEND_STEPS',
                        payload: baseFrontierSteps,
                    });
                    stepsDispatch({
                        type: 'APPENDED_DYNAMIC_STEPS'
                    });
                }

                return { stepsAdded: tempStepsAdded, stepsAddedThatDontMoveBar: tempStepsAddedThatDontMoveBar }
            },
            errorStep: { 
                component: <FrontierError />, 
                disableButtons: true, 
                onNextFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => { 
                } 
            },
        },
        steps: [
            {
                component: <ProgramsComponent.default />, 
                disableButtons: true, 
                onNextFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {
                    const headers = {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                    const body = programsRequest(userId, formData)
        
                    fetch(environment.backendUrlToSetData, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(body),
                    })
                        .then(() => {
                            setIsLoadingOnStep(false)
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }, 
                onSubmitFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {
                    const headers = {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                    const body = programsRequest(userId, formData)
        
                    fetch(environment.backendUrlToSetData, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(body),
                    })
                        .then(() => {
                            setIsLoadingOnStep(true)
                                const headers = {
                                    Authorization: company,
                                    "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                                }
                    
                                fetch(environment.backendUrlToSubmitData, {
                                    method: 'POST',
                                    headers,
                                    body: JSON.stringify({ userId, sendFlowBotFieldName: "flow_sent_after_demoform_3", refLinkBotFieldName: (formData.fromWidget ? "from_widget_redirect_url" : "demo_3_redirect_url") }),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        window.location = data.refLink
                                        setIsLoadingOnStep(false)
                                        setTimeout(() => {
                                            window.location = window.location.href.replace("/form/", "/error/").replace("/edit/", "/error/")
                                        }, 50000)
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            }, 
            {
                disableButtons: true,
                disableAsyncOnEvent: true,
                component: <AddressComponent.default />, 
                onNextFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {
                    const headers = {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                    const body = addressRequest(userId, formData)
        
                    fetch(environment.backendUrlToSetData, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(body),
                    })
                        .then(() => {
                            setIsLoadingOnStep(false)
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }, 
                onSubmitFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {
                    const headers = {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                    const body = addressRequest(userId, formData)
        
                    fetch(environment.backendUrlToSetData, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(body),
                    })
                        .then(() => {
                            setIsLoadingOnStep(true)
                                const headers = {
                                    Authorization: company,
                                    "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                                }
                    
                                fetch(environment.backendUrlToSubmitData, {
                                    method: 'POST',
                                    headers,
                                    body: JSON.stringify({ userId, sendFlowBotFieldName: "flow_sent_after_demoform_3", refLinkBotFieldName: (formData.fromWidget ? "from_widget_redirect_url" : "demo_3_redirect_url") }),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        window.location = data.refLink
                                        setIsLoadingOnStep(false)
                                        setTimeout(() => {
                                            window.location = window.location.href.replace("/form/", "/error/").replace("/edit/", "/error/")
                                        }, 50000)
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
        
                }
            },
            // {
            //     ProgressStepperIndex: 0,
            //     component: <ConfirmAddressComponent.default />, 
            //     previousText: "Edit Info",
            //     onNextFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {}, 
            //     onSubmitFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {},
            //     onPrevFunction: ({ formData, userId, company, environment, setIsLoadingOnStep, validationDispatch }) => {
            //         validationDispatch({ type: "SET_ADDRESSOBJECT_ISVALID", payload: false })
            //     }
            // }, 
            {
                component: <DetermineCompanyComponent.default />,
                dontMoveProgressBar: true,
                disableButtons: true, 
                onNextFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {}, 
                onSubmitFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {
                    fetch(environment.backendUrlToSubmitData, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify({ userId, sendFlowBotFieldName: "flow_sent_after_demoform_3", refLinkBotFieldName: (formData.fromWidget ? "from_widget_redirect_url" : "demo_3_redirect_url") }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            window.location = data.refLink
                            setIsLoadingOnStep(false)
                            setTimeout(() => {
                                window.location = window.location.href.replace("/form/", "/error/").replace("/edit/", "/error/")
                            }, 50000)
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            },
            {
                disablePrevButton: true,
                component: <PIIComponent.default />, 
                onNextFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {
                    const headers = {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                    const body = piiRequestFreeconnect(userId, formData)
                    body.accepted_disclosures_timestamp = new Date().toJSON()
        
                    fetch(environment.backendUrlToSetData, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(body),
                    })
                        .then(() => {
                            setIsLoadingOnStep(false)
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }, 
                onSubmitFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {
                    const headers = {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                    const body = piiRequestFreeconnect(userId, formData)
        
                    fetch(environment.backendUrlToSetData, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(body),
                    })
                        .then(() => {
                            setIsLoadingOnStep(true)
                                const headers = {
                                    Authorization: company,
                                    "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                                }
                    
                                fetch(environment.backendUrlToSubmitData, {
                                    method: 'POST',
                                    headers,
                                    body: JSON.stringify({ userId, sendFlowBotFieldName: "flow_sent_after_demoform_3", refLinkBotFieldName: (formData.fromWidget ? "from_widget_redirect_url" : "demo_3_redirect_url") }),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        window.location = data.refLink
                                        setIsLoadingOnStep(false)
                                        setTimeout(() => {
                                            window.location = window.location.href.replace("/form/", "/error/").replace("/edit/", "/error/")
                                        }, 50000)
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            }, 
            // {
            //     component: <WaitForServiceabilityComponent.default />, 
            //     dontMoveProgressBar: true,
            //     disableButtons: true, 
            //     onNextFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {}, 
            //     onSubmitFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {}
            // },  
            // {
            //     component: <LoadingComponent.default />, 
            //     dontMoveProgressBar: true,
            //     disableButtons: true, 
            //     onNextFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {}, 
            //     onSubmitFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {}
            // }, 
            {
                component: <CheckProgramsComponent.default />, 
                dontMoveProgressBar: true,
                disableButtons: true, 
                onNextFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {
                    const headers = {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                    const body = programsRequest(userId, formData)
        
                    fetch(environment.backendUrlToSetData, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(body),
                    })
                        .then(() => {
                            setIsLoadingOnStep(false)
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }, 
                onSubmitFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {
                    const headers = {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                    const body = programsRequest(userId, formData)
        
                    fetch(environment.backendUrlToSetData, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(body),
                    })
                        .then(() => {
                            setIsLoadingOnStep(true)
                                const headers = {
                                    Authorization: company,
                                    "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                                }
                    
                                fetch(environment.backendUrlToSubmitData, {
                                    method: 'POST',
                                    headers,
                                    body: JSON.stringify({ userId, sendFlowBotFieldName: "flow_sent_after_demoform_3", refLinkBotFieldName: (formData.fromWidget ? "from_widget_redirect_url" : "demo_3_redirect_url") }),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        window.location = data.refLink
                                        setIsLoadingOnStep(false)
                                        setTimeout(() => {
                                            window.location = window.location.href.replace("/form/", "/error/").replace("/edit/", "/error/")
                                        }, 50000)
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            },
            {
                disableButtons: true, 
                component: <CongratsComponent.default />, 
                onNextFunction: ({ formData, userId, company, environment, setIsLoadingOnStep }) => {
                    const headers = {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                    let body = { userId }

                    if (formData.serviceableCompany === 'frontier') {
                        body.full_address = formData.fullAddress
                        body.mc_benefitCode = formData.programs[0].programCode
                    } else {
                        body.accepted_disclosures = true
                        body.accepted_disclosures_timestamp = new Date().toJSON()
                    }

                    fetch(environment.backendUrlToSetData, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(body),
                    })
                        .then(() => {
                            setIsLoadingOnStep(false)
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }, 
                onSubmitFunction: ({ formData, userId, company, environment, setIsLoadingOnStep, popupDispatch }) => {
                    popupDispatch({ type: 'REMOVE_POPUP' })
                    setIsLoadingOnStep(true)
                    if(formData.serviceableCompany === "optimum"){
                        const headers = {
                            Authorization: company,
                            "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                        }
            
                        fetch(environment.backendUrlToSubmitData, {
                            method: 'POST',
                            headers,
                            body: JSON.stringify({ userId, sendFlowBotFieldName: "flow_sent_after_demoform_3", refLinkBotFieldName: (formData.fromWidget ? "from_widget_redirect_url" : "demo_3_redirect_url") }),
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                window.location = data.refLink
                                setIsLoadingOnStep(false)
                                setTimeout(() => {
                                    window.location = window.location.href.replace("/form/", "/error/").replace("/edit/", "/error/")
                                }, 50000)
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    } else {
                        const headers = {
                            Authorization: company,
                            "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                        }
                        const body = { 
                            userId, 
                            accepted_disclosures: true,
                            accepted_disclosures_timestamp: new Date().toJSON()
                        }
            
                        fetch(environment.backendUrlToSetData, {
                            method: 'POST',
                            headers,
                            body: JSON.stringify(body),
                        })
                            .then(() => {
                                const headers = {
                                    Authorization: company,
                                    "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                                }
                    
                                fetch(environment.backendUrlToSubmitData, {
                                    method: 'POST',
                                    headers,
                                    body: JSON.stringify({ userId, sendFlowBotFieldName: "flow_sent_after_demoform_3", refLinkBotFieldName: (formData.fromWidget ? "from_widget_redirect_url" : "demo_3_redirect_url") }),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        window.location = data.refLink
                                        setIsLoadingOnStep(false)
                                        setTimeout(() => {
                                            window.location = window.location.href.replace("/form/", "/error/").replace("/edit/", "/error/")
                                        }, 50000)
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                    
                }
            }
        ]
    };
} 

export const baseFrontierSteps = [
    // No Longer Needed but left in case requirements change
    // { 
    //     component: <FrontierCommunication />, 
    //     onNextFunction: ({formData, userId, company, environment, setIsLoadingOnStep}) => { 
    //         const headers = {
    //         Authorization: company,
    //         "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
    //     }
    //     const body = frontierCommunicationRequestFreeconnect(userId, formData)

    //     fetch(environment.backendUrlToSetData, {
    //         method: 'POST',
    //         headers,
    //         body: JSON.stringify(body),
    //     })
    //         .then(() => {
    //             setIsLoadingOnStep(false)
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    //     } 
    // },
    { 
        component: <FrontierNV />, 
        dontMoveProgressBar: true,
        onNextFunction: ({formData, userId, company, environment, setIsLoadingOnStep, stepDispatch}) => { 
            setIsLoadingOnStep(true) 
            // TODO : Uncomment and replace below if/else statement
            //  This will be the condition we go off of to decide if they go back to messenger
            if (formData.applicationStatus === "COMPLETE") {
                setIsLoadingOnStep(false)
            } else {
                    const headers = {
                    Authorization: company,
                    "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                }
                fetch(environment.backendUrlToSubmitData, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify({ userId, sendFlowBotFieldName: "frontier_nv", refLinkBotFieldName: "demo_3_redirect_url" }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        window.location = data.refLink
                        setIsLoadingOnStep(false)
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            // if (!formData.eligible) {
            //     const headers = {
            //         Authorization: company,
            //         "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
            //     }
            //     fetch(environment.backendUrlToSubmitData, {
            //         method: 'POST',
            //         headers,
            //         body: JSON.stringify({ userId, sendFlowBotFieldName: "frontier_nv", refLinkBotFieldName: "demo_3_redirect_url" }),
            //     })
            //         .then((response) => response.json())
            //         .then((data) => {
            //             window.location = data.refLink
            //             setIsLoadingOnStep(false)
            //         })
            //         .catch((error) => {
            //             console.error(error);
            //         });
            // } else {
            //     setIsLoadingOnStep(false)
            // }
        } 
    },
    { 
        component: <FrontierOffers />, 
        disableButtons: true,
        onNextFunction: ({formData, userId, company, environment, setIsLoadingOnStep, formDataDispatch}) => {  
        } 
    },
    { 
        component: <FrontierScheduling />, 
        disableButtons: true,
        onNextFunction: ({formData, userId, company, environment, setIsLoadingOnStep, formDataDispatch, popupDispatch}) => { 
            setIsLoadingOnStep(true)
            popupDispatch({ type: 'REMOVE_POPUP' })
            const finishReserveScheduleHeaders = { 
                "X-Api-Key": environment.frontierFinishReserveScheduleUrl.key,
                "Authorization": "frontier"
            }
            const finishReserveScheduleBody = frontierFinishReserveSchedule(userId, formData)
            fetch(environment.frontierFinishReserveScheduleUrl.url, {
                method: 'POST',
                headers: finishReserveScheduleHeaders,
                body: JSON.stringify(finishReserveScheduleBody),
            })
                .then((response) => {
                    response.json()
                    formDataDispatch({ type: 'SET_ACCEPTED_DISCLOSURES', payload: true });
                    const acceptDisclosuresHeaders = { 
                        "X-Api-Key": environment.frontierAcceptDisclosuresUrl.key,
                        "Authorization": "frontier"
                    }
                    const acceptDisclosuresBody = frontierAcceptDisclosures(userId, formData)
                    fetch(environment.frontierAcceptDisclosuresUrl.url, {
                        method: 'PUT',
                        headers: acceptDisclosuresHeaders,
                        body: JSON.stringify(acceptDisclosuresBody),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            const submitCustomerJourneyHeaders = { 
                                "Authorization" : "frontier",
                                "X-Api-Key": environment.frontierSubmitCustomerJourneyUrl.key
                            }
                            const submitCustomerJourneyBody = {
                                quoteId: formData.quoteId
                            }
                            fetch(environment.frontierSubmitCustomerJourneyUrl.url, {
                                method: 'POST',
                                headers: submitCustomerJourneyHeaders,
                                body: JSON.stringify(submitCustomerJourneyBody),
                            })
                            .then((submitResponse) => submitResponse.json())
                            .then((submitData) => {
                                formDataDispatch({
                                    type: "SET_ORDER_ID",
                                    payload: submitData.orderId
                                })
                            // const submitCustomerJourneyObject = await submitCustomerJourneyResponse.json();
                                setIsLoadingOnStep(false)
                            })
                            .catch((error) => {
                                console.error(error);
                            }); 
                        })
                    setIsLoadingOnStep(false)
                })
                .catch((error) => {
                    console.error(error);
                })
        }, 
        nextText: "Continue",
        disablePrevButton: true 
    },
    // { 
    //     component: <Disclosures />, 
    //     onNextFunction: ({formData, userId, company, environment, setIsLoadingOnStep, formDataDispatch}) => {
    //         setIsLoadingOnStep(true)
    //         formDataDispatch({ type: 'SET_ACCEPTED_DISCLOSURES', payload: true });
    //         const acceptDisclosuresHeaders = { 
    //             "X-Api-Key": environment.frontierAcceptDisclosuresUrl.key,
    //             "Authorization": "frontier"
    //         }
    //         const acceptDisclosuresBody = frontierAcceptDisclosures(userId, formData)
    //         fetch(environment.frontierAcceptDisclosuresUrl.url, {
    //             method: 'PUT',
    //             headers: acceptDisclosuresHeaders,
    //             body: JSON.stringify(acceptDisclosuresBody),
    //         })
    //             .then((response) => response.json())
    //             .then((data) => {
    //                 const submitCustomerJourneyHeaders = { 
    //                     "Authorization" : "frontier",
    //                     "X-Api-Key": environment.frontierSubmitCustomerJourneyUrl.key
    //                 }
    //                 const submitCustomerJourneyBody = {
    //                     quoteId: formData.quoteId
    //                 }
    //                 fetch(environment.frontierSubmitCustomerJourneyUrl.url, {
    //                     method: 'POST',
    //                     headers: submitCustomerJourneyHeaders,
    //                     body: JSON.stringify(submitCustomerJourneyBody),
    //                 })
    //                 .then((submitResponse) => submitResponse.json())
    //                 .then((submitData) => {
    //                 // const submitCustomerJourneyObject = await submitCustomerJourneyResponse.json();
    //                     setIsLoadingOnStep(false)
    //                 })
    //             .catch((error) => {
    //                 console.error(error);
    //             }); 
    //         })
    //     }
    // },
    { component: <FrontierSuccess />, onNextFunction: (url, formData) => { } },
]

export const optimumSteps = { component: <CallNow />, onNextFunction: (url, formData) => { }, disableButtons: true }

export const frontierExtraSteps = [ 
    { 
        component: <FrontierSecurityQuestions />, 
        onNextFunction: ({formData, userId, company, environment, setIsLoadingOnStep, formDataDispatch}) => { 
            setIsLoadingOnStep(true)
            const customerIdentityVerificationHeaders = { 
                "X-Api-Key": environment.frontierCustomerIdentityVerificationUrl.key,
                "Authorization": "frontier"
            }
            const customerIdentityVerificationBody = frontierCustomerIdentityVerification(userId, formData)
            fetch(environment.frontierCustomerIdentityVerificationUrl.url, {
                method: 'POST',
                headers: customerIdentityVerificationHeaders,
                body: JSON.stringify(customerIdentityVerificationBody),
            })
                .then((response) => response.json())
                .then((data) => {
                    setIsLoadingOnStep(false)
                })
        } 
    },  
    { 
        component: <FrontierPositiveId />, 
        onNextFunction: ({formData, userId, company, environment, setIsLoadingOnStep, formDataDispatch}) => { } },  
    { 
        component: <FrontierPreviousAddress />,
        onNextFunction: ({formData, userId, company, environment, setIsLoadingOnStep, formDataDispatch, stepDispatch}) => { 
            setIsLoadingOnStep(true)
            const verifyCustomerPreviousAddressHeaders = { 
                "X-Api-Key": environment.frontierVerifyCustomerPreviousAddressUrl.key,
                "Authorization": "frontier"
            }
            const verifyCustomerPreviousAddressBody = frontierVerifyCustomerPreviousAddress(userId, formData)
            fetch(environment.frontierVerifyCustomerPreviousAddressUrl.url, {
                method: 'POST',
                headers: verifyCustomerPreviousAddressHeaders,
                body: JSON.stringify(verifyCustomerPreviousAddressBody),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data?.body?.creditScore?.rating === "P" || data?.body?.creditScore?.rating === "Z") {
                        stepDispatch({ type:'INSERT_STEP', payload: {index: steps.currentStep + 1, step: frontierExtraSteps[1]}})
                    }
                    setIsLoadingOnStep(false)
                })
        }
    },  
]

export default steps;
