// Packages
import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Contexts
import { StepsContext } from '../contexts/Steps';

// Props of the Component
const propTypes = {
};

const ProgressBar = ({ }) => {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [imageObject, setImageObject] = useState({});
    const [steps, stepsDispatch] = useContext(StepsContext);
    const [percentageList, setPercentageList] = useState({});
    const [currentProgress, setCurrentProgress] = useState(0);
    const [length, setLength] = useState(null);
    const [lastCalculatedStep, setLastCalculatedStep] = useState(-1);
    const [subtractFromLength, setSubtractFromLength] = useState(null);
    const [lastCalculatedLength, setLastCalculatedLength] = useState(null);
    const [asyncStepLengthChange, setAsyncStepLengthChange] = useState({});
    const [percentRemaining, setPercentRemaining] = useState(100);
    const [staticPercent, setStaticPercent] = useState(0);
    const [amountOfAsyncStepsCalculated, setAmountOfAsyncStepsCalculated] = useState(0);

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ProgressBar/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ProgressBar/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/ProgressBar.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/ProgressBar.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ProgressBar/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ProgressBar/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ProgressBar/imageObjects.js`)
            .then((module) => {
                setImageObject(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ProgressBar/imageObjects.js`).then(
                    (module) => {
                        setImageObject(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        // if steps.steps.length is less than lastCalculatedLength, 
        //  clear the future steps from the setPercentageList to {}
        //      take the current step, and set to the last item in the object
        const tempObj = {}
        for(const stepPercent in percentageList){
            if (percentageList[stepPercent] == undefined || stepPercent <= steps.currentStep){
                tempObj[stepPercent] = percentageList[stepPercent]
            }
        }
        setPercentageList(tempObj)
        setLastCalculatedLength(steps.steps.length)
        setLastCalculatedStep(steps.currentStep)
    }, [steps.steps.length])

    useEffect(() => {
        if (lastCalculatedStep < steps.currentStep) {
            let continuePastAsync = true
            let tempLength = length
            let tempSubtractLength = subtractFromLength
            let tempLastCalculatedLength = lastCalculatedLength
    
            if (length === null) {
                tempLength = steps.steps.length - 1
                setLength(tempLength)
            }
            if (lastCalculatedLength === null) {
                tempLastCalculatedLength = steps.steps.length
                setLastCalculatedLength(tempLastCalculatedLength)
            }

            if (tempLastCalculatedLength !== steps.steps.length) {
                const arrayOfAsyncSteps = Object.keys(steps.metaData?.asyncStep ? steps.metaData?.asyncStep : [])

                if (arrayOfAsyncSteps.length !== amountOfAsyncStepsCalculated) {
                    const ifSyncStepsHasKeys = arrayOfAsyncSteps.some((element) => steps.metaData.asyncStep[element])
                    if (ifSyncStepsHasKeys) {
                        let tempObject = { ...asyncStepLengthChange }
        
                        for (let i = 0; i < arrayOfAsyncSteps.length; i += 1) {
                            let indexPreviousValue = 
                                tempObject[steps.metaData.asyncStep[arrayOfAsyncSteps[i]].indexOccursOn] ? 
                                        tempObject[steps.metaData.asyncStep[arrayOfAsyncSteps[i]].indexOccursOn] : 
                                        0
                            tempObject[steps.metaData.asyncStep[arrayOfAsyncSteps[i]].indexOccursOn] = indexPreviousValue + steps.metaData.asyncStep[arrayOfAsyncSteps[i]].amountToSubtractFromLength
                        }
    
                        if (tempObject[currentProgress] || tempObject[steps.currentStep] === 0) {
                            continuePastAsync = false
                            let asyncTempCurrentProgress = 1
                            let asyncTempSubtractLength = 0
                            let asyncTempPercentRemaining = 100 - percentageList[steps.currentStep - 1]
                            let asyncTempStaticPercent = percentageList[steps.currentStep - 1]
            
                            for (let i = steps.currentStep; i < steps.steps.length; i += 1) {
                                if (steps.steps[i]?.dontMoveProgressBar) {
                                    asyncTempSubtractLength += 1
                                }
                            }
            
                            let asyncTempLength = steps.steps.length - steps.currentStep
            
                            setPercentageList(prev => ({ ...prev, [steps.currentStep]: (asyncTempStaticPercent + ((asyncTempCurrentProgress) / (asyncTempLength - asyncTempSubtractLength)) * asyncTempPercentRemaining) }))
                            setCurrentProgress(asyncTempCurrentProgress + 1)
                            setLength(asyncTempLength)
                            setSubtractFromLength(asyncTempSubtractLength)
                            setPercentRemaining(asyncTempPercentRemaining)
                            setStaticPercent(asyncTempStaticPercent)
                        }
        
                        setAsyncStepLengthChange(tempObject)
                        setAmountOfAsyncStepsCalculated(prev => prev + 1)
                    }
                }
            }

            if (continuePastAsync) {
                if (subtractFromLength === null) {
                    tempSubtractLength = steps.currentStep
        
                    for (let i = tempSubtractLength; i < steps.steps.length; i += 1) {
                        if (steps.steps[i]?.dontMoveProgressBar) {
                            tempSubtractLength += 1
                        }
                    }
                    const tempAddToProgressBar = steps.metaData?.addToProgressBarLength ? steps.metaData?.addToProgressBarLength : 0
                    setSubtractFromLength(tempSubtractLength - tempAddToProgressBar)
                }
        
                if (asyncStepLengthChange[steps.currentStep] || asyncStepLengthChange[steps.currentStep] === 0) {
                    let asyncTempCurrentProgress = 1
                    let asyncTempSubtractLength = 0
                    let asyncTempPercentRemaining = 100 - percentageList[steps.currentStep - 1]
                    let asyncTempStaticPercent = percentageList[steps.currentStep - 1]
    
                    for (let i = steps.currentStep; i < steps.steps.length; i += 1) {
                        if (steps.steps[i]?.dontMoveProgressBar) {
                            asyncTempSubtractLength += 1
                        }
                    }
    
                    let asyncTempLength = steps.steps.length - steps.currentStep
    
                    setPercentageList(prev => ({ ...prev, [steps.currentStep]: (asyncTempStaticPercent + ((asyncTempCurrentProgress) / (asyncTempLength - asyncTempSubtractLength)) * asyncTempPercentRemaining) }))
                    setCurrentProgress(asyncTempCurrentProgress + 1)
                    setLength(asyncTempLength)
                    setSubtractFromLength(asyncTempSubtractLength)
                    setPercentRemaining(asyncTempPercentRemaining)
                    setStaticPercent(asyncTempStaticPercent)
                } else if (!steps.steps[steps.currentStep]?.dontMoveProgressBar) {
                    setPercentageList(prev => ({ ...prev, [steps.currentStep]: (staticPercent + ((currentProgress) / (tempLength - tempSubtractLength)) * percentRemaining) }))
                    setCurrentProgress(prev => prev + 1)
                } else if (steps.steps[steps.currentStep]?.dontMoveProgressBar) {
                    setPercentageList(prev => ({ ...prev, [steps.currentStep]: prev[steps.currentStep - 1] }))
                }
            }
            
            setLastCalculatedStep(steps.currentStep)
        }
    }, [steps.currentStep])

    // useEffect(() => {
    //     console.log(
    //         "=================================", "\n",
    //         "steps.currentStep: ", steps.currentStep, "\n",
    //         "steps.steps.length: ", steps.steps.length, "\n",
    //         "currentProgress: ", currentProgress, "\n",
    //         "length: ", length, "\n",
    //         "math for current percent: ", `${staticPercent} + (((${currentProgress}) / (${length} - ${subtractFromLength})) * ${percentRemaining})`, "\n",
    //         "equation for current percent: ", `staticPercent + (((currentProgress) / (length - subtractFromLength)) * percentRemaining)`, "\n",
    //         "percentageList: ", percentageList, "\n",
    //         "lastCalculatedStep", lastCalculatedStep, "\n",
    //         "lastCalculatedLength", lastCalculatedLength, "\n",
    //         "subtractFromLength: ", subtractFromLength, "\n",
    //         "percentRemaining: ", percentRemaining, "\n",
    //         "staticPercent: ", staticPercent, "\n",
    //         "asyncStepLengthChange: ", asyncStepLengthChange, "\n",
    //         "================================="
    //     )
    // }, [lastCalculatedStep, percentageList])

    useEffect(() => {
        setPercentageList(prev => ({ ...prev, [steps.currentStep]: steps.setProgressBar}))
    }, [steps.setProgressBar])

    return (
        <div className={classNames.container}>
            <img 
                className={classNames.progressIndicator} 
                src={imageObject?.progressBarIndicator?.src} 
                alt={imageObject?.progressBarIndicator?.altText} 
                style={{ 
                    left: `${typeof percentageList === 'object' && percentageList !== null ? 
                        (percentageList[steps.currentStep] ? percentageList[steps.currentStep] : 0) : 
                        0
                    }%`
                }}
            />
            <div className={classNames.innerBar} style={{ width: `${typeof percentageList === 'object' && percentageList !== null ? 
                    (percentageList[steps.currentStep] ? percentageList[steps.currentStep] : 0) : 
                    0
                }%` }}
            />
        </div>
    );
};

ProgressBar.propTypes = propTypes;

export default ProgressBar;