const classNames = {
    label: 'address-label',
    validationMessage: 'address-error',
    addressButton: 'addresses-button',
    addressesButtonsDivider: 'addresses-buttons-divider',
    addressesButtonsDividerNoBorder: 'addresses-buttons-divider-no-border',
    addressFieldContainer: 'address-field-container',
    stateZipConatiner: 'address-field-state-zip-container',
    button: "button-addresssugestions",
    buttonLast: "button-addresssugestions-last",
    suggestionContainer: "suggestion-container-addresssugestions",
    stateDropdown: "address-field-state-dropdown",
    sublabelText: "address-field-sublabel-text",
    poweredByText: "address-field-poweredBy-text"
};

export default classNames;
