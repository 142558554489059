// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
    className: PropTypes.string,
    disableDefaultStyling: PropTypes.bool
};
const defaultProps = {
    className: '',
    disableDefaultStyling: false
};

function Container({ children, className, disableDefaultStyling }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Container/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Container/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Container.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Container.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Container/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Container/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return <div className={`${disableDefaultStyling ? '' : classNames.container} ${className}`}>{children}</div>;
}

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
