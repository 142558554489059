// Packages
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ConditionalRender from '../components/ConditionalRender';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
// UI
import SubHeadingText from './SubHeadingText'
import BodyText from './BodyText'
import Button from './Button'
// Contexts
import { ValidationContext } from '../contexts/Validation';
import { PopupContext } from '../contexts/Popup';
// Helpers
import { phoneNumberAutoFormat, stripPhoneNumberAutoFormat, ssnAutoFormat, stripSsnAutoFormat } from '../helpers/phone';
//Assets
import SSLIcon from '../companies/default/styles/variables/assets/SSL-encryption-icon.svg'
import EncryptionShield from '../companies/default/styles/variables/assets/encryption-shield.svg'

// Props of the Component
const propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    placeHolder: PropTypes.string,
    initialValue: PropTypes.string,
    validationKey: PropTypes.string,
    validationFunction: PropTypes.func,
    step: PropTypes.number,
    setValueTo: PropTypes.string,
    useValidationContext: PropTypes.bool,
    reloadOnStart: PropTypes.bool,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.string,
    phoneNumber: PropTypes.bool,
    ssn: PropTypes.bool,
    subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    showBadge: PropTypes.bool,
    setIsValidStateTo: PropTypes.bool,
    setValidationMessageTo: PropTypes.string,
    dropdown: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    isDropdownPresent:PropTypes.oneOfType([ PropTypes.bool, PropTypes.string]),
    staticValue: PropTypes.string,
    popUpTitle: PropTypes.string,
    popUpContent: PropTypes.string,
    popUpSSLBadge: PropTypes.bool
};
const defaultProps = {
    onChange: () => {},
    children: '',
    className: '',
    inputClassName: '',
    placeHolder: '',
    initialValue: '',
    validationKey: '',
    validationFunction: () => {},
    step: null,
    setValueTo: undefined,
    useValidationContext: true,
    reloadOnStart: false,
    disabled: false,
    autoComplete: "on",
    phoneNumber: false,
    ssn: false,
    subLabel: null,
    showBadge: null,
    setIsValidStateTo: null,
    setValidationMessageTo: null,
    dropdown: null,
    isDropdownPresent: false,
    staticValue: null,
    popUpTitle: null,
    popUpContent: null,
    popUpSSLBadge: null
};

function Text({
    onChange,
    name,
    children,
    className,
    inputClassName,
    placeHolder,
    initialValue,
    validationKey,
    validationFunction,
    step,
    setValueTo,
    useValidationContext,
    disabled,
    autoComplete,
    reloadOnStart,
    phoneNumber,
    ssn,
    subLabel,
    showBadge,
    setIsValidStateTo,
    setValidationMessageTo,
    dropdown,
    isDropdownPresent,
    staticValue,
    popUpTitle,
    popUpContent,
    popUpSSLBadge,
    type
}) {
    const { company } = useParams();
    const [value, setValue] = useState(phoneNumber ? phoneNumberAutoFormat(initialValue) : (ssn ? ssnAutoFormat(initialValue) : initialValue));
    const [classNames, setClassNames] = useState({});
    const [isValidState, setIsValidState] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');
    const [validation, ValidationDispatch] = useContext(ValidationContext);
    const [storedReloadValidation, setStoredReloadValidation] = useState(validation.reloadValidation);
    const [popupState, popupDispatch] = useContext(PopupContext);

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/TextField/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/TextField/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/TextField.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/TextField.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/TextField/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/TextField/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        if (setIsValidStateTo !== null) {
            setIsValidState(setIsValidStateTo)
        }
    })
    useEffect(() => {
        if (setValidationMessageTo !== null) {
            setValidationMessage(setValidationMessageTo)
        }
    })

    useEffect(() => {
        if (validationKey) {
            if (validation.reloadValidation > storedReloadValidation) {
                // const { isValid, message } = validationFunction(phoneNumber ? stripPhoneNumberAutoFormat(phoneNumberAutoFormat(value)) : value);
                const { isValid, message } = validationFunction(phoneNumber ? stripPhoneNumberAutoFormat(phoneNumberAutoFormat(value)) : (ssn ? stripSsnAutoFormat(ssnAutoFormat(value)) : value));
                setIsValidState(isValid);
                setValidationMessage(message);
                if (useValidationContext) {
                    ValidationDispatch({
                        type: `SET_${validationKey.toUpperCase()}`,
                        payload: { step, isValid },
                    });
                }
            }
        }
    }, [validation.reloadValidation]);

    useEffect(() => {
        if (useValidationContext) {
            if ((validationKey && step) || step === 0) {
                ValidationDispatch({
                    type: `SET_${validationKey.toUpperCase()}_STEP`,
                    payload: step,
                });
            }
        }
    }, [step]);

    useEffect(() => {
        if (initialValue) {
            setStoredReloadValidation(validation.reloadValidation);
            if (validationKey) {
                // const { isValid, message } = validationFunction(phoneNumber ? stripPhoneNumberAutoFormat(phoneNumberAutoFormat(value)) : value);
                const { isValid, message } = validationFunction(phoneNumber ? stripPhoneNumberAutoFormat(phoneNumberAutoFormat(value)) : (ssn ? stripSsnAutoFormat(ssnAutoFormat(value)) : value));
                setIsValidState(isValid);
                setValidationMessage(message);
                if (useValidationContext) {
                    ValidationDispatch({
                        type: `SET_${validationKey.toUpperCase()}`,
                        payload: { step, isValid },
                    });
                }
            }
        }
    }, []);

    useEffect(() => {
        if (setValueTo !== undefined) {
            setValue(phoneNumber ? phoneNumberAutoFormat(setValueTo) : setValueTo);
        }
    }, [setValueTo]);

    return (
        <ConditionalRender
            condition={children}
            falseReturn={
                <div className={`${classNames.label} ${className}`}>
                    <input
                        autoComplete={autoComplete}
                        disabled={disabled}
                        placeholder={disabled ? "Disabled" : placeHolder}
                        className={`${classNames.textfield} ${inputClassName}`}
                        type={(phoneNumber || ssn || type === "tel") ? "tel" : "text"}
                        name={name}
                        value={value}
                        onChange={(event) => {
                            if (validationKey) {
                                // const { isValid, message } = validationFunction(phoneNumber ? stripPhoneNumberAutoFormat(phoneNumberAutoFormat(event.target.value)) : event.target.value);
                                const { isValid, message } = validationFunction(phoneNumber ? stripPhoneNumberAutoFormat(phoneNumberAutoFormat(event.target.value)) : (ssn ? stripSsnAutoFormat(ssnAutoFormat(event.target.value)) : event.target.value));
                                setIsValidState(isValid);
                                setValidationMessage(message);
                                if (useValidationContext) {
                                    ValidationDispatch({
                                        type: `SET_${validationKey.toUpperCase()}`,
                                        payload: { step, isValid },
                                    });
                                }
                            }
                            if (phoneNumber) {
                                setValue(phoneNumberAutoFormat(event.target.value));
                                onChange(stripPhoneNumberAutoFormat(phoneNumberAutoFormat(event.target.value)));
                            } else if (ssn) {
                                const ssnAutoFormatValue = ssnAutoFormat(event.target.value);
                                setValue(ssnAutoFormatValue);
                                onChange(stripSsnAutoFormat(ssnAutoFormatValue));
                            } else {
                                setValue(event.target.value);
                                onChange(event.target.value);
                            }
                        }}
                    />
                    <ConditionalRender condition={!isValidState || validationMessage}>
                        <div className={classNames.validationMessage}>{validationMessage}</div>
                    </ConditionalRender>
                </div>
            }
        >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className={`${classNames.label} ${className}`}>
                <div className={classNames.labelText}>{children}
                    <ConditionalRender condition={popUpTitle && popUpContent}>
                        <button
                            type="button" 
                            onClick={(event) => {
                                event.preventDefault()
                                popupDispatch({ type: 'ADD_POPUP', payload: {
                                    title: popUpTitle,
                                    onClick: () => { popupDispatch({ type: 'REMOVE_POPUP' }) },
                                    component: (
                                        <div className={classNames.popUpContent}>
                                            <SubHeadingText>{popUpContent}</SubHeadingText>
                                            <ConditionalRender condition={popUpSSLBadge}>
                                                <div className='ssl-encryption-badge-container'>
                                                    <img className='ssl-encryption-badge-image' src={SSLIcon} alt="ssl-encryption-badge" />
                                                </div>
                                            </ConditionalRender>
                                            <Button style="previous" onClick={() => { 
                                                popupDispatch({ type: 'REMOVE_POPUP' })
                                            }} >Close</Button>
                                        </div>
                                )}})
                            }} className={classNames.infoButton}>  ⓘ</button>
                    </ConditionalRender>
                <ConditionalRender condition={subLabel}>
                    <BodyText className={classNames.subLabel}>
                        <ConditionalRender condition={showBadge}>
                            <img className='secure-shield-image' src={EncryptionShield} alt="encryption-badge" />
                        </ConditionalRender>
                        {subLabel}
                    </BodyText>
                </ConditionalRender>
                </div>
                <input
                    autoComplete={autoComplete}
                    disabled={disabled}
                    placeholder={disabled ? "Disabled" : placeHolder}
                    className={`${classNames.textfield} ${inputClassName} ${!isValidState || validationMessage ? classNames.errorInput : ""} ${isDropdownPresent ? classNames.inputDropdown : ''}`}
                    type={phoneNumber || ssn || type === "tel" ? "tel" : "text"}
                    name={name}
                    value={value}
                    onChange={(event) => {
                        if (!(staticValue && value !== staticValue && event.target.value.length < value.length)) {
                            if ((staticValue && event.target.value === staticValue) || (staticValue && value === staticValue && event.target.value.length < value.length)) {
                                return
                            }
                        }
                        if (validationKey) {
                            // const { isValid, message } = validationFunction(phoneNumber ? stripPhoneNumberAutoFormat(phoneNumberAutoFormat(event.target.value)) : event.target.value);
                            const { isValid, message } = validationFunction(phoneNumber ? stripPhoneNumberAutoFormat(phoneNumberAutoFormat(event.target.value)) : (ssn ? stripSsnAutoFormat(ssnAutoFormat(event.target.value)) : event.target.value));
                            setIsValidState(isValid);
                            setValidationMessage(message);
                            if (useValidationContext) {
                                ValidationDispatch({
                                    type: `SET_${validationKey.toUpperCase()}`,
                                    payload: { step, isValid },
                                });
                            }
                        }
                        onChange(event.target.value);
                        if (phoneNumber) {
                            setValue(phoneNumberAutoFormat(event.target.value));
                            onChange(stripPhoneNumberAutoFormat(phoneNumberAutoFormat(event.target.value)));
                        } else if (ssn) {
                            const ssnAutoFormatValue = ssnAutoFormat(event.target.value);
                            setValue(ssnAutoFormatValue);
                            onChange(stripSsnAutoFormat(ssnAutoFormatValue));
                        }else {
                            setValue(event.target.value);
                            onChange(event.target.value);
                        }
                    }}
                />
                <ConditionalRender condition={isDropdownPresent}>
                    <div className={classNames.dropdown}>
                        {dropdown}
                    </div>
                </ConditionalRender>
                <ConditionalRender condition={!isValidState || validationMessage}>
                    <BodyText className={classNames.validationMessage}>
                        <div style={{  height: "1em", width: "1em", marginTop: "2px" }}><AiOutlineExclamationCircle /></div><div>&nbsp;</div>{validationMessage}
                    </BodyText>
                </ConditionalRender>
            </label>
        </ConditionalRender>
    );
}

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
