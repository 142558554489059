// Packages
import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

// Contexts
import { ConfigContext } from '../contexts/Config';
import { FormDataContext } from '../contexts/FormData';
import { StepsContext } from '../contexts/Steps';
import { EnvironmentContext } from '../contexts/Environment';
import { PopupContext } from '../contexts/Popup';
import { IsLoadingContext } from '../contexts/IsLoading';

function DbTracking({page}) {
    // Context and params
    const { company } = useParams();
    const [configState, configDispatch] = useContext(ConfigContext)
    const [formDataState, formDataDispatch] = useContext(FormDataContext)
    const [stepsState, stepsDispatch] = useContext(StepsContext)
    const [environmentState, environmentDispatch] = useContext(EnvironmentContext)
    const [popupState, popupDispatch] = useContext(PopupContext)
    const [IsLoadingState, IsLoadingDispatch] = useContext(IsLoadingContext)

    //helper state
    const [loadingBlockerIndex, setLoadingBlockerIndex] = useState(0)
    const [hasInitCallRun, setHasInitCallRun] = useState(0)
    const [stepTimesSaved, setStepTimesSaved] = useState({})

    //STUB - Initial create user call to Ace Users DB System
    useEffect(() => {
        if(environmentState.aceUsersDbApi && !hasInitCallRun && stepsState.steps.length > 0){
            setHasInitCallRun(prev => prev + 1)
            const urlString = window.location.href;
            const url = new URL(urlString);
            const queryParameters = url.searchParams;
            const demoStartTime = Date.now()
            callAceUsersDb({
                "main_demo": {
                    "initial_company": company,
                    "referrer": document.referrer,
                    "page_name": page,
                    "demo_start_time": String(demoStartTime),
                    "demo_time_start_iso": moment(demoStartTime).tz("America/Los_Angeles").format().replace("T", " ").replace("-08:00", ".000").replace("-07:00", ".000")
                }
            }, true)
        }
    }, [environmentState.aceUsersDbApi, IsLoadingState, stepsState.steps]);

    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK && (formDataState.isWelcomePageSplit === false || formDataState.isWelcomePageSplit === true)) {
            callAceUsersDb({
                "main_demo": {
                    "welcome_page": formDataState.isWelcomePageSplit
                }
            }, true)
        }
    }, [formDataState.isWelcomePageSplit, formDataState.aceUserPK]);

    //STUB - Config state
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK && configState?.leadReference && configState?.targetedProvider){
            let configObj = {
                "main_demo": {
                    "lead_reference": configState.leadReference,
                    "targetedProvider": configState.targetedProvider,
                    "advertising_platform": configState.advertisingPlatform,
                    "campaign_type": configState.campaignType,
                    "source_reference": configState.sourceReference,
                    "current_form_version": configState.formVersion
                }
            }
            callAceUsersDb(configObj, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [configState, environmentState.aceUsersDbApi, formDataState.aceUserPK]);

    //STUB - Steps change handler
    useEffect(() => {
        // console.log("STEPS STATE CHANGE:", stepsState)
        if(stepsState.currentStep && formDataState.aceUserPK){
            const stepName = stepsState.steps[stepsState.currentStep].name
            let stepObj = {
                "main_demo": {
                    [stepName]: true
                }
            }
            callAceUsersDb(stepObj, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [stepsState.currentStep]);

    //STUB - Program is selected
    useEffect(() => {
        if(environmentState.aceUsersDbApi){
            callAceUsersDb({
                "main_demo": {
                    "selected_program": formDataState.program_code
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.program_code])


    //STUB - existingCustomer is selected - runs for both frontier and optimum
    useEffect(() => {
        //NOTE - Might have to seperate logic for frontier vs optimum
        if(environmentState.aceUsersDbApi){
            callAceUsersDb({
                "main_demo": {
                    "existing_customer": formDataState.existingCustomer
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.existingCustomer])

    //STUB - PII is submitted
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState?.eligibleProviders && formDataState.submittedComboPii){
            //NOTE - Might need to sepperate object by company here
            callAceUsersDb({
                "main_demo": {
                    "existing_customer": formDataState.existingCustomer,
                    "is_marketing_email_consent": formDataState.marketingEmailConsent,
                },
                "pii": {
                    "first_name": formDataState.firstName,
                    "middle_name": formDataState.middleName,
                    "last_name": formDataState.lastName,
                    "second_last_name": formDataState.secondLastName,
                    "name_suffix": formDataState.suffix,
                    "dob": formDataState.dob,
                    "email": formDataState.email,
                    "phone_number": formDataState.phoneNumber,
                    "last_four": formDataState.ssn,
                    "bqp_first_name": formDataState.bqpFirstName,
                    "bqp_last_name": formDataState.bqpLastName,
                    "bqp_dob": formDataState.bqpDob,
                    "bqp_last_four": formDataState.bqpSsn,
                    "acp_subscriber_id": formDataState.acpId,
                    "sms_consent": formDataState.userSMSConsent,
                    "address": formDataState.address.parsedAddress.street,
                    "address_line2": formDataState.address.parsedAddress.line2,
                    "city": formDataState.address.parsedAddress.city,
                    "zipCode": formDataState.address.parsedAddress.zip,
                    "state": formDataState.address.parsedAddress.state
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.submittedComboPii, formDataState?.topServiceableCompany])

    //STUB - pop up context handlers
    useEffect(() => {
        if(popupState?.popupName){
            callAceUsersDb({
                "demo": {
                    [popupState?.popupName + "_popup"]: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")

            if(popupState?.timePopupStart){
                callAceUsersDb({
                    "demo": {
                        [popupState?.popupName + "_time_start"]: String(popupState?.timePopupStart),
                        [popupState?.popupName + "_time_start_iso"]: moment(popupState?.timePopupStart).tz("America/Los_Angeles").format().replace("T", " ").replace("-08:00", ".000").replace("-07:00", ".000")
                    }
                }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
            }
        }
    }, [popupState.popupName])
    useEffect(() => {
        if(popupState?.popupTimeEnd && popupState?.popupTimeElapsed){
            callAceUsersDb({
                "demo": {
                    [popupState?.lastDisplayedPopup + "_time_end"]: String(popupState?.popupTimeEnd),
                    [popupState?.lastDisplayedPopup + "_time_end_iso"]: moment(popupState?.popupTimeEnd).tz("America/Los_Angeles").format().replace("T", " ").replace("-08:00", ".000").replace("-07:00", ".000"),
                    [popupState?.lastDisplayedPopup + "_elapsed_time"]: String(popupState?.popupTimeElapsed)
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [popupState.popupTimeEnd])

    //STUB - input blockers context handlers
    useEffect(() => {
        if(IsLoadingState?.addedBlockerArr){
            //loadingBlockerIndex
            const itemToSave = IsLoadingState?.addedBlockerArr[loadingBlockerIndex]
            if(itemToSave?.endBlockerTimeStamp){
                callAceUsersDb({
                    "main_demo": {
                        [itemToSave.blockerName + "_loading_blocker_name"]: itemToSave.blockerName,
                        [itemToSave.blockerName + "_time_start"]: String(itemToSave?.blockerStartTimestamp),
                        [itemToSave.blockerName + "_time_start_iso"]: moment(itemToSave?.blockerStartTimestamp).tz("America/Los_Angeles").format().replace("T", " ").replace("-08:00", ".000").replace("-07:00", ".000"),
                        [itemToSave.blockerName + "_elapsed_time"]: String(itemToSave?.blockerDiff),
                        [itemToSave.blockerName + "_end_time"]: String(itemToSave?.endBlockerTimeStamp),
                        [itemToSave.blockerName + "_end_time_iso"]: moment(itemToSave?.endBlockerTimeStamp).tz("America/Los_Angeles").format().replace("T", " ").replace("-08:00", ".000").replace("-07:00", ".000")
                    }
                }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
                setLoadingBlockerIndex(loadingBlockerIndex + 1)
            }
        }
    }, [IsLoadingState.addedBlockerArr])

    //STUB - eligible providers call
    useEffect(() => {
        if(environmentState.aceUsersDbApi){
            callAceUsersDb({
                "main_demo": {
                    "eligble_providers": formDataState.eligibleProviders.replace(/,/g, '|')
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.eligibleProviders])

    //STUB - frontier offers call
    useEffect(() => {
        if(environmentState.aceUsersDbApi){
            const frontierOffersByAddress = formDataState?.fcusServiceabilityResults?.frontier?.offers_by_address
            if(frontierOffersByAddress){
                let offersByAddressString = ""
                for (let i in frontierOffersByAddress){
                    offersByAddressString = offersByAddressString + frontierOffersByAddress[i].name + "|"
                }
                offersByAddressString = offersByAddressString.slice(0, -1)

                callAceUsersDb({
                    "product": {
                        "available_offers": offersByAddressString
                    }
                }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
            }
        }
    }, [formDataState.fcusServiceabilityResults])

    //STUB - frontier offer call
    useEffect(() => {
        if(environmentState.aceUsersDbApi){
            callAceUsersDb({
                "product": {
                    "selected_offer": formDataState.frontierOffer
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.frontierOffer])

    //STUB - frontier call now clicked
    useEffect(() => {
        if(environmentState.aceUsersDbApi){
            callAceUsersDb({
                "demo": {
                    "frontier_call_now_clicked": formDataState.frontierCallNowClicked
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.frontierCallNowClicked])

    //STUB - Frontier Preinstall time
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.preinstallDate && formDataState.preinstallTime && formDataState.installTimeFrame){
            callAceUsersDb({
                "product": {
                    "selected_installation_date": formDataState.preinstallDate,
                    "selected_installation_time": formDataState.preinstallTime,
                    "selected_installation_timeframe": formDataState.installTimeFrame
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.preinstallDate, formDataState.preinstallTime, formDataState.installTimeFrame])

    //STUB - Frontier Preselect preliminary data
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.preselectPageTimestamp && formDataState.autoPreselectedDate){
            callAceUsersDb({
                "product": {
                    "preselect_page_timestamp": formDataState.preselectPageTimestamp,
                    "auto_preselected_date": formDataState.autoPreselectedDate,
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.preinstallDate, formDataState.autoPreselectedDate])

    //STUB - Frontier Preselect chose another date
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.chooseAnotherDate !== null &&  formDataState.chooseAnotherDate !== undefined ){
            callAceUsersDb({
                "product": {
                    "preselect_chose_another_date": formDataState.chooseAnotherDate
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.chooseAnotherDate])

    //STUB - Spectrum cert saving
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.certUrl){
            callAceUsersDb({
                "product": {
                    "trusted_form_cert_url": formDataState.certUrl
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.trusedFormCertRetained])

    //STUB - Answer to ACP transfer question
    useEffect(() => {
        if(environmentState.aceUsersDbApi){
            callAceUsersDb({
                "main_demo": {
                    "has_existing_acp": formDataState.hasExistingAcp ? "yes" : "no"
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.hasExistingAcp])

    //STUB - Answer to ACP transfer question
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    "got_no_benefit_split": !formDataState.isBenefitSplit,
                    "got_welcome_page": formDataState.isWelcomePageSplit
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.isBenefitSplit, formDataState.aceUserPK])

    //STUB - Answer to ACP transfer question
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            const stepTimeStampArrays = Object.keys(stepsState.timeStamps)

            stepTimeStampArrays.forEach((stepName) => {
                // console.log(`${stepName}: bool - ${stepsState.timeStamps[stepName].totalTime !== stepTimesSaved[stepName]}, values - ${stepsState.timeStamps[stepName].totalTime} and ${stepTimesSaved[stepName]}`)
                if (stepsState.timeStamps[stepName].totalTime !== stepTimesSaved[stepName] && stepsState.timeStamps[stepName].totalTime > 0) {
                    // console.log(`${stepName}: ${stepsState.timeStamps[stepName].totalTime}`)
                    callAceUsersDb({
                        "main_demo": {
                            [`time_spent_on_${stepName}`]: stepsState.timeStamps[stepName].totalTime.toString()
                        }
                    }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
                    setStepTimesSaved(prev => ({ ...prev, [stepName]: stepsState.timeStamps[stepName].totalTime }))
                }
            })
        }
    }, [stepsState])

    // SECTION - ITM END NO OFFERS

    // NOTE - Page Reached ITM End No Offers
    useEffect(() => {
        if (environmentState.aceUsersDbApi && formDataState.aceUserPK) {
            callAceUsersDb({
                "main_demo": {
                    ['received_page_itm_end_no_offers']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.pageReachedItmEndNoOffers])

    // !SECTION - ITM END NO OFFERS

    // SECTION - ITM END APP RECEIVED

    // NOTE - Page Reached ITM End App Received
    useEffect(() => {
        if (environmentState.aceUsersDbApi && formDataState.aceUserPK) {
            callAceUsersDb({
                "main_demo": {
                    ['received_page_itm_app_received']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.pageReachedItmEndAppReceived])

    // NOTE - Clicked Call Button ITM End App Received
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_call_now_button_itm_app_received']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedCallNowButtonItmEndAppReceived])

    // NOTE - Clicked Call Href ITM End App Received
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_call_now_href_itm_app_received']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedCallNowHrefItmEndAppReceived])

    // !SECTION - ITM END APP RECEIVED

    // SECTION - ITM END EXISTING

    // NOTE - Page Reached ITM End Existing
    useEffect(() => {
        if (environmentState.aceUsersDbApi && formDataState.aceUserPK) {
            callAceUsersDb({
                "main_demo": {
                    ['received_page_itm_existing']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.pageReachedItmEndExisting])

    // NOTE - Clicked Call Button ITM End Existing
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_call_now_button_itm_existing']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedCallNowButtonItmEndExisting])

    // NOTE - Clicked Call Href ITM End Existing
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_call_now_href_itm_existing']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedCallNowHrefItmEndExisting])

    // !SECTION - ITM END EXISTING


    // SECTION - ITM END SUCCESS OPT TRANSFER

    // NOTE - Page Reached ITM End Success Opt Transfer
    useEffect(() => {
        if (environmentState.aceUsersDbApi && formDataState.aceUserPK) {
            callAceUsersDb({
                "main_demo": {
                    ['received_page_itm_end_success_opt_transfer']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.pageReachedItmEndSuccessOptTransfer])

    // NOTE - Clicked Call Button ITM End Success Opt Transfer
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_call_now_button_itm_success_opt_transfer']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedCallNowButtonItmEndSuccessOptTransfer])

    // NOTE - Clicked Call Href ITM End Success Opt Transfer
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_call_now_href_itm_success_opt_transfer']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedCallNowHrefItmEndSuccessOptTransfer])

    // NOTE - Clicked Learn More ITM End Success Opt Transfer
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_learn_more_itm_success_opt_transfer']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedLearnMoreItmEndSuccessOpt])

    // !SECTION - ITM END SUCCESS OPT TRANSFER


    // SECTION - ITM END SUCCESS OPT NO TRANSFER

    // NOTE - Page Reached ITM End Success Opt No Transfer
    useEffect(() => {
        if (environmentState.aceUsersDbApi && formDataState.aceUserPK) {
            callAceUsersDb({
                "main_demo": {
                    ['received_page_itm_end_success_opt_no_transfer']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.pageReachedItmEndSuccessOptNoTransfer])

    // NOTE - Clicked Call Button ITM End Success Opt No Transfer
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_call_now_button_itm_success_opt']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedCallNowButtonItmEndSuccessOpt])

    // NOTE - Clicked Call Href ITM End Success Opt No Transfer
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_call_now_href_itm_success_opt']: true
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedCallNowHrefItmEndSuccessOpt])

    // !SECTION - ITM END SUCCESS OPT NO TRANSFER

    // NOTE - Selected Optimum Offer
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            // console.log(formDataState.optimumOffer)
            callAceUsersDb({
                "main_demo": {
                    ['optimum_offer']: formDataState.optimumOffer
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.optimumOffer])
    
    // NOTE - Clicked offers disclaimers
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_offers_disclaimers']: formDataState.clickedOffersDisclaimers
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedOffersDisclaimers])

    // NOTE - Clicked offers call now
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_offers_call_now']: formDataState.clickedOffersCallNow
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedOffersCallNow])

    // NOTE - Clicked optimum success with submit call now
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_call_now_itm_p3_submit_opt']: formDataState.clickedCallNowItmP3SubmitOpt
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedCallNowItmP3SubmitOpt])

    // NOTE - Clicked offers call now
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_bb_checkbox']: formDataState.clickedBbCheckbox
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.clickedBbCheckbox])

    // NOTE - Clicked offers call now
    useEffect(() => {
        if(environmentState.aceUsersDbApi && formDataState.aceUserPK){
            callAceUsersDb({
                "main_demo": {
                    ['clicked_submit_demo_form']: formDataState.submitDemoForm
                }
            }, false, formDataState?.eligibleProviders ? formDataState?.topServiceableCompany : "combo")
        }
    }, [formDataState.submitDemoForm])

    // ANCHOR - Helper function to call the aceUsers DB.
    const callAceUsersDb = (stepObject, setAceUserKey=false, authorization = "combo") => {
        if(formDataState.aceUserPK){
            stepObject.PK = formDataState.aceUserPK
        }
        const headersObj = {
            "x-api-key": environmentState.aceUsersDbApi.key
        }

        if("eligibleProvidersArray" in formDataState && formDataState.eligibleProvidersArray.length == 0){
            headersObj.Authorization = "unserviceable"
        } else {
            headersObj.Authorization = authorization
        }
        fetch(environmentState.aceUsersDbApi.url + "/put_user", {
            method: "PUT",
            headers: headersObj,
            body: JSON.stringify(stepObject)
        }).then((res) => res.json()).then((data) => {
            if(setAceUserKey){
                formDataDispatch({ type: 'SET_ACE_USER_DB_KEY', payload: data.key });
            }
        });
    }

    return <></>
}

export default DbTracking;
