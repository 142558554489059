// Packages
import React, { useEffect, useContext, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

// Contexts
import { ConfigContext } from '../contexts/Config';
import { FormDataContext } from '../contexts/FormData';
import { StepsContext } from '../contexts/Steps';
import { EnvironmentContext } from '../contexts/Environment';
import { IsLoadingContext } from '../contexts/IsLoading';

function CheckForSplitTest() {
    // Context and params
    const { company } = useParams();
    const [configState, configDispatch] = useContext(ConfigContext)
    const [formDataState, formDataDispatch] = useContext(FormDataContext)
    const [stepsState, stepsDispatch] = useContext(StepsContext)
    const [environment, environmentDispatch] = useContext(EnvironmentContext)
    const [IsLoadingState, isLoadingDispatch] = useContext(IsLoadingContext)
    const [searchParams] = useSearchParams();
    const [splitFinished, setSplitFinished] = useState(0)
    const [response, setResponse] = useState({})

    useEffect(() => {
        if (splitFinished > 0) {
            formDataDispatch({
                type: "SET_IS_BENEFIT_SPLIT",
                payload: (searchParams.get("splitSeed") ? Number(searchParams.get("splitSeed")) : response.randomNumber) <= (configState?.benefitsSplit ? configState.benefitsSplit : 0)
            })
            formDataDispatch({
                type: "SET_USER_GOT_WELCOME_PAGE_SPLIT",
                payload: (searchParams.get("splitSeed") ? Number(searchParams.get("splitSeed")) : response.randomNumber) <= (configState?.welcomePageSplit ? configState.welcomePageSplit : 0)
            })
            isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "SetSplits" })
        }
    }, [splitFinished])

    useEffect(() => {
        if (configState.benefitsSplit) {
            isLoadingDispatch({ type: "ADD_INPUT_BLOCKER", payload: "SetSplits" })
            if (formDataState.aceUserPK) {
                const headers = {
                    Authorization: company,
                    "X-Api-Key": environment.utilityApi.key
                }
                const body = {
                    "seed": formDataState.aceUserPK
                }
                fetch(`${environment.utilityApi.url}/random-number`, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setSplitFinished(prev => prev + 1)
                        setResponse(data)
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    }, [configState, formDataState.aceUserPK])

    return <></>
}

export default CheckForSplitTest;
