// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { GrDown, GrUp } from 'react-icons/gr';

// Props of the Component
const propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    moreText: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    lessText: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    style: PropTypes.string,
    addPrimaryShowMargin: PropTypes.bool
};
const defaultProps = {
    onClick: () => {},
    className: '',
    moreText: <>Show More <GrDown/></>,
    lessText: <>Show Less <GrUp /></>,
    style: "showMore",
    addPrimaryShowMargin: false
};

function Button({ onClick, className, moreText, lessText, style, addPrimaryShowMargin }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [buttonText, setButtonText] = useState(moreText)
    const [buttonState, setButtonState] = useState(false)

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ShowMore/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ShowMore/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/ShowMore.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/ShowMore.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ShowMore/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ShowMore/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <button
            className={`${classNames[style]} ${addPrimaryShowMargin ? classNames.primaryShowMoreMargin : ''} ${className}`}
            type="button"
            onClick={() => {
                onClick(!buttonState);
                setButtonText(!buttonState ? lessText : moreText)
                setButtonState(prev => !prev)
            }}
        >
            {buttonText}
        </button>
    );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
