// Packages
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// UI
import AddressField from '../../../ui/AddressField';
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
// Helpers
import CheckBox from '../../../ui/CheckBox';
import ConditionalRender from '../../../components/ConditionalRender';
import {
    runMultipleValidationFunctions,
    requireNonEmptyValue,
    requireNotPoBox,
    requireValidAddress,
    requireResidentialAddress,
    requireAddressLine2IfAddressHasUnits,
    requireValidAddressLine2
} from '../../../helpers/validation';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { ValidationContext } from '../../../contexts/Validation';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function Address({ step, mode, onNext, onPrev }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [differentShippingAddress, setDifferentShippingAddress] = useState(formData.shippingAddress !== null);
    const validationDispatch = useContext(ValidationContext)[1];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Address/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/Address/index.less`);
        });
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Address/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/Address/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <div className={classNames.centerItems}>
            <HeadingText>Address</HeadingText>
            <SubHeadingText>
                {
                    company === "gotech" || company == "gotechacp" ? "Enter your address information below to make sure that we can provide service in your area." : "Enter your home address below so we can check your eligibility for FREE Wireless Service."
                }
            </SubHeadingText>
            <AddressField
                subLabelText="(Cannot be a PO Box)"
                addAsterisk={true}
                step={step}
                initialValue={formData.address}
                validationKey="addressObject"
                validationFunction={(value) =>
                    runMultipleValidationFunctions(value, [
                        requireNotPoBox,
                        requireValidAddress,
                        requireResidentialAddress,
                        requireAddressLine2IfAddressHasUnits,
                        requireValidAddressLine2
                    ])
                }
                line1ValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                cityValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                stateValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                zipValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                onChange={(addressObject) => {
                    formDataDispatch({
                        type: 'SET_ADDRESS',
                        payload: addressObject,
                    });
                }}
            >
                Home Address
            </AddressField>
            <CheckBox
                name="Temporary Address"
                onChecked={(checked) => {
                    if (checked) {
                        formDataDispatch({
                            type: 'SET_TEMPORARY_ADDRESS',
                            payload: "Temporary",
                        });
                    } else {
                        formDataDispatch({
                            type: 'SET_TEMPORARY_ADDRESS',
                            payload: "Permanent",
                        });
                    }
                }}
            >
                I am at this address temporarily
            </CheckBox>
            <CheckBox
                name="Is Shipping Address"
                initialCheck={formData.shippingAddress !== null}
                onChecked={(checked) => {
                    setDifferentShippingAddress(checked);
                    if (!checked) {
                        formDataDispatch({
                            type: 'SET_SHIPPING_ADDRESS_NULL',
                            payload: null,
                        });
                        validationDispatch({
                            type: 'SET_SHIPPINGADDRESSOBJECT',
                            payload: { isValid: true, step },
                        });
                    } else {
                        validationDispatch({
                            type: 'SET_SHIPPINGADDRESSOBJECT',
                            payload: { isValid: false, step },
                        });
                    }
                }}
            >
                Add a PO Box or different shipping address
            </CheckBox>
            <ConditionalRender condition={differentShippingAddress}>
                <AddressField
                    step={step}
                    line2Label="Apt, Unit, or Lot #"
                    initialValue={formData.shippingAddress}
                    validationKey="shippingAddressObject"
                    validationFunction={(value) =>
                        runMultipleValidationFunctions(value, [
                            requireValidAddress,
                            requireAddressLine2IfAddressHasUnits,
                            requireValidAddressLine2
                        ])
                    }
                    line1ValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                    cityValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                    stateValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                    zipValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                    onChange={(addressObject) => {
                        formDataDispatch({
                            type: 'SET_SHIPPING_ADDRESS',
                            payload: addressObject,
                        });
                    }}
                >
                    Shipping Address or PO Box
                </AddressField>
            </ConditionalRender>
        </div>
    );
}

Address.propTypes = propTypes;
Address.defaultProps = defaultProps;

export default Address;
