const ValidationReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FIELDS':
            return { ...state, fields: action.payload };
        case 'RELOAD_VALIDATION':
            return { ...state, reloadValidation: state.reloadValidation + 1 };
        case 'RESET_RELOAD_VALIDATION':
            return { ...state, reloadValidation: 0 };
        case 'SET_WAITING_TRUE':
            return { ...state, waitingForValidationCompletion: {  ...state.waitingForValidationCompletion, [action.payload]: true } };
        case 'SET_WAITING_FALSE':
            return { ...state, waitingForValidationCompletion: {  ...state.waitingForValidationCompletion, [action.payload]: false } };
        case 'SET_ACCEPTEDDISCLOSURES':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    acceptedDisclosures: action.payload,
                },
            };
        case 'SET_ACCEPTEDDISCLOSURES_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    acceptedDisclosures: { ...state.fields.acceptedDisclosures, step: action.payload },
                },
            };
        case 'SET_ADDRESSOBJECT':
            return { ...state, fields: { ...state.fields, addressObject: action.payload } };
        case 'SET_ADDRESSOBJECT_ISVALID':
            return { ...state, fields: { ...state.fields, addressObject: { ...state.fields.addressObject, isValid: action.payload } } };
        case 'SET_ADDRESSOBJECT_STEP':
            return {
                ...state,
                fields: { ...state.fields, addressObject: { ...state.fields.addressObject, step: action.payload } },
            };
        case 'SET_PREVIOUSADDRESSOBJECT':
            return { ...state, fields: { ...state.fields, previousAddressObject: action.payload } };
        case 'SET_PREVIOUSADDRESSOBJECT_ISVALID':
            return { ...state, fields: { ...state.fields, previousAddressObject: { ...state.fields.previousAddressObject, isValid: action.payload } } };
        case 'SET_PREVIOUSADDRESSOBJECT_STEP':
            return {
                ...state,
                fields: { ...state.fields, previousAddressObject: { ...state.fields.previousAddressObject, step: action.payload } },
            };
        case 'SET_SHIPPINGADDRESSOBJECT':
            return { ...state, fields: { ...state.fields, shippingAddressObject: action.payload } };
        case 'SET_SHIPPINGADDRESSOBJECT_ISVALID':
            return { ...state, fields: { ...state.fields, shippingAddressObject: { ...state.fields.shippingAddressObject, isValid: action.payload } } };
        case 'SET_SHIPPINGADDRESSOBJECT_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    shippingAddressObject: { ...state.fields.shippingAddressObject, step: action.payload },
                },
            };
        case 'SET_PROGRAMS':
            return { ...state, fields: { ...state.fields, programs: action.payload } };
        case 'SET_PROGRAMS_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    programs: { ...state.fields.programs, step: action.payload },
                },
            };
        case 'SET_PROGRAMS_ISVALID':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    programs: { ...state.fields.programs, isValid: action.payload },
                },
            };
        case 'SET_FIRSTNAME':
            return { ...state, fields: { ...state.fields, firstName: action.payload } };
        case 'SET_FIRSTNAME_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    firstName: { ...state.fields.firstName, step: action.payload },
                },
            };
        case 'SET_MIDDLENAME':
            return { ...state, fields: { ...state.fields, middleName: action.payload } };
        case 'SET_MIDDLENAME_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    middleName: { ...state.fields.middleName, step: action.payload },
                },
            };
        case 'SET_LASTNAME':
            return { ...state, fields: { ...state.fields, lastName: action.payload } };
        case 'SET_LASTNAME_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    lastName: { ...state.fields.lastName, step: action.payload },
                },
            };
        case 'SET_SECONDLASTNAME':
            return { ...state, fields: { ...state.fields, secondLastName: action.payload } };
        case 'SET_SECONDLASTNAME_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    secondLastName: { ...state.fields.secondLastName, step: action.payload },
                },
            };
        case 'SET_PHONENUMBER':
            return { ...state, fields: { ...state.fields, phoneNumber: action.payload } };
        case 'SET_PHONENUMBER_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    phoneNumber: { ...state.fields.phoneNumber, step: action.payload },
                },
            };
        case 'SET_DOB':
            return { ...state, fields: { ...state.fields, dob: action.payload } };
        case 'SET_DOB_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dob: { ...state.fields.dob, step: action.payload },
                },
            };
        case 'SET_SSN':
            return { ...state, fields: { ...state.fields, ssn: action.payload } };
        case 'SET_SSN_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    ssn: { ...state.fields.ssn, step: action.payload },
                },
            };
        case 'SET_CLICKEDACTIVATEDEMO':
            return { ...state, fields: { ...state.fields, clickedActivateDemo: action.payload } };
        case 'SET_CLICKEDACTIVATEDEMO_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    clickedActivateDemo: { ...state.fields.clickedActivateDemo, step: action.payload },
                },
            };
        case 'SET_BQPFIRSTNAME':
            return { ...state, fields: { ...state.fields, bqpFirstName: action.payload } };
        case 'SET_BQPFIRSTNAME_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    bqpFirstName: { ...state.fields.bqpFirstName, step: action.payload },
                },
            };
        case 'SET_BQPLASTNAME':
            return { ...state, fields: { ...state.fields, bqpLastName: action.payload } };
        case 'SET_BQPLASTNAME_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    bqpLastName: { ...state.fields.bqpLastName, step: action.payload },
                },
            };
        case 'SET_BQPDOB':
            return { ...state, fields: { ...state.fields, bqpDob: action.payload } };
        case 'SET_BQPDOB_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    bqpDob: { ...state.fields.bqpDob, step: action.payload },
                },
            };
        case 'SET_BQPSSN':
            return { ...state, fields: { ...state.fields, bqpSsn: action.payload } };
        case 'SET_BQPSSN_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    bqpSsn: { ...state.fields.bqpSsn, step: action.payload },
                },
            };
        case 'SET_USERMOBILECONSENT':
            return { ...state, fields: { ...state.fields, userMobileConsent: action.payload } };
        case 'SET_USERMOBILECONSENT_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    userMobileConsent: { ...state.fields.userMobileConsent, step: action.payload },
                },
            };
        case 'SET_EMAIL':
            return { ...state, fields: { ...state.fields, email: action.payload } };
        case 'SET_EMAIL_STEP':
            return { ...state, fields: { ...state.fields, email: { ...state.fields.email, step: action.payload } } };
        case 'SET_FRONTIERCOMMUNICATION_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    frontierCommunication: { ...state.fields.frontierCommunication, step: action.payload },
                },
            };
        case 'SET_FRONTIERCOMMUNICATION':
            return { ...state, fields: { ...state.fields, frontierCommunication: action.payload } };
        case 'SET_FRONTIERCOMMUNICATIONTOGGLES':
            return { ...state, fields: { ...state.fields, frontierCommunicationToggles: action.payload } };
        case 'SET_FRONTIERCOMMUNICATIONTOGGLES_STEP':
            return {
                ...state,
                fields: {
                    ...state.fields,
                    frontierCommunicationToggles: { ...state.fields.frontierCommunicationToggles, step: action.payload },
                },
            };
        default:
            const splitActionType = action.type.split("_")
            const validationKey = splitActionType[1].replace(/([-_][a-z])/ig, ($1) => {
                return $1.toUpperCase()
                  .replace('-', '')
                  .replace('_', '');
              });
            
            if (splitActionType.length > 2) {
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        [splitActionType[1]]: { ...state.fields[splitActionType[1]], step: action.payload },
                    },
                };
            }
            else {
                return { ...state, fields: { ...state.fields, [splitActionType[1]]: action.payload } };
            }
}
};

export default ValidationReducer;
