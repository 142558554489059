const classNames = {
    label: 'label-textfield',
    textfield: 'input-textfield',
    validationMessage: 'error-text-textfield',
    errorInput: 'input-error-textfield',
    subLabel: 'sublabel-textfield',
    inputDropdown: 'input-dropdown-textfield',
    dropdown: 'dropdown-textfield',
    labelText: 'label-text-textfield',
    popUpContent: 'popup-content-textfield',
    infoButton: 'textfield-info-button'
};

export default classNames;
