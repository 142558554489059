const StepsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_STEPS': {
            let timeStamps = {}
            if (action?.payload?.steps?.[0]?.name) {
                timeStamps = { [action.payload.steps[0].name]: { currentIndex: 0, totalTime: 0, times: [{ startTime: Date.now() }] }}
            }

            return { ...state, steps: action.payload, timeStamps };
        }
        case 'SET_STEPS_AND_METADATA': {
            let timeStamps = {}
            if (action?.payload?.steps?.[0]?.name) {
                timeStamps = { [action.payload.steps[0].name]: { currentIndex: 0, times: [{ startTime: Date.now() }] }}
            }

            return { ...state, metaData: action.payload.metaData, steps: action.payload.steps, timeStamps };
        }
        case 'APPEND_STEP':
            return { ...state, steps: [...state.steps, action.payload]};
        case 'APPEND_STEPS':
            return { ...state, steps: [...state.steps, ...action.payload]};
        case 'REMOVE_STEP':
            let tempSteps = [ ...state.steps ]
            tempSteps.splice(action.payload, 1)
            return { ...state, steps: tempSteps};
        case 'REMOVE_COMBO_STEPS':
            // based on the company, remove last number of appended steps
            const companyStepNumber = {
                optimum: 2,
                frontier: 6,
                spectrum: 2,
                viasat: 2,
                natwireless: 2,
                via: 2,
                redpocket: 2
            }[action.payload];
            let tempComboSteps = [...state.steps];
            tempComboSteps.splice(-1 * companyStepNumber, companyStepNumber);
            return {...state, steps: tempComboSteps};
        case 'SET_CURRENT_STEP':
            return { ...state, currentStep: action.payload };
        case 'INCREMENT_STEP': {
            let tempState = { ...state }
            if (tempState?.steps?.[tempState?.currentStep]?.name) {
                const currentTime = Date.now()
                // console.log(`(${tempState.timeStamps[tempState.steps[tempState.currentStep].name].totalTime} ? ${tempState.timeStamps[tempState.steps[tempState.currentStep].name].totalTime} : 0) + (${currentTime} - ${tempState.timeStamps[tempState.steps[tempState.currentStep].name].times[tempState.timeStamps[tempState.steps[tempState.currentStep].name].currentIndex].startTime})`)
                tempState.timeStamps[tempState.steps[tempState.currentStep].name].totalTime = (tempState.timeStamps[tempState.steps[tempState.currentStep].name].totalTime ? tempState.timeStamps[tempState.steps[tempState.currentStep].name].totalTime : 0) + (currentTime - tempState.timeStamps[tempState.steps[tempState.currentStep].name].times[tempState.timeStamps[tempState.steps[tempState.currentStep].name].currentIndex].startTime)
                tempState.timeStamps[tempState.steps[tempState.currentStep].name].times[tempState.timeStamps[tempState.steps[tempState.currentStep].name].currentIndex].endTime = currentTime
                
            }
            if (tempState?.steps?.[tempState?.currentStep + 1]?.name) {
                if (tempState.timeStamps[tempState.steps[tempState.currentStep + 1].name]?.currentIndex || tempState.timeStamps[tempState.steps[tempState.currentStep + 1].name]?.currentIndex === 0) {
                    tempState.timeStamps[tempState.steps[tempState.currentStep + 1].name] = { ...tempState.timeStamps[tempState.steps[tempState.currentStep + 1].name], currentIndex: tempState.timeStamps[tempState.steps[tempState.currentStep + 1].name].currentIndex + 1, times: [ ...tempState.timeStamps[tempState.steps[tempState.currentStep + 1].name].times, { startTime: Date.now() }] }
                } else {
                    tempState.timeStamps[tempState.steps[tempState.currentStep + 1].name] = { currentIndex: 0, totalTime: 0, times: [{ startTime: Date.now() }] }
                }
            }

            tempState.currentStep = tempState.currentStep + 1
            return tempState;
        }
        case 'DECREMENT_STEP': {
            let tempState = { ...state }
            if (tempState?.steps?.[tempState?.currentStep]?.name) {
                const currentTime = Date.now()
                tempState.timeStamps[tempState.steps[tempState.currentStep].name].totalTime = (tempState.timeStamps[tempState.steps[tempState.currentStep].name].totalTime ? tempState.timeStamps[tempState.steps[tempState.currentStep].name].totalTime : 0) + (currentTime - tempState.timeStamps[tempState.steps[tempState.currentStep].name].times[tempState.timeStamps[tempState.steps[tempState.currentStep].name].currentIndex].startTime)
                tempState.timeStamps[tempState.steps[tempState.currentStep].name].times[tempState.timeStamps[tempState.steps[tempState.currentStep].name].currentIndex].endTime = currentTime
            }
            if (tempState?.steps?.[tempState?.currentStep - 1]?.name) {
                if (tempState.timeStamps[tempState.steps[tempState.currentStep - 1].name]?.currentIndex || tempState.timeStamps[tempState.steps[tempState.currentStep - 1].name]?.currentIndex === 0) {
                    tempState.timeStamps[tempState.steps[tempState.currentStep - 1].name] = { ...tempState.timeStamps[tempState.steps[tempState.currentStep + 1].name], currentIndex: tempState.timeStamps[tempState.steps[tempState.currentStep - 1].name].currentIndex + 1, totalTime: 0, times: [ ...tempState.timeStamps[tempState.steps[tempState.currentStep - 1].name].times, { startTime: Date.now() }] }
                }
            }

            tempState.currentStep = tempState.currentStep - 1
            return tempState;
        }
        case 'INCREMENT_METADATA_SUBTRACTFROMSTEPSLENGTH':
            return { ...state, metaData: { ...state.metaData, subtractFromStepsLength: (state?.metaData?.subtractFromStepsLength ? state?.metaData?.subtractFromStepsLength : 0) + 1 } };
        case 'ADD_X_TO_METADATA_SUBTRACTFROMSTEPSLENGTH': 
            return { ...state, metaData: { ...state.metaData, subtractFromStepsLength: (state?.metaData?.subtractFromStepsLength ? state?.metaData?.subtractFromStepsLength : 0) + action.payload } };
        case 'SET_METADATA_SUBTRACTFROMSTEPSLENGTH': 
            return { ...state, metaData: { ...state.metaData, subtractFromStepsLength: action.payload } };
        case 'FIRE_ERROR': 
            if (state.metaData.errorStep) {
                return { 
                    ...state, 
                    currentStep: state.steps.length, 
                    metaData: { 
                        ...state.metaData, 
                        errorTitle: action.payload.title,
                        errorDescription: action.payload.description,
                        errorButtonText: action.payload.buttonText,
                        stepBeforeError: state.currentStep, 
                        errorStepIndex: state.steps.length,
                        timesToErrorPage: state?.metaData?.timesToErrorPage ? state?.metaData?.timesToErrorPage + 1 : 1
                    }, 
                    steps: [...state.steps, state.metaData.errorStep]
                };
            }
            
            return state
        case 'REMOVE_ERROR': 
            if (state.metaData.errorStepIndex) {
                let tempSteps = [ ...state.steps ]
                tempSteps.splice(state.metaData.errorStepIndex, 1)

                return { 
                    ...state, 
                    currentStep: state.metaData.stepBeforeError, 
                    steps: tempSteps, 
                    metaData: { 
                        ...state.metaData, 
                        errorTitle: null,
                        errorDescription: null,
                        errorButtonText: null,
                        stepBeforeError: null, 
                        errorStepIndex: null 
                    }
                };
            }
            
            return state
        case 'ADD_ASYNC_STEP_ADD': 
            return { 
                ...state, 
                metaData: { 
                    ...state.metaData, 
                    asyncStep: { 
                        ...state.metaData.asyncStep, 
                        [action.payload.name]: {
                            indexOccursOn: action.payload.indexOccursOn,
                            amountToSubtractFromLength: action.payload.amountToSubtractFromLength ? action.payload.amountToSubtractFromLength : 0
                        }
                    } 
                } 
            };
        case 'APPENDED_DYNAMIC_STEPS': 
            return { ...state, metaData: { ...state.metaData, addedDynamicSteps: true } };
        case 'INSERT_STEP': {
            // console.log("INSIDE INSERT STEP")
            // console.log(action.payload)
            state.steps.splice(action.payload.index, 0, action.payload.step)
            return { ...state }
        }
        case 'SET_PROGRESS_BAR':
            return {...state, setProgressBar: action.payload}
        default:
            return state;
    }
};

export default StepsReducer;
