// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from "moment";
import "moment-timezone";
// Components
import ConditionalRender from '../../../components/ConditionalRender'
// UI
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
import Calendar from '../../../ui/Calendar';
import Button from '../../../ui/Button';
import LabelText from '../../../ui/LabelText';
import SmallText from '../../../ui/SmallText';
import BodyText from '../../../ui/BodyText';
import DisplayPhoneNumber from '../../../ui/DisplayPhoneNumber';
import HeaderText from '../../../ui/HeaderText';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';
import { PopupContext } from '../../../contexts/Popup';
// Steps
import Disclosures from './Disclousres';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function FrontierScheduling({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [startedTimeout, setStartedTimeout] = useState(false);
    const [availableDates, setAvailableDates] = useState([]);
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const isLoadingDispatch = useContext(IsLoadingContext)[1];
    const [steps, stepsDispatch] = useContext(StepsContext);
    const environment = useContext(EnvironmentContext)[0];
    const [popupState, popupDispatch] = useContext(PopupContext);
    const [clickedAgree, setClickedAgree] = useState(0);
    const [timeZone, setTimeZone] = useState('');

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierScheduling/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierScheduling/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    useEffect(() => {
        const asyncFunction = async () => {
            isLoadingDispatch({ type: "ADD_INPUT_BLOCKER", payload: "FrontierGetSchedule"})
            const customerCreditBundleHeaders = { 
                "Authorization" : "frontier",
                "X-Api-Key": environment.frontierCustomerCreditCheckUrl.key
            }
            const customerCreditBundleBody = {
                quoteId: formData.quoteId
            }
            const customerCreditBundleResponse = await fetch(environment.frontierCustomerCreditBundleUrl.url, {
                method: 'POST',
                headers: customerCreditBundleHeaders,
                body: JSON.stringify(customerCreditBundleBody),
            })
            const customerCreditBundleObject = await customerCreditBundleResponse.json();
            const getHeaders = { 
                "Authorization" : "frontier",
                "X-Api-Key": environment.frontierGetQuoteUrl.key
            }
            const getQuoteResponse = await fetch(`${environment.frontierGetQuoteUrl.url}?quoteId=${formData.quoteId}`, {
                method: 'GET',
                headers: getHeaders
            })
            const getQuoteObject = await getQuoteResponse.json();
            // console.log(getQuoteObject.body?.customer?.serviceability?.timeZone?.timeZone)
            const getDepositRequirementsResponse = await fetch(`${environment.frontierGetDepositRequirementsUrl.url}?quoteId=${formData.quoteId}`, {
                method: 'GET',
                headers: getHeaders
            })
            const getDepositRequirementsObject = await getDepositRequirementsResponse.json();
            const getScheduleHeaders = { 
                "X-Api-Key": environment.frontierGetScheduleUrl.key,
                "Authorization": "frontier"
            }
            const getScheduleResponse = await fetch(`${environment.frontierGetScheduleUrl.url}?quoteId=${formData.quoteId}`, {
                method: 'GET',
                headers: getScheduleHeaders
            })
            const getScheduleObject = await getScheduleResponse.json()
            if (!(getScheduleObject.body)){
                stepsDispatch({
                    type: 'FIRE_ERROR',
                    payload: {
                        title: "Uh Oh... Something went wrong",
                        description: "Please click the button below to restart your application for internet service with Frontier!",
                        buttonText: "Try Again"
                    }
                });
            }
            var abbrs = {
                EST : 'Eastern Standard Time (EST)',
                EDT : 'Eastern Daylight Time (EDT)',
                CST : 'Central Standard Time (CST)',
                CDT : 'Central Daylight Time (CDT)',
                MST : 'Mountain Standard Time (MST)',
                MDT : 'Mountain Daylight Time (MDT)',
                PST : 'Pacific Standard Time (PST)',
                PDT : 'Pacific Daylight Time (PDT)',
            };
            
            moment.fn.zoneName = function () {
                var abbr = this.zoneAbbr();
                return abbrs[abbr] || abbr;
            };

            var timezone = moment.tz(getQuoteObject.body?.customer?.serviceability?.timeZone?.timeZone).format('zz') 
            setTimeZone(timezone)
            setAvailableDates(getScheduleObject.body)
            isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "FrontierGetSchedule" }) 
        }
        asyncFunction();
    }, [])

    useEffect(() => {
        if (clickedAgree > 0) {
            onNext()
        }
    }, [clickedAgree])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', flexGrow: '1' }}>
            <div>
                <HeadingText>Schedule Your Installation</HeadingText>
                <SubHeadingText>Pick a date and time that works for you. Your installation technician will arrive between the hours that you select.</SubHeadingText>
                <Calendar 
                    // onChange={(value) => { console.log(value) }} 
                    availableDates={availableDates} 
                    timeZone={timeZone}
                    dateKey="earliestStartTime"
                    formatFunction={(value) => {
                        return moment(value).format('MM/DD/YYYY')
                    }}
                    sideWindow={(activeDate) => {
                        const dateSelected = availableDates.filter(value => moment(value.earliestStartTime).format('MM/DD/YYYY') === activeDate)

                        return (
                            <div className={classNames.container}>
                                <div className={classNames.upperContainer}>
                                    <LabelText className={classNames.windowLabel}>Arrival Window</LabelText>
                                    <ConditionalRender condition={activeDate} falseReturn={<SmallText className={classNames.noneSelectedText}>Please select a date to view installation times.</SmallText>}>
                                        <div className={classNames.buttons}>
                                            {dateSelected.map((scheduleDate) => {
                                                return (
                                                    <Button 
                                                        style="outlined"
                                                        onClick={() => {
                                                            // console.log("Picked Schedule: ", scheduleDate)
                                                            popupDispatch({ type: 'ADD_POPUP', payload: {
                                                                title: "Terms & Conditions",
                                                                // onClick: () => { popupDispatch({ type: 'REMOVE_POPUP' }) },
                                                                component: (
                                                                    <>
                                                                        <Disclosures step={step} disableHead={true} disableCheck={false} />
                                                                        <Button style="singleDisclosuresNext" onClick={() => { 
                                                                            setClickedAgree(prev => prev + 1)
                                                                        }} >
                                                                            Accept and Continue
                                                                        </Button>
                                                                        {/* <Button style="disclosuresPrevious" onClick={() => { 
                                                                            popupDispatch({ type: 'REMOVE_POPUP' })
                                                                        }} >Previous</Button> */}
                                                                    </>
                                                            )}})
                                                            formDataDispatch({ type: 'SET_SCHEDULE_ID', payload: scheduleDate.scheduleId.toString() });
                                                            formDataDispatch({ type: 'SET_INSTALL_DATE', payload: moment(scheduleDate.earliestStartTime).format('MMMM Do YYYY, h:mm:ss a') });    
                                                        }}
                                                    >{moment(scheduleDate?.earliestStartTime).format('hA').toLowerCase()} - {moment(scheduleDate?.latestStartTime).format('hA').toLowerCase()}</Button>
                                                )
                                            })}
                                        </div>
                                    </ConditionalRender>
                                </div>
                                <SubHeadingText className={classNames.timeZoneLabel}>{timeZone}</SubHeadingText>
                            </div>
                        )
                    }}
                />
                <div className={classNames.callCenterContainer}>
                    <HeaderText>Need Help?</HeaderText>
                    <div className={classNames.phoneNumberContainer}>
                        <BodyText className={classNames.callAgentText} >Talk to an agent:</BodyText>
                        <svg className={classNames.phoneIcon} width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18009 0.683884C3.38774 0.577512 3.61601 0.515729 3.84978 0.502632C4.08354 0.489534 4.31747 0.525421 4.53607 0.607913C4.75467 0.690405 4.95293 0.817621 5.11772 0.981126C5.28251 1.14463 5.41006 1.34069 5.49194 1.55632L6.4596 4.10467C6.63687 4.57206 6.58538 5.09373 6.32044 5.51928L5.20506 7.31165C5.14733 7.40451 5.11501 7.51045 5.11124 7.6192C5.10746 7.72794 5.13236 7.83581 5.18349 7.93231L6.70644 10.7993C6.75781 10.8959 6.83375 10.9778 6.92687 11.037C7.01998 11.0962 7.1271 11.1306 7.23779 11.1369L9.37065 11.2615C9.6207 11.2761 9.8641 11.3463 10.0824 11.4669C10.3008 11.5875 10.4884 11.7554 10.6311 11.9578L12.2057 14.1939C12.7717 14.9979 12.4929 16.1069 11.6124 16.5581L10.3848 17.1881C9.5064 17.6389 8.41497 17.6192 7.58644 17.0094C5.46568 15.4492 3.72953 13.4395 2.50675 11.1297C1.27742 8.82352 0.59203 6.2749 0.50143 3.67285C0.466522 2.65721 1.07414 1.76472 1.95262 1.31387L3.18009 0.683884Z" fill="#0022F0"/>
                        </svg>
                        <DisplayPhoneNumber noOne className={classNames.callCenterPhoneNumber}>2068146798</DisplayPhoneNumber>
                    </div>
                </div>
            </div>
        </div>
    )
}

FrontierScheduling.propTypes = propTypes;
FrontierScheduling.defaultProps = defaultProps;

export default FrontierScheduling;