const classNames = {
    label: 'label-dropdown',
    dropdown: 'select-dropdown',
    validationMessage: 'validation-message-dropdown',
    dropDownError: 'select-dropdown-error',
    labelText: 'select-dropdown-label',
    fullWidth: 'select-dropdown-full-width',
    noBottomMargin: 'select-dropdown-no-bottom-margin'
};

export default classNames;
