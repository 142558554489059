import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

export const addressRequest = (userId, formData) => {
    const body = {
        userId,
        "mc_address": formData?.address?.parsedAddress?.street ? formData.address.parsedAddress.street: -1,
        "mc_addressLn2": formData?.address?.parsedAddress?.line2 ? formData.address.parsedAddress.line2: -1,
        "mc_city": formData?.address?.parsedAddress?.city ? formData.address.parsedAddress.city: -1,
        "mc_state": formData.address.parsedAddress.state,
        "mc_zipCode": formData.address.parsedAddress.zip,
        "tempAddress": formData.temporaryAddress,
        "mc_shippingAddress": formData.shippingAddress?.parsedAddress?.street ? formData.shippingAddress.parsedAddress.street: -1,
        "mc_shippingAddressLn2": formData.shippingAddress?.parsedAddress?.line2 ? formData.shippingAddress.parsedAddress.line2 : -1,
        "mc_shippingCity": formData.shippingAddress?.parsedAddress?.city ? formData.shippingAddress.parsedAddress.city : -1,
        "mc_shippingState": formData.shippingAddress?.parsedAddress?.state ? formData.shippingAddress.parsedAddress.state : -1,
        "mc_shippingZipCode": formData.shippingAddress?.parsedAddress?.zip ? formData.shippingAddress.parsedAddress.zip : -1,
    }

    return body
}

export const programsRequest = (userId, formData) => {
    const body = {
        userId,
        "mc_benefitCode": formData.programs[0].program_code
    }

    return body
}

export const piiRequest = (userId, formData) => {
    const dobArray = formData.dob ? formData.dob.split("/") : -1
    const body = {
        userId,
        "mc_firstName": formData.firstName ? formData.firstName : -1,
        "mc_lastName": formData.lastName ? formData.lastName : -1,
        "mc_middleName": formData.middleName ? formData.middleName : -1,
        "mc_secondLastName": formData.secondLastName ? formData.secondLastName : -1,
        "mc_suffix": formData.suffix ? formData.suffix : -1,
        "mc_phone": formData.phoneNumber ? formData.phoneNumber : -1,
        "display_dob": formData.dob ? formData.dob : -1,
        "mc_dob": formData.dob !== -1 ? dobArray[2] + "-" + dobArray[0] + "-" + dobArray[1] : -1,
        "mc_email": formData.email ? formData.email : -1,
        "mc_ssn": formData.ssn ? formData.ssn : -1,
        "clicked_activate_demo": formData.clickedActivateDemo !== null ? formData.clickedActivateDemo : true
    }

    return body
}

export const piiRequestCombo = (userId, formData) => {
    const dobArray = formData.dob ? formData.dob.split("/") : -1
    let body = {
        userId,
        "mc_firstName": formData.firstName ? formData.firstName : -1,
        "mc_lastName": formData.lastName ? formData.lastName : -1,
        "mc_middleName": formData.middleName ? formData.middleName : -1,
        "mc_secondLastName": formData.secondLastName ? formData.secondLastName : -1,
        "mc_suffix": formData.suffix ? formData.suffix : -1,
        "mc_phone": formData.phoneNumber ? formData.phoneNumber : -1,
        "display_dob": formData.dob ? formData.dob : -1,
        "mc_dob": formData.dob !== -1 ? dobArray[2] + "-" + dobArray[0] + "-" + dobArray[1] : -1,
        "mc_ssn": formData.ssn ? formData.ssn : -1,
        "bqp_first_name": formData.bqpFirstName ? formData.bqpFirstName : -1,
        "bqp_last_name": formData.bqpLastName ? formData.bqpLastName : -1,
        "bqp_dob": formData.bqpDob ? formData.bqpDob : -1,
        "bqp_ssn": formData.bqpSsn ? formData.bqpSsn : -1,
        "mc_email": formData.email ? formData.email : -1,
        "acp_subscriber_id": formData.acpId ? formData.acpId : -1,
        // "fc_serviceability_company": formData.topServiceableCompany ? formData.topServiceableCompany : -1,
        "clicked_activate_demo": formData.clickedActivateDemo ? formData.clickedActivateDemo : -1
    }

    if(formData.ssn.length > 4){
        body["mc_ssn"] = formData.ssn.slice(5)
    }

    return body
}

export const piiRequestOptimum = (userId, formData, add_ssn) => {
    const body = {
        userId,
        "mc_firstName": formData.firstName ? formData.firstName : -1,
        "mc_lastName": formData.lastName ? formData.lastName : -1,
        "mc_middleName": formData.middleName ? formData.middleName : -1,
        "mc_secondLastName": formData.secondLastName ? formData.secondLastName : -1,
        "mc_suffix": formData.suffix ? formData.suffix : -1,
        "mc_phone": formData.phoneNumber ? formData.phoneNumber : -1,
        "display_dob": formData.dob ? formData.dob : -1,
        "mc_dob": formData.dob ? `${new Date(formData.dob).getTime()}` : -1,
        "user_mobile_consent": formData.userMobileConsent ? formData.userMobileConsent : false,
    }

    // if(add_ssn){
    //     body["mc_ssn"] = formData.ssn.slice(5)
    //     body["secure_pii_pk"] = add_ssn
    // }

    return body
}

export const piiRequestFreeconnect = (userId, formData) => {
    const dobArray = formData.dob ? formData.dob.split("/") : -1
    let body = {
        userId,
        "mc_firstName": formData.firstName ? formData.firstName : -1,
        "mc_lastName": formData.lastName ? formData.lastName : -1,
        "mc_middleName": formData.middleName ? formData.middleName : -1,
        "mc_secondLastName": formData.secondLastName ? formData.secondLastName : -1,
        "mc_suffix": formData.suffix ? formData.suffix : -1,
        "mc_phone": formData.phoneNumber ? formData.phoneNumber : -1,
        "display_dob": formData.dob ? formData.dob : -1,
        "mc_dob": formData.dob !== -1 ? dobArray[2] + "-" + dobArray[0] + "-" + dobArray[1] : -1,
        "mc_ssn": formData.ssn ? formData.ssn : -1,
        "bqp_first_name": formData.bqpFirstName ? formData.bqpFirstName : -1,
        "bqp_last_name": formData.bqpLastName ? formData.bqpLastName : -1,
        "bqp_dob": formData.bqpDob ? formData.bqpDob : -1,
        "bqp_ssn": formData.bqpSsn ? formData.bqpSsn : -1,
        "mc_email": formData.email ? formData.email : -1
    }

    if (formData.serviceableCompany === "frontier") {
        body["accepted_disclosures"] = formData.acceptedDisclosures
        body["nv_application_id"] = formData.acpId ? formData.acpId : -1
    }

    if (formData.serviceableCompany === "optimum") {
        body["accepted_disclosures"] = formData.acceptedDisclosures
    }

    if(formData.ssn.length > 4){
        body["mc_ssn"] = formData.ssn.slice(5)
    }

    return body
}

export const freeconnectExistingCustomer = (userId, existingCustomer) => {
    const body = {
        userId,
        "is_existing_customer": existingCustomer
    }

    return body
}

export const transferRequest = (userId, formData) => {
    const body = {
        userId,
        "acp_wd_existing_benefit": formData.hasExistingAcp ? formData.hasExistingAcp : -1,
        "user_mobile_consent": formData.userMobileConsent ? formData.userMobileConsent : false,
        "trusted_forms_cert_url": formData.certUrl ? formData.certUrl : -1,
        "ace_user_pk": formData.aceUserPK ? formData.aceUserPK : -1,
        "fc_serviceability_company": formData.topServiceableCompany ? formData.topServiceableCompany : -1,
    }

    return body
}

export const frontierCommunicationRequestFreeconnect = (userId, formData) => {
    const body = {
        userId,
        "Acct-info-mobile": formData.accountInfoSMS ? formData.accountInfoSMS : -1,
        "Acct-info-email": formData.accountInfoEmail ? formData.accountInfoEmail : -1,
        "Service-update-mobile": formData.serviceUpdateSMS ? formData.serviceUpdateSMS : -1,
        "Service-update-email": formData.serviceUpdateEmail ? formData.serviceUpdateEmail : -1,
        "Market-promotion-mobile": formData.marketPromotionSMS ? formData.marketPromotionSMS: -1,
        "Market-promotion-email": formData.marketPromotionEmail ? formData.marketPromotionEmail : -1,
        "user_mobile_consent": formData.frontierCommunication ? formData.frontierCommunication : -1,
    }

    return body
}

export const frontierAcceptDisclosures = (userId, formData) => {
    let accepted_list = []
    formData.frontierDisclosureFields.forEach((disclosureField) => {
        accepted_list.push({
            "name": disclosureField.name,
            "GUID": disclosureField.GUID,
            "status": "ACCEPT"
        })
    })
    const body = {
        userId,
        "quoteId": formData.quoteId ? formData.quoteId : -1,
        "accepted_list" : accepted_list
    }

    return body
}

export const frontierFinishReserveSchedule = (userId, formData) => {
    const body = {
        userId,
        "quoteId": formData.quoteId,
        "scheduleId": formData.scheduleId,
        "name": formData.firstName + ` ${formData.lastName}`,
        "primaryPhone": Number(formData.phoneNumber)
    }

    return body
}

export const frontierCustomerIdentityVerification = (userId, formData) => {
    let answers = []
    const entries = Object.entries(formData.securityChallengeAnswers)
    entries.forEach((key) => {
        answers.push({"questionNumber": parseInt(key[0]), "answerNumber": key[1]})
    })
    const body = {
        userId,
        "quoteId": formData.quoteId,
        "accountUuid": formData.accountUuid,
        "securityChallengeQuestions": answers
    }

    return body
}

export const frontierVerifyCustomerPreviousAddress = (userId, formData) => {
    const body = {
        userId,
        "quoteId": formData.quoteId,
        "accountUuid": formData.accountUuid,
        "previousAddress": [{
            "addressLine1": formData.previousAddress.parsedAddress.street,
            "addressLine2": formData.previousAddress.parsedAddress.line2 ? formData.previousAddress.parsedAddress.line2 : "",
            "cityName": formData.previousAddress.parsedAddress.city,
            "stateAbbreviation": formData.previousAddress.parsedAddress.state,
            "zipCode": formData.previousAddress.parsedAddress.zip
        }]
    }

    return body
}

export const frontierErrorOccurred = (userId, formData) => {
    const body = {
        userId,
        "quoteId": -1,
        "accountUuid": -1,
        "product_id": -1,
        "schedule_id": -1,
        "installation_date": -1
    }

    return body
}

export const liveVoxBody = (formData, uuid, serviceability=true, company=formData.topServiceableCompany, existingCustomer=formData.existingCustomer) => {
    let livevoxBody = {
        "action": "add_contact",
        "active": true,
        "account": uuid,
        "email": formData.email,
        "address1": formData.address.parsedAddress.street,
        "address2": formData.address.parsedAddress.line2,
        "city": formData.address.parsedAddress.city,
        "zipCode": formData.address.parsedAddress.zip,
        "state": formData.address.parsedAddress.state,
        "country": "UNITED_STATES_OF_AMERICA",
        "firstName": formData.firstName,
        "lastName": formData.lastName,
        "phoneNumber": formData.phoneNumber,
        "phoneOrdinal": 1,
        "smsConsent": formData.userMobileConsent,
        "cellConsent": formData.userMobileConsent,
        "primaryEmailConsent": formData.userMobileConsent,
        "company": company,
        "leadStatus": "",
        "origin": formData.liveVoxOrigin,
        "leadPath": formData.liveVoxLeadPath ? formData.liveVoxLeadPath : `Facebook.ssn.Leadgen.${formData.liveVoxOrigin}`,
        "checkForTribal": true,
        "serviceabilityCheckResults": serviceability, // Since they made it here, they should be serviceable
        "send_to_joey": false,
        "dateOfBirth": `${new Date(formData.dob).getTime()}`,
        "ssnLead": false,
        "currentCustomer": existingCustomer ? existingCustomer : "NO"
    }

    if (formData.topServiceableCompany === "optimum" || formData.topServiceableCompany === "frontier" || formData.topServiceableCompany === "viasat" || formData.topServiceableCompany === "spectrum")  {
        livevoxBody["send_to_joey"] = true
    }

    if (formData.certUrl) {
        livevoxBody["TrustedForm_Cert_URL"] = formData.certUrl
    }

    return livevoxBody
}

export const liveVoxFtrInstall = (formData, uuid) => {
    let liveVoxBody = {
        "action": "add_contact",
        "account": uuid,
        "installDate": formData.preinstallDate,
        "installTime": formData.preinstallTime,
        "preinstall": true,
        "phoneOrdinal": 1,
        "phoneNumber": formData.phoneNumber,
        "origin": formData.liveVoxOrigin,
    }

    if (formData.certUrl) {
        liveVoxBody["TrustedForm_Cert_URL"] = formData.certUrl
    }

    return liveVoxBody
}

export const liveVoxFtrOffer = (formData, uuid) => {
    let liveVoxBody = {
        "action": "add_contact",
        "account": uuid,
        "frontierOffer": formData.frontierOffer,
        "phoneOrdinal": 1,
        "phoneNumber": formData.phoneNumber
    }

    return liveVoxBody
}

export const liveVoxNoCurrentCustomerBody = (formData, uuid) => {
    let liveVoxBody = {
        "action": "add_contact",
        "active": true,
        "account": uuid,
        "email": formData.email,
        "address1": formData.address.parsedAddress.street,
        "address2": formData.address.parsedAddress.line2,
        "city": formData.address.parsedAddress.city,
        "zipCode": formData.address.parsedAddress.zip,
        "state": formData.address.parsedAddress.state,
        "country": "UNITED_STATES_OF_AMERICA",
        "firstName": formData.firstName,
        "lastName": formData.lastName,
        "phoneNumber": formData.phoneNumber,
        "phoneOrdinal": 1,
        "smsConsent": formData.userMobileConsent,
        "cellConsent": formData.userMobileConsent,
        "primaryEmailConsent": formData.userMobileConsent,
        "company": formData.topServiceableCompany,
        "leadStatus": "",
        "origin": formData.liveVoxOrigin,
        "leadPath": formData.liveVoxLeadPath ? formData.liveVoxLeadPath : `Facebook.ssn.Leadgen.${formData.liveVoxOrigin}`,
        "checkForTribal": true,
        "serviceabilityCheckResults": true, // Since they made it here, they should be serviceable
        "send_to_joey": false,
        "dateOfBirth": `${new Date(formData.dob).getTime()}`,
        "ssnLead": false
    }

    if (formData.topServiceableCompany === "frontier" || formData.topServiceableCompany === "viasat" || formData.topServiceableCompany === "spectrum" || formData.topServiceableCompany === "optimum")  {
        liveVoxBody["send_to_joey"] = true
    }

    return liveVoxBody
}

export const liveVoxCurrentCustomerStatus = (formData, uuid) => {
    return {
        "action": "add_contact",
        "account": uuid,
        "phoneOrdinal": 1,
        "currentCustomer": formData.existingCustomer ? "YES" : "NO",
        "phoneNumber": formData.phoneNumber
    }
}

export const fixedRedirectBody = (formData, wirelessClient="marketplace", hash="N/A", PK=formData.lvUuid, livevoxAccountId=formData.lvUuid, fixedClient=formData.topServiceableCompany, enrollmentId="N/A") => {
    return {
        "PK": PK,
        "first_name": formData.firstName,
        "last_name": formData.lastName,
        "enrollment_id": enrollmentId,
        "wireless_company": wirelessClient,
        "fixed_client": fixedClient,
        "origin": formData.liveVoxOrigin,
        "hash": hash,
        "address_line1": formData.address.parsedAddress.street,
        "address_line2": formData.address.parsedAddress.line2,
        "state": formData.address.parsedAddress.state,
        "zip": formData.address.parsedAddress.zip,
        "phone_number": formData.phoneNumber,
        "email": formData.email,
        "livevox_account_id": livevoxAccountId,
        "lead_path": formData.liveVoxLeadPath ? formData.liveVoxLeadPath : `Facebook.ssn.Leadgen.${formData.liveVoxOrigin}`
    }
}

export const cnx1Body = (formData, config, userId='', queryParams={}) => {

    let body =  {
        "contactDataID": formData?.connexOneLeadId ? formData?.connexOneLeadId : undefined,
        "aceID": formData?.aceUserPK ? formData?.aceUserPK : '',
        "securePiiPk": formData.topServiceableCompany === 'optimum' && formData.isSsnSplit ? uuidv4() : "",
        "chatID": userId,
        "chatURL": formData.chatURL,
        "firstName": formData.firstName,
        "middleName": formData.middleName,
        "lastName": formData.lastName,
        "dateOfBirth": formData.dob,
        "ssnLast4": formData.topServiceableCompany === 'optimum' && formData.isSsnSplit ? formData.ssn.slice(-4) : formData.ssn,
        "phoneNumber": formData.phoneNumber,
        "email": formData.email,
        "addressLn1": formData.address.parsedAddress.street,
        "addressLn2": formData.address.parsedAddress.line2,
        "city": formData.address.parsedAddress.city,
        "state": formData.address.parsedAddress.state,
        "zipCode": formData.address.parsedAddress.zip,
        "shippingLn1": formData.shippingAddress?.parsedAddress?.street,
        "shippingLn2": formData.shippingAddress?.parsedAddress?.line2,
        "shippingCity": formData.shippingAddress?.parsedAddress?.city,
        "shippingState": formData.shippingAddress?.parsedAddress?.state,
        "shippingZipcode": formData.shippingAddress?.parsedAddress?.zip,
        "isAddressValidated": true,
        "isSsnLead": formData.topServiceableCompany === 'optimum' && formData.isSsnSplit,
        "isCheckForTribal": null,
        "isCrosschecked": null,
        "isExistingCustomer": formData.existingCustomer,
        "isSmsConsent": formData.userSMSConsent ? formData.userSMSConsent: false,
        "isCellConsent": formData.userMobileConsent ? formData.userMobileConsent: false,
        "isPrimaryEmailConsent": formData.userMobileConsent ? formData.userMobileConsent: false,
        "isMarketingEmailConsent": formData.marketingEmailConsent ? formData.marketingEmailConsent: false,
        "isPreInstall": formData.preinstallDate ? true : null,
        "installTimeWindow": formData.installTimeFrame,
        "enrollmentID": "",
        "orderNumber": "",
        "selectedProgram": formData?.programs?.[0]?.program_code,
        "nvAppID": formData.acpId,
        "offerSold": "",
        "selectedOffer": formData?.optimumOffer ? formData?.optimumOffer : "",
        "frontierOffer": formData.frontierOffer,
        "installDate": formData.preinstallDate ? moment(formData.preinstallDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
        "targetedProvider": config["targetedProvider"],
        "eligibleProviders": formData.eligibleProviders,
        "serviceabilityCheckResults": formData.topServiceableCompany,
        "serviceProviderSold": "",
        "outsourcedCloser": "",
        "adSetID": "",
        "adID": "",
        "adName": formData?.sponsor_id,
        "adAccountID": "",
        "adAccountName": "",
        "campaignID": "",
        "campaignType": config["campaignType"],
        "advertisingPlatform": config["advertisingPlatform"],
        "leadReference": formData.topServiceableCompany === 'optimum' && formData.isSsnSplit && config?.ssnLeadReference ? config["ssnLeadReference"] : config["leadReference"],
        "sourceReference": config["sourceReference"],
        "trustedFormCertURL": "",
        "isDemoformComplete": formData.isDemoformComplete,
        "klaviyoId": formData.klaviyoId,
        "isAutoInstallDate": formData.isAutoSelectPageShown ? formData.isAutoSelectPageShown : false,
        "facebookClickId": queryParams?.fbclid,
        "isComLink": config?.isComLink ? true : false,
        "dataListID": config?.dataListId,
        "isLabelRequested": formData.sendBroadbandLabel,
        "isDemoformSubmitted": formData.submitDemoForm,
        "ssmCode": queryParams?.formId,
        "eligibleProviderPrimaryCount": formData?.primaryProvidersCount,
        "eligibleProviderSatelliteCount": formData?.satelliteProvidersCount
    }

    if (formData.optimumCurrentCustomer) {
        body.isExistingCustomer = formData.optimumCurrentCustomer
    }

    if (formData.topServiceableCompany === "unserviceable") {
        body.eligibleProviders = "Unknown"
    }

    if (formData.hasExistingAcp !== undefined && formData.hasExistingAcp !== null) {
        body.itmLeadType = formData.hasExistingAcp ? "transfer" : "nonAcpAcquisition"
    }

    return body
}

export const freeconnectCnx1Body = (formData, config, queryParams) => {
    let body =  {
        "aceID": "",
        "securePiiPk": "",
        "chatID": "",
        "chatURL": "",
        "firstName": formData.firstName,
        "middleName": formData.middleName,
        "lastName": formData.lastName,
        "dateOfBirth": formData.dob,
        "ssnLast4": formData.ssn,
        "phoneNumber": formData.phoneNumber,
        "email": formData.email,
        "addressLn1": formData.address?.parsedAddress?.street,
        "addressLn2": formData.address?.parsedAddress?.line2,
        "city": formData.address?.parsedAddress?.city,
        "state": formData.address?.parsedAddress?.state,
        "zipCode": formData.address?.parsedAddress?.zip,
        "shippingLn1": formData.shippingAddress?.parsedAddress?.street,
        "shippingLn2": formData.shippingAddress?.parsedAddress?.line2,
        "shippingCity": formData.shippingAddress?.parsedAddress?.city,
        "shippingState": formData.shippingAddress?.parsedAddress?.state,
        "shippingZipcode": formData.shippingAddress?.parsedAddress?.zip,
        "isAddressValidated": true,
        "isSsnLead": null,
        "isCheckForTribal": null,
        "isCrosschecked": null,
        "isExistingCustomer": formData.existingCustomer,
        "isSmsConsent": formData.userSMSConsent ? formData.userSMSConsent: false,
        "isCellConsent": formData.userMobileConsent ? formData.userMobileConsent: false,
        "isPrimaryEmailConsent": formData.userMobileConsent ? formData.userMobileConsent: false,
        "isPreInstall": formData.preinstallDate ? true : null,
        "installTimeWindow": formData.installTimeFrame,
        "enrollmentID": "",
        "orderNumber": "",
        "selectedProgram": formData.programs[0].program_code,
        "nvAppID": formData.acpId,
        "offerSold": "",
        "frontierOffer": formData.frontierOffer,
        "installDate": formData.preinstallDate ? moment(formData.preinstallDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
        "targetedProvider": config["targetedProvider"],
        "eligibleProviders": formData.serviceableCompany,
        "serviceabilityCheckResults": formData.serviceableCompany,
        "serviceProviderSold": "",
        "outsourcedCloser": "",
        "adSetID": "",
        "adID": "",
        "adName": formData?.sponsor_id,
        "adAccountID": "",
        "adAccountName": "",
        "campaignID": "",
        "campaignType": config["campaignType"],
        "advertisingPlatform": config["advertisingPlatform"],
        "leadReference": config["leadReference"],
        "sourceReference": config["sourceReference"],
        "trustedFormCertURL": "",
        "facebookClickId": queryParams?.fbclid,
        "eligibleProviderPrimaryCount": formData?.primaryProvidersCount,
        "eligibleProviderSatelliteCount": formData?.satelliteProvidersCount
    }

    if (formData.existingCustomer) {
        body.isExistingCustomer = formData.existingCustomer
    }

    return body
}

export const activeProspectXYZiesLeadBody = (formData) => {
    let body = {
        company: formData.topServiceableCompany, // REVIEW - isnt this always spectrum?
        origin: formData.liveVoxOrigin, // REVIEW - make sure this will still work even though we arent using LV anymore.
        leadpath: formData.address.parsedAddress.state == "NY" ? "Facebook.Leadgen.NewYork.Spectrum" : formData.liveVoxLeadPath, // change leadpath for this call so it skips adding to XYZies
        is_existing_customer: formData?.existingCustomer ? formData.existingCustomer : false,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: formData.phoneNumber,
        street_address: formData.address.parsedAddress.street,
        state: formData.address.parsedAddress.state,
        zip_code: formData.address.parsedAddress.zip,
        trustedform_cert_url: formData.certUrl
    }
    return body
}

export const ftrPreselectedBody = (userId, formData) => {
    let body = {
        userId,
        "ftr_chose_another_date": formData.chooseAnotherDate,
        "ftr_preselected_date": formData.autoPreselectedDate,
        "ftr_preinstall_date": formData.preinstallDate,
        "ftr_preinstall_time": formData.preinstallTime
    }

    return body
}

export const setFormCompleteKlayvio = (formData) => {
    let body = {
        "id": formData.klaviyoId,
        "fields": {
            "append": {"completedDemoForm": true}
        }
    }
    return body
}

export const fcusP3Complete = (userId, formData) => {
    let body = {
        userId,
        "selectedOffer": formData?.optimumOffer ? formData?.optimumOffer : "",
        "fc_serviceability_company": formData.topServiceableCompany ? formData.topServiceableCompany : -1,
    }

    return body
}

export const ctamLeadReport = (formData) => {
    let body = {
        "firstName": formData.firstName,
        "lastName": formData.lastName,
        "dateOfBirth": formData.dob,
        "email": formData.email,
        "phoneNumber": formData.phoneNumber,
        "streetAddress": `${formData.address?.parsedAddress?.street}${formData.address?.parsedAddress?.line2 ? ` ${formData.address.parsedAddress.line2}` : ''}`,
        "city": formData.address?.parsedAddress?.city,
        "state": formData.address?.parsedAddress?.state,
        "zipCode": formData.address?.parsedAddress?.zip,
        "serviceableProvider": formData.topServiceableCompany,
        "fromDemoForm": "True"
    }

    return body
}

export const orderSubmissionComplianceDB = (formData, configData, submitPii=false) => {
    const returnObject = {
        "ace_id": formData?.aceUserPK,
        "email": formData?.email,
        "phone": formData?.phoneNumber,
        "formVersion": configData?.formVersion,
        "visitUrl": window.location.href,
        "initialStartTime": formData?.initialStartTime
    }
    const discloArray = []
    if(submitPii){
        const contactConsent = "I agree to be contacted by FreeConnect and/or its related agents via email or phone call regarding my application, account updates & information, and future offers."
        const smsConsent = "I agree to receive SMS notifications from FreeConnect and/or its partners regarding my application, order, and special offers. Message & data rates may apply."
        returnObject["contactConsent"] = contactConsent
        returnObject["smsConsent"] = smsConsent
    } else {
        returnObject["titleText"] = `Before we seal the deal, please take a second to review your info and click the button below to submit your order. If anything looks incorrect, give us a call at ${configData?.DemoformPhoneNumberFormatted}`
        discloArray.push("A Positive ID check and resolved balance are required to qualify for the offer you selected.")
        if(formData.hasExistingAcp === undefined ? true : false){
            discloArray.push("For more information about FreeConnect and all things internet, visit FreeConnect.us")
        }
        if(formData.hasExistingAcp === undefined ? false : true){
            // NOTE - this logic was made in the IcOptimumSuccessWithSub component. I am not sure what the full point of it is but its lave so I am trusting it here!
            if((formData.hasExistingAcp === undefined ? false : true) && (formData.hasExistingAcp === undefined ? false : formData.hasExistingAcp)){
                discloArray.push("To avoid additional service charges, you will need to apply your ACP benefit to your account when you receive your Account Number from Optimum. Learn More")
            }
        }
        discloArray.push("By clicking Submit Order, you agree to have your order processed using the information provided above.")
        returnObject["disclosuresArray"] = discloArray
    }
    
    return returnObject
}