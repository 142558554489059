// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// UI
import BodyText from './BodyText';

// Props of the Component
const propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
    type: PropTypes.string,
    style: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    labelText: PropTypes.string
};
const defaultProps = {
    onClick: () => {},
    type: 'button',
    style: 'filled',
    className: '',
    disabled: false,
    labelText: ''
};

function Button({ children, onClick, type, style, className, disabled, labelText }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Button/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Button/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Button.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Button.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Button/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Button/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    if (style === "label") {
        return (
            <div className={classNames[style]?.div}>
                <BodyText className={classNames[style]?.label}>{labelText}</BodyText>
                <button
                    disabled={disabled}
                    className={`${classNames[style]?.button} ${className}`}
                    type={type}
                    onClick={() => {
                        onClick();
                    }}
                >
                    {children}
                </button>
            </div>
        )
    } else {
        return (
            <button
                disabled={disabled}
                className={`${classNames[style]} ${className}`}
                type={type}
                onClick={() => {
                    onClick();
                }}
            >
                {children}
            </button>
        );
    }
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
