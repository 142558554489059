const FormDataReducer = (state, action) => {
    switch (action.type) {
        case 'SET':
            return action.payload;
        case 'SET_FORMDATA':
            return {
                ...state,
                address: action.payload.address,
                temporaryAddress: action.payload.temporaryAddress,
                shippingAddress: action.payload.shippingAddress,
            };
        case 'SET_ADDRESS':
            return { ...state, address: { ...state.address, ...action.payload } };
        case 'SET_FULL_ADDRESS':
            return { ...state, fullAddress: action.payload};
        case 'SET_PREVIOUS_ADDRESS':
            return { ...state, previousAddress: { ...state.previousAddress, ...action.payload } };
        case 'CLEAR_PREVIOUS_ADDRESS':
            return { ...state, previousAddress: { ...state.previousAddress, ...action.payload } };
        case 'CLEAR_ADDRESS':
            return { ...state, address: {} };
        case 'SET_ADDRESS_LINE_2':
            return { ...state, address: { ...state.address, addressLine2: action.payload } };
        case 'SET_TEMPORARY_ADDRESS':
            return { ...state, temporaryAddress: action.payload };
        case 'CLEAR_TEMPORARY_ADDRESS':
            return { ...state, temporaryAddress: "Permanent" };
        case 'SET_SHIPPING_ADDRESS':
            return { ...state, shippingAddress: { ...state.shippingAddress, ...action.payload } };
        case 'SET_SHIPPING_ADDRESS_LINE_2':
            return { ...state, shippingAddress: { ...state.shippingAddress, addressLine2: action.payload } };
        case 'SET_SHIPPING_ADDRESS_NULL':
            return { ...state, shippingAddress: null };
        case 'ADD_PROGRAM':
            return { ...state, programs: [action.payload] };
        case 'ADD_PROGRAM_CODE':
            return { ...state, program_code: action.payload };
        case 'CLEAR_PROGRAM':
            return { ...state, programs: [] };
        case 'REMOVE_PROGRAM':
            return {
                ...state,
                programs: state.programs.filter((program) => program.program_code !== action.payload.program_code),
            };
        case 'SET_FORM_END_TIME':
            return { ...state, formEndTime: action.payload };
        case 'SET_FIRST_NAME':
            return { ...state, firstName: action.payload };
        case 'SET_LAST_NAME':
            return { ...state, lastName: action.payload };
        case 'SET_MIDDLE_NAME':
            return { ...state, middleName: action.payload };
        case 'SET_SECOND_LAST_NAME':
            return { ...state, secondLastName: action.payload };
        case 'SET_SUFFIX':
            return { ...state, suffix: action.payload };
        case 'SET_PHONE_NAME':
            return { ...state, phoneNumber: action.payload };
        case 'SET_DOB': {
            const dobArray = action.payload.split("/")
            return { ...state, dob: action.payload, formattedDob: dobArray[2] + "-" + dobArray[0] + "-" + dobArray[1] };
        }
        case 'SET_SSN':
            return { ...state, ssn: action.payload };
        case 'SET_BQP_FIRST_NAME':
            return { ...state, bqpFirstName: action.payload };
        case 'SET_BQP_LAST_NAME':
            return { ...state, bqpLastName: action.payload };
        case 'SET_BQP_DOB': {
            const bqpDobArray = action.payload.split("/")
            return { ...state, bqpDob: action.payload, bqpFormattedDob: bqpDobArray[2] + "-" + bqpDobArray[0] + "-" + bqpDobArray[1] };
        }
        case 'SET_BQP_SSN':
            return { ...state, bqpSsn: action.payload };
        case 'SET_ACCEPTED_DISCLOSURES':
            return { ...state, acceptedDisclosures: action.payload };
        case 'SET_CLICKED_ACTIVATE_DEMO':
            return { ...state, clickedActivateDemo: action.payload };
        case 'SET_USER_MOBILE_CONSENT':
            return { ...state, userMobileConsent: action.payload };
        case 'SET_USER_SMS_CONSENT':
            return { ...state, userSMSConsent: action.payload };
        case 'SET_USER_MARKETING_EMAIL_CONSENT':
            return { ...state, marketingEmailConsent: action.payload };
        case 'SET_SERVICEABLE_COMPANY':
            return { ...state, serviceableCompany: action.payload };
        case 'SET_PREDICTIVE_SEARCH_ADDRESS_OBJECT':
            return { ...state, predictiveSearchAddressObject: action.payload };
        case 'SET_ACCOUNT_INFO_SMS':
            return { ...state, accountInfoSMS: action.payload };
        case 'SET_ACCOUNT_INFO_EMAIL':
            return { ...state, accountInfoEmail: action.payload };
        case 'SET_SERVICE_UPDATE_SMS':
            return { ...state, serviceUpdateSMS: action.payload };
        case 'SET_SERVICE_UPDATE_EMAIL':
            return { ...state, serviceUpdateEmail: action.payload };
        case 'SET_MARKET_PROMOTION_SMS':
            return { ...state, marketPromotionSMS: action.payload };
        case 'SET_MARKET_PROMOTION_EMAIL':
            return { ...state, marketPromotionEmail: action.payload };
        case 'SET_FRONTIER_COMMUNICATION':
            return { ...state, frontierCommunication: action.payload };
        case 'SET_FRONTIER_DISCLOSURE_FIELDS':
            return { ...state, frontierDisclosureFields: [...state.frontierDisclosureFields, action.payload] };
        case 'SET_QUOTE_ID':
            return { ...state, quoteId: action.payload };
        case 'SET_PREVIOUS_ADDRESS':
            return { ...state, previousAddress: { ...state.previousAddress, ...action.payload } };
        case 'CLEAR_PREVIOUS_ADDRESS':
            return { ...state, previousAddress: {} };
        case 'SET_APPLICATION_ID':
            return { ...state, applicationId: action.payload };
        case 'SET_APPLICATION_STATUS':
            return { ...state, applicationStatus: action.payload };
        case 'SET_ACCOUNT_UUID':
            return { ...state, accountUuid: action.payload };
        case 'SET_OFFER_ID':
            return { ...state, offerId: action.payload };
        case 'SET_PRODUCT_ID':
            return { ...state, productId: action.payload };
        case 'SET_PRODUCT_ITEM':
            return { ...state, productItem: [action.payload] };
        case 'SET_SCHEDULE_ID':
            return { ...state, scheduleId: action.payload };
        case 'SET_INSTALL_DATE':
            return { ...state, installDate: action.payload };
        case 'SET_FRONTIER_SECURITY_QUESTIONS':
            return { ...state, securityChallengeQuestions: [...state.securityChallengeQuestions, action.payload] };
        case 'SET_FRONTIER_SECURITY_ANSWERS':
            return { ...state, securityChallengeAnswers: {...state.securityChallengeAnswers, ...action.payload} };
        case 'INCREMENT_FREEFORM_ADDRESS_TIMES_AT_PAGE_1':
            return { ...state, freeformAddressTimesAtPage1: state.freeformAddressTimesAtPage1 + 1 };
        case 'INCREMENT_FREEFORM_ADDRESS_TIMES_AT_PAGE_2':
            return { ...state, freeformAddressTimesAtPage2: state.freeformAddressTimesAtPage2 + 1 };
        case 'INCREMENT_FREEFORM_ADDRESS_TIMES_AT_PAGE_3':
            return { ...state, freeformAddressTimesAtPage3: state.freeformAddressTimesAtPage3 + 1 };
        case 'SET_SERVICEABLE_COMPANIES':
            return { ...state, serviceableCompanies: action.payload };
        case 'SET_PREDICTIVE_SEARCH_ADDRESS_OBJECT':
            return { ...state, predictiveSearchAddressObject: action.payload };
        case 'SET_LIVE_VOX_ORIGIN':
            return { ...state, liveVoxOrigin: action.payload};
        case 'SET_EMAIL':
            return { ...state, email: action.payload };
        case 'SET_EXISTING_CUSTOMER':
            return { ...state, existingCustomer: action.payload };
        case 'SET_LIVE_VOX_LEAD_PATH':
            return { ...state, liveVoxLeadPath: action.payload };
        case 'SET_ORDER_ID':
            return { ...state, orderId: action.payload }
        case 'SET_FULL_NAME':
            return { ...state, fullName: action.payload }
        case 'SET_OPTIMUM_SERVICEABLE':
            return {...state, isServiceable: action.payload};
        case 'SET_FROM_COMPANY':
            return {...state, fromCompany: action.payload};
        case 'SET_TOP_SERVICEABLE_COMPANY':
            return {...state, topServiceableCompany: action.payload};
        case 'SET_FC_US_SERVICEABILITY_RESULTS':
            return {...state, fcusServiceabilityResults: action.payload};
        case 'SET_WIRELESS_OR_INTERNET':
            return {...state, wirelessOrInternet: action.payload};
        case 'SET_PREINSTALL_DATE':
            return { ...state, preinstallDate: action.payload };
        case 'SET_PREINSTALL_TIME':
            return { ...state, preinstallTime: action.payload };
        case 'SET_PREINSTALL_TIMEFRAME':
            return { ...state, installTimeFrame: action.payload };
        // case 'SET_ACP_ID':
        //     return { ...state, acpId: action.payload };
        case 'SET_FRONTIER_OFFER':
            return { ...state, frontierOffer: action.payload };
        case 'SET_IS_CURRENT_CUSTOMER':
            return { ...state, currentCustomer: action.payload };
        case 'SET_LV_UUID':
            return { ...state, lvUuid: action.payload };
        case 'CLICKED_OPT_CURRENT_CUSTOMER':
            // Used to track if an optimum eligible user has clicked the Yes button to current customer question
            return { ...state, optimumCurrentCustomer: action.payload };
        case 'SET_PII_PREVIOUS_CLICKED':
            return { ...state, piiPreviousClicked: action.payload };
        case 'GOT_CHOICE_PAGE':
            return { ...state, gotChoicePage: action.payload };
        case 'SET_ENROLLMENT_ID':
            return { ...state, enrollmentId: action.payload };
        case 'SET_CERT_URL':
            return { ...state, certUrl: action.payload };
        case 'SET_ELIGIBLE_PROVIDERS':
            return { ...state, eligibleProviders: action.payload };
        case 'SET_ACP_ID':
            return { ...state, acpId: action.payload };
        case 'SET_FORM_COMPLETE':
            return { ...state, isDemoformComplete: action.payload };
        case 'SET_ELIGIBLE_PROVIDERS_ARRAY':
            return { ...state, eligibleProvidersArray: action.payload }
        case 'SET_PRIMARY_PROVIDERS_COUNT':
            return { ...state, primaryProvidersCount: action.payload }
        case 'SET_SATELLITE_PROVIDERS_COUNT':
            return { ...state, satelliteProvidersCount: action.payload }
        case 'SET_ONLY_SERVICEABLE_BY_WIRELESS_OR_FIXED':
            return { ...state, serviceableByWirelessOrFixed: action.payload }
        case 'SET_ELIGIBLE_CLIENT_TYPES':
            return { ...state, eligibleClientTypes: action.payload }
        case 'SET_ACE_USER_DB_KEY':
            return { ...state, aceUserPK: action.payload }
        case 'SUBMITTED_COMBO_PII':
            return { ...state, submittedComboPii: action.payload }
        case 'FRONTIER_CALL_NOW_CLICKED':
            return { ...state, frontierCallNowClicked: action.payload }
        case 'SET_IS_SSN_SPLIT':
            return { ...state, isSsnSplit: action.payload }
        case 'SET_CERT_RETAINED':
            return { ...state, trusedFormCertRetained: action.payload }
        case 'SET_CHOOSE_ANOTHER_DATE':
            return { ...state, chooseAnotherDate: action.payload }
        case 'SET_PRESELECTED_DATE':
            return { ...state, autoPreselectedDate: action.payload }
        case 'SET_PRESELECT_PAGE_TIMESTAMP':
            return { ...state, preselectPageTimestamp: action.payload }
        case 'SET_HAS_EXISTING_ACP':
            return { ...state, hasExistingAcp: action.payload }
        case 'SET_IS_BENEFIT_SPLIT':
            return { ...state, isBenefitSplit: action.payload }
        case 'SET_USER_GOT_WELCOME_PAGE_SPLIT':
            return { ...state, isWelcomePageSplit: action.payload }
        case 'SET_KLAVIYO_ID':
            return { ...state, klaviyoId: action.payload }
        case 'SET_IS_AUTO_SELECT_PAGE_SHOWN':
            return { ...state, isAutoSelectPageShown: action.payload }
        case 'SET_CLICKED_CALL_NOW_BUTTON_ITM_END_APP_RECEIVED':
            return { ...state, clickedCallNowButtonItmEndAppReceived: action.payload }
        case 'SET_CLICKED_CALL_NOW_HREF_ITM_END_APP_RECEIVED':
            return { ...state, clickedCallNowHrefItmEndAppReceived: action.payload }
        case 'SET_CLICKED_CALL_NOW_BUTTON_ITM_END_EXISTING':
            return { ...state, clickedCallNowButtonItmEndExisting: action.payload }
        case 'SET_CLICKED_CALL_NOW_HREF_ITM_END_EXISTING':
            return { ...state, clickedCallNowHrefItmEndExisting: action.payload }
        case 'SET_CLICKED_CALL_NOW_BUTTON_ITM_END_SUCCESS_OPT_TRANSFER':
            return { ...state, clickedCallNowButtonItmEndSuccessOptTransfer: action.payload }
        case 'SET_CLICKED_CALL_NOW_HREF_ITM_END_SUCCESS_OPT_TRANSFER':
            return { ...state, clickedCallNowHrefItmEndSuccessOptTransfer: action.payload }
        case 'SET_CLICKED_CALL_NOW_BUTTON_ITM_END_SUCCESS_OPT_NOT_TRANSFER':
            return { ...state, clickedCallNowButtonItmEndSuccessOpt: action.payload }
        case 'SET_CLICKED_CALL_NOW_HREF_ITM_END_SUCCESS_OPT_NOT_TRANSFER':
            return { ...state, clickedCallNowHrefItmEndSuccessOpt: action.payload }
        case 'SET_CLICKED_LEARN_ITM_END_SUCCESS_OPT_NOT_TRANSFER':
            return { ...state, clickedCallNowHrefItmEndSuccessOpt: action.payload }
        case 'SET_CLICKED_LEARN_MORE_ITM_END_SUCCESS_OPT_TRANSFER':
            return { ...state, clickedLearnMoreItmEndSuccessOpt: action.payload }
        case 'SET_PAGE_REACHED_ITM_END_NO_OFFERS':
            return { ...state, pageReachedItmEndNoOffers: action.payload }
        case 'SET_PAGE_REACHED_ITM_END_APP_RECEIVED':
            return { ...state, pageReachedItmEndAppReceived: action.payload }
        case 'SET_PAGE_REACHED_ITM_END_EXISTING':
            return { ...state, pageReachedItmEndExisting: action.payload }
        case 'SET_PAGE_REACHED_ITM_END_SUCCESS_OPT_TRANSFER':
            return { ...state, pageReachedItmEndSuccessOptTransfer: action.payload }
        case 'SET_PAGE_REACHED_ITM_END_SUCCESS_OPT_NOT_TRANSFER':
            return { ...state, pageReachedItmEndSuccessOptNoTransfer: action.payload }
        case 'SET_OPTIMUM_OFFER':
            return { ...state, optimumOffer: action.payload }
        case 'SET_CLICKED_OFFERS_DISCLAIMERS':
            return { ...state, clickedOffersDisclaimers: action.payload }
        case 'SET_CLICKED_OFFERS_CALL_NOW':
            return { ...state, clickedOffersCallNow: action.payload }
        case 'SET_CONNEX_ONE_LEAD_ID':
            return { ...state, connexOneLeadId: action.payload }
        case 'SET_SUBMIT_DEMOFORM':
            return { ...state, submitDemoForm: action.payload }
        case 'SET_SEND_BROADBAND_LABEL':
            return { ...state, sendBroadbandLabel: action.payload }
        case 'SET_CLICKED_BB_CHECKBOX':
            return { ...state, clickedBbCheckbox: action.payload }
        case 'SET_CLICKED_CALL_NOW_ITM_P3_SUBMIT_OPT':
            return { ...state, clickedCallNowItmP3SubmitOpt: action.payload }
        default:
            return state;
    }
};

export default FormDataReducer;
