// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GrDown, GrUp } from 'react-icons/gr';
// Steps
import Disclosures from './Disclousres';
// Components
import ConditionalRender from '../../../components/ConditionalRender'
// UI
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
import OfferText from '../../../ui/OfferText';
import OfferCard from '../../../ui/OfferCard';
import Button from '../../../ui/Button';
import LoadingBlocker from '../../../ui/LoadingBlocker';
import ShowMore from '../../../ui/ShowMore';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';
import { PopupContext } from '../../../contexts/Popup';
import { frontierExtraSteps } from '../contexts/form/steps'


// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function FrontierOffers({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [offers, setOffers] = useState([]);
    const [startedTimeout, setStartedTimeout] = useState(false);
    const [clickedAgree, setClickedAgree] = useState(0);
    const [displayMoreOffers, setDisplayMoreOffers] = useState(false);
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [steps, stepsDispatch] = useContext(StepsContext);
    const environment = useContext(EnvironmentContext)[0];
    const [popupState, popupDispatch] = useContext(PopupContext);
    const isLoadingDispatch = useContext(IsLoadingContext)[1];
    const [clickedOffer, setClickedOffer] = useState(0);
    const [activeOffer, setActiveOffer] = useState(1)

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierOffers/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierOffers/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    useEffect(() => {
        const asyncFunction = async () => {
            if (clickedOffer > 0) {
                // Quote Item Post
                const quoteItemPostHeaders = { 
                    "Authorization" : "frontier",
                    "X-Api-Key": environment.frontierQuoteItemPostUrl.key,
                }
                const quoteItemPostBody = {
                    quoteId: formData.quoteId,
                    productId: formData.productId
                }
                const quoteItemPostResponse = await fetch(environment.frontierQuoteItemPostUrl.url, {
                    method: 'POST',
                    headers: quoteItemPostHeaders,
                    body: JSON.stringify(quoteItemPostBody),
                })
                const quoteItemPostObject = await quoteItemPostResponse.json();
                formDataDispatch({
                    type: 'SET_PRODUCT_ID',
                    payload: quoteItemPostObject.body?.productSummary?.productLineItemSummary?.[0]?.lineItemId
                });
                formDataDispatch({
                    type: 'SET_PRODUCT_ITEM',
                    payload: quoteItemPostObject.body?.items?.[0]
                });
                const mcHeaders =  {
                    Authorization: company,
                    "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                }
                const mcBody = { 
                    userId,
                    'product_id': quoteItemPostObject.body?.productSummary?.productLineItemSummary?.[0]?.lineItemId
                }
                const setFieldResponse = await fetch(environment.backendUrlToSetData, {
                    method: 'POST',
                    headers: mcHeaders,
                    body: JSON.stringify(mcBody),
                })
                const setFieldResponseObj = await setFieldResponse.json()
                // Quote Item Put
                const quoteItemPutHeaders = { 
                    "Authorization" : "frontier",
                    "X-Api-Key": environment.frontierQuoteItemPutUrl.key
                }
                const quoteItemPutBody = {
                    quoteId: formData.quoteId,
                    productId: formData.productId,
                    product_item: quoteItemPostObject.body?.items?.[0]
                }
                const quoteItemPutResponse = await fetch(environment.frontierQuoteItemPutUrl.url, {
                    method: 'PUT',
                    headers: quoteItemPutHeaders,
                    body: JSON.stringify(quoteItemPutBody),
                })
                const quoteItemPutObject = await quoteItemPutResponse.json();
                // Close Select Offers
                const closeSelectOffersHeaders = { 
                    "Authorization" : "frontier",
                    "X-Api-Key": environment.frontierCloseSelectOffersUrl.key
                }
                const closeSelectOffersBody = {
                    quoteId: formData.quoteId
                }
                const closeSelectOffersResponse = await fetch(environment.frontierCloseSelectOffersUrl.url, {
                    method: 'POST',
                    headers: closeSelectOffersHeaders,
                    body: JSON.stringify(closeSelectOffersBody),
                })
                const closeSelectOffersObject = await closeSelectOffersResponse.json();
                // Customer Credit Check
                const customerCreditCheckHeaders = { 
                    "Authorization" : "frontier",
                    "X-Api-Key": environment.frontierCustomerCreditCheckUrl.key
                }
                const customerCreditCheckBody = {
                    quoteId: formData.quoteId,
                    accountUuid: formData.accountUuid
                }
                const customerCreditCheckResponse = await fetch(environment.frontierCustomerCreditCheckUrl.url, {
                    method: 'POST',
                    headers: customerCreditCheckHeaders,
                    body: JSON.stringify(customerCreditCheckBody),
                })
                const customerCreditCheckObject = await customerCreditCheckResponse.json();
                // const customerCreditCheckObjectBool = true
                // if (customerCreditCheckObjectBool) {
                if (customerCreditCheckObject.body?.redFlags) {
                    stepsDispatch({ type:'INSERT_STEP', payload: {index: steps.currentStep + 1, step: frontierExtraSteps[2]}})

                }
                // if (customerCreditCheckObjectBool) { 
                if (customerCreditCheckObject.body?.fraudPrevention?.securityChallengeQuestions) {  
                    const securityQuestions = customerCreditCheckObject.body?.fraudPrevention?.securityChallengeQuestions
                    for (let i = 0; i < securityQuestions.length; i++) {
                        formDataDispatch({ 
                            type: 'SET_FRONTIER_SECURITY_QUESTIONS', 
                            payload: securityQuestions[i]
                        });
                    }
                    // for (let i = 0; i < customerCreditCheckObject.body.fraudPrevention.securityChallengeQuestions.length; i++) {
                    //     formDataDispatch({ 
                    //         type: 'SET_FRONTIER_SECURITY_QUESTIONS', 
                    //         payload: customerCreditCheckObject.body.fraudPrevention.securityChallengeQuestions[i]
                    //     });
                    // }
                    stepsDispatch({ type:'INSERT_STEP', payload: {index: steps.currentStep + 1, step: frontierExtraSteps[0]}})
                } 
                isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "FrontierSelectedOffer" })
                onNext()
            }
        }
        asyncFunction();
    }, [clickedOffer])

    useEffect(() => {
        const asyncFunction = async () => {
            isLoadingDispatch({ type: "ADD_INPUT_BLOCKER_WITH_TEXT", payload: { key: "FrontierGetOffers", textArray: [ 
                "Getting best offers...", 
                "Responding to trolls...", 
                "Wheeling and dealing...",
                "Balling on a budget...",
                "Touching grass...",
                "Hunting for treasure...",
                "Pleasing your pockets...",
                "Yoga break...",
                "Snatching savings...",
                "Burning your bill...",
                "Cutting the cost...",
                "Fetching the free..."
            ] }})
            // create Quote
            const createQuoteHeaders = { 
                "Content-Type": "application/json",
                "X-Api-Key": environment.frontierCreateQuoteUrl.key,
                Authorization: "frontier"
            }
            const createQuoteBody = {
                userId,
                agent: "mazama",
                customerType: "Residential",
                ftr_header: "COMMOUTREA",
                User: userId,
                assisted: false,
                addressID: {
                    environmentCode: formData.predictiveSearchAddressObject?.samRecords[0].environment ? formData.predictiveSearchAddressObject?.samRecords[0].environment : formData.environmentCode,
                    samControlNumber: formData.predictiveSearchAddressObject?.samRecords[0].controlNumber ? formData.predictiveSearchAddressObject?.samRecords[0].controlNumber : formData.controlNumber
                }
            };
            const createQuoteResponse = await fetch(environment.frontierCreateQuoteUrl.url, {
                method: 'POST',
                headers: createQuoteHeaders,
                body: JSON.stringify(createQuoteBody),
            })
            const createQuoteObject = await createQuoteResponse.json()
            if (!(createQuoteObject.body.hasOwnProperty("quoteId"))){
                stepsDispatch({
                    type: 'FIRE_ERROR',
                    payload: {
                        title: "We've encountered an error!",
                        description: "Please click the button below to restart your application for internet service with Frontier!",
                        buttonText: "Try Again"
                    }
                });
            }
            
            formDataDispatch({
                type: 'SET_QUOTE_ID',
                payload: createQuoteObject?.body?.quoteId,
            });
            formDataDispatch({
                type: 'SET_ACCOUNT_UUID',
                payload: createQuoteObject?.body?.customer?.accountUuid,
            });
            const mcHeaders =  {
                Authorization: company,
                "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
            }
            const mcBody = { 
                userId,
                'quoteId': createQuoteObject?.body?.quoteId,
                'accountUuid': createQuoteObject?.body?.customer?.accountUuid
            }
            const setFieldResponse = await fetch(environment.backendUrlToSetData, {
                method: 'POST',
                headers: mcHeaders,
                body: JSON.stringify(mcBody),
            })
            const setFieldResponseObj = await setFieldResponse.json()
            let lowIncomeVerificationBody = {}
            let lowIncomeVerificationHeaders = {}
            let lowIncomeUrl = ''

            if (formData.applicationId) {
                lowIncomeVerificationHeaders = {
                    "Authorization" : "frontier",
                    "X-Api-Key": environment.frontierLowIncomeByAppUrl.key,
                }
                lowIncomeVerificationBody = {
                    userId,
                    quoteId: createQuoteObject?.body?.quoteId,
                    accountUuid: createQuoteObject?.body?.customer?.accountUuid,
                    applicationId: formData.applicationId,
                    subscriberFirstName: formData.firstName,
                    subscriberLastName: formData.lastName,
                    subscriberDateOfBirth: formData.dob,
                    subscriberPhoneNumber: formData.phoneNumber,
                    enrollInTribalBenefit: false,
                    stateProvince: formData.address.parsedAddress.state
                }
                lowIncomeUrl = environment.frontierLowIncomeByAppUrl.url
            } else {
                lowIncomeVerificationHeaders = {
                    "Authorization" : "frontier",
                    "X-Api-Key": environment.frontierLowIncomeVerificationUrl.key,
                }
                lowIncomeVerificationBody = {
                    userId,
                    quoteId: createQuoteObject?.body?.quoteId,
                    accountUuid: createQuoteObject?.body?.customer?.accountUuid,
                    subscriberFirstName: formData.firstName,
                    subscriberLastName: formData.lastName,
                    subscriberLast4Ssn: formData.ssn, 
                    subscriberDateOfBirth: formData.dob,
                    subscriberPhoneNumber: formData.phoneNumber,
                    enrollInTribalBenefit: false,
                    addressLine1: formData.address.parsedAddress.street,
                    addressLine2: formData.address.parsedAddress.line2 ? formData.address.parsedAddress.line2 : '',
                    city: formData.address.parsedAddress.city,
                    stateProvince: formData.address.parsedAddress.state,
                    zipCode: formData.address.parsedAddress.zip
                }
                lowIncomeUrl = environment.frontierLowIncomeVerificationUrl.url
            }
            const lowIncomeVerificationResponse = await fetch(lowIncomeUrl, {
                method: 'POST',
                headers: lowIncomeVerificationHeaders,
                body: JSON.stringify(lowIncomeVerificationBody),
            })
            const lowIncomeVerificationObject = await lowIncomeVerificationResponse.json();
            const finishQuoteCreateHeaders = {
                "Authorization" : "frontier",
                "X-Api-Key": environment.frontierFinishQuoteCreateUrl.key,
            }
            const finishQuoteCreateBody = {
                userId,
                quoteId: createQuoteObject?.body?.quoteId,
                accountUuid: createQuoteObject?.body?.customer?.accountUuid,
                fullName: `${formData.firstName} ${formData.lastName}`,
                firstName: formData.firstName,
                lastName: formData.lastName,
                addressLine1: formData.address.parsedAddress.street,
                addressLine2: formData.address.parsedAddress.line2 ? formData.address.parsedAddress.line2 : "",
                city: formData.address.parsedAddress.city,
                stateCode: formData.address.parsedAddress.state,
                postalCode: formData.address.parsedAddress.zip,
                phoneNumber: formData.phoneNumber,
                email: formData.email,
                dob: formData.formattedDob,
                act_email: true,
                act_mobile: true,
                serv_email: true,
                serv_mobile: true,
                promo_email: true,
                promo_mobile: true
            }
            const finishQuoteCreateResponse = await fetch(environment.frontierFinishQuoteCreateUrl.url, {
                method: 'POST',
                headers: finishQuoteCreateHeaders,
                body: JSON.stringify(finishQuoteCreateBody),
            })
            const finishQuoteCreateObject = await finishQuoteCreateResponse.json()
            if (!(finishQuoteCreateObject.body)){
                stepsDispatch({
                    type: 'FIRE_ERROR',
                    payload: {
                        title: "Uh Oh... Something went wrong",
                        description: "Please click the button below to restart your application for internet service with Frontier!",
                        buttonText: "Try Again"
                    }
                });
            }
            const getOffersHeaders = { 
                "Authorization" : "frontier",
                "X-Api-Key": environment.frontierGetOffersDisplay.key,
            }
            const getOfferResponse = await fetch(`${environment.frontierGetOffersDisplay.url}?quoteId=${createQuoteObject?.body?.quoteId}`, {
                method: 'GET',
                headers: getOffersHeaders,
            })
            const getOfferObject = await getOfferResponse.json();
            if (!(getOfferObject.body)){
                stepsDispatch({
                    type: 'FIRE_ERROR',
                    payload: {
                        title: "Uh Oh... Something went wrong",
                        description: "Please click the button below to restart your application for internet service with Frontier!",
                        buttonText: "Try Again"
                    }
                });
            }
            var offersArray = []
            for (let i=0; i < getOfferObject.body.length; i++) {
                if (getOfferObject.body?.[i].serviceType === "Broadband") {
                    if (getOfferObject.body?.[i].name === "Fiber 1 Gig Internet") {
                        offersArray.unshift(getOfferObject.body[i])
                        continue
                    } else if (offersArray[0]?.name === "Fiber 1 Gig Internet" && getOfferObject.body?.[i].name === "Fiber 100 Internet") {
                        offersArray.splice(1, 0, getOfferObject.body[i])
                        continue
                    } else if (offersArray[0]?.name === "Fiber 1 Gig Internet" && getOfferObject.body?.[i].name === "Fiber 50 Internet") {
                        continue
                    } else if (getOfferObject.body?.[i].name === "Fiber 100 Internet") {
                        offersArray.unshift(getOfferObject.body[i])
                    }
                    offersArray.push(getOfferObject.body[i])
                }
            }
            setOffers(offersArray);
            isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER_WITH_TEXT", payload: "FrontierGetOffers" })
        }
        asyncFunction();
    },[]);

    useEffect(() => {
        if (clickedAgree > 0) {
            popupDispatch({ type: 'REMOVE_POPUP' })
            onNext()
        }
    }, [clickedAgree])

    return (
        <>
            <HeadingText>Pick Your Plan</HeadingText>
            <SubHeadingText>These are the top offers in your area. Select the one that best suits your needs.</SubHeadingText>
                <ConditionalRender condition={offers.length > 0} falseReturn={<SubHeadingText>There are no offers available for your location</SubHeadingText>}>
                    <div className={classNames?.extraOffers}>
                        {
                            offers.map((offer, i) => {
                                const realPrice = (parseFloat(offer?.pricePlan?.priceTerm?.[0]?.amount) - 30).toFixed(2)
                                return (
                                    <OfferCard key={i}
                                        active={activeOffer === i}
                                        label={offer?.name === "Fiber 1 Gig Internet" ? "Best Value" : (offer?.name === "Fiber 100 Internet" ? "Free Offer" : null )}
                                        labelColor={offer?.name === "Fiber 1 Gig Internet" ? "purple" : (offer?.name === "Fiber 100 Internet" ? "green" : null )}
                                        title={offer?.name}
                                        realValue={`$${realPrice < 0 ? parseFloat(0).toFixed(2) : realPrice}`}
                                        fakeValue={`$${offer?.pricePlan?.priceTerm?.[0]?.amount}`}
                                        description={offer?.displayOffers}
                                        onCardClick={ () => { setActiveOffer(i) } }
                                        onClick={ () => { 
                                            // console.log("Selected Offer:", offer?.name)
                                            // TODO: set in manychat
                                            formDataDispatch({
                                                type: 'SET_PRODUCT_ID',
                                                payload: offer?.id,
                                            });
                                            setClickedOffer(prev => prev +1)
                                            isLoadingDispatch({ type: "ADD_INPUT_BLOCKER", payload: "FrontierSelectedOffer" })
                                        }}
                                    />
                                )
                            })        
                        }
                    </div>
            </ConditionalRender>
        </>
    )
}

FrontierOffers.propTypes = propTypes;
FrontierOffers.defaultProps = defaultProps;

export default FrontierOffers;