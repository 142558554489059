// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender'
// UI
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
import Card from '../../../ui/Card';
import BodyText from '../../../ui/BodyText';
import Link from '../../../ui/Link';
import LabelText from '../../../ui/LabelText';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function FrontierSuccess({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [startedTimeout, setStartedTimeout] = useState(false);
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [isLoading, isLoadingDispatch] = useContext(IsLoadingContext);
    const [steps, stepsDispatch] = useContext(StepsContext);
    const environment = useContext(EnvironmentContext)[0];
    const [hasRunSubmit, setHasRunSubmit] = useState(false);

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Loading/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Loading/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    return (
        <>
            <HeadingText>Application Complete!</HeadingText>
            <SubHeadingText>It's a date! Details for your free Internet installation can be found below. We'll also send this info to you through email and Messenger.</SubHeadingText>
            <Card>
                <LabelText>Installation Date & Time:</LabelText>
                <BodyText>{formData.installDate}</BodyText>
                <br />
                <LabelText>Order Number:</LabelText>
                <BodyText>{formData.orderId}</BodyText>
            </Card>
            <SubHeadingText>We recommend keeping these numbers for customer assistance purposes. If you have any questions or need to change your install date, contact us at <Link href={`tel:2068146798`}>206-814-6798</Link>.</SubHeadingText>
        </>
    )
}

FrontierSuccess.propTypes = propTypes;
FrontierSuccess.defaultProps = defaultProps;

export default FrontierSuccess;