// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// Component
import ConditionalRender from '../components/ConditionalRender';

// Props of the Component
const propTypes = {
    className: PropTypes.string,
    companyProp: PropTypes.string
};
const defaultProps = {
    className: '',
    companyProp: null
};

function FreeconnectCallNowContent({ className, companyProp }) {
    const { company } = useParams();
    // const [classNames, setClassNames] = useState({});
    const [content, setContent] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/FreeconnectCallNowContent/content.js`)
            .then((module) => {
                setContent(module.default);
            })
            .catch(() => {
                setContent({})
            });
    }, [company]);

    return (
        <ConditionalRender condition={content[companyProp]?.image && content[companyProp]?.altText}>
            <img 
                src={content[companyProp]?.image ? content[companyProp].image : ""} 
                style={{ width: "100%", height: "auto", borderRadius: "12px" }}
                className={className}
                alt={content[companyProp]?.altText ? content[companyProp].altText : ""}
            />
        </ConditionalRender>
    )
}

FreeconnectCallNowContent.propTypes = propTypes;
FreeconnectCallNowContent.defaultProps = defaultProps;

export default FreeconnectCallNowContent;
