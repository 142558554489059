// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// UI
import HeadingText from './HeadingText';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func
};
const defaultProps = {
    className: '',
    title: '',
    onClick: () => {}
};

function Popup({ children, className, title, onClick }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Popup/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Popup/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Popup.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Popup.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Popup/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Popup/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <>
            <div className={classNames.blocker} onClick={onClick}></div>
            <div className={`${classNames.popup} ${className}`}>
                <HeadingText className={classNames.header}>{title}</HeadingText>
                <div className={classNames.container}>
                    {children}
                </div>
            </div>
        </>
    );
}

Popup.propTypes = propTypes;
Popup.defaultProps = defaultProps;

export default Popup;
