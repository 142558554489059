// Packages
import React, { useContext,useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import LoadingBlocker from './LoadingBlocker';
import SuperHeadingText from './SuperHeadingText';
// Contexts
import { IsLoadingContext } from '../contexts/IsLoading';

function DisplayIsLoadingInputBlocker() {
    const { company } = useParams();
    const isLoading = useContext(IsLoadingContext)[0];
    const [classNames, setClassNames] = useState({});
    const [textArray, setTextArray] = useState([]);

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/DisplayIsLoadingInputBlocker/index.less`).catch(
            () => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/DisplayIsLoadingInputBlocker/index.less`);
            }
        );
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/DisplayIsLoadingInputBlocker.less`)
        .catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/DisplayIsLoadingInputBlocker.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/DisplayIsLoadingInputBlocker/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(
                    /* webpackMode: "eager" */ `../companies/default/styles/ui/DisplayIsLoadingInputBlocker/classNames.js`
                ).then((module) => {
                    setClassNames(module.default);
                });
            });
    }, [company]);

    useEffect(() => {
        if (isLoading.textArray?.length > 0) {
            document.documentElement.style.setProperty('--input-blocker-amount-of-loading-texts', `${isLoading.textArray.length - 1}`);
            document.documentElement.style.setProperty('--input-blocker-speed', `${isLoading.textArray.length * 0.26}s`);
            setTextArray(isLoading.textArray)
        } else {
            document.documentElement.style.setProperty('--input-blocker-amount-of-loading-texts', '0');
            document.documentElement.style.setProperty('--input-blocker-speed', '0');
            setTextArray([])
        }
    }, [isLoading.textArray])

    return (
        <ConditionalRender condition={Object.keys(isLoading.blockInput).some((element) => isLoading.blockInput[element] === true)}>
            <ConditionalRender 
                condition={textArray.length > 0}
                falseReturn={<LoadingBlocker/>}
            >
                <LoadingBlocker>
                    <div className={classNames.loadingAnimation}>
                        <div>
                            {
                                textArray.map((text) => {
                                    return (
                                        <SuperHeadingText>{text}</SuperHeadingText>
                                    )
                                })
                            }
                        </div>
                    </div>
                </LoadingBlocker>
            </ConditionalRender>
        </ConditionalRender>
    );
}

export default DisplayIsLoadingInputBlocker;
