// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender'
// UI
import Button from '../../../ui/Button'
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
import DisplayPhoneNumber from '../../../ui/DisplayPhoneNumber';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';

import { frontierErrorOccurred } from '../../../helpers/backendRequestGenerators';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function FrontierError({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [startedTimeout, setStartedTimeout] = useState(false);
    const [callNumber, setCallNumber] = useState("SET ME TO NUMBER")
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [isLoading, isLoadingDispatch] = useContext(IsLoadingContext);
    const [steps, stepsDispatch] = useContext(StepsContext);
    const environment = useContext(EnvironmentContext)[0];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierNV/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierNV/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    useEffect(() => {
        formDataDispatch({
            type: 'SET_QUOTE_ID',
            payload: "",
        });
        formDataDispatch({
            type: 'SET_ACCOUNT_UUID',
            payload: "",
        });
        formDataDispatch({ 
            type: 'SET_SCHEDULE_ID', 
            payload: ""
        });
        formDataDispatch({ 
            type: 'SET_INSTALL_DATE', 
            payload: ""
        });    
        formDataDispatch({
            type: 'SET_PRODUCT_ID',
            payload: ""
        });
        formDataDispatch({
            type: 'SET_PRODUCT_ITEM',
            payload: ""
        }); 
    }, [])

    return (
        <ConditionalRender 
            condition={steps.metaData.timesToErrorPage < 3}
            falseReturn={
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', flexGrow: '1' }}>
                    <div>
                        <HeadingText>Give Us a Call</HeadingText>
                        <SubHeadingText>It looks like we encountered an unexpected error with your application. Give us a call at the number below to finish applying for FREE Internet.</SubHeadingText>
                    </div>
                    <div className={classNames.bottom}>
                        <Button
                            style="nextNoBottomMargin"
                            onClick={() => {
                                window.open(`tel:${callNumber}`); 
                            }}>CALL NOW</Button>
                            <DisplayPhoneNumber>{callNumber}</DisplayPhoneNumber>
                    </div>
                </div>
            }
        >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', flexGrow: '1' }}>
                <div>
                    <HeadingText>{steps.metaData.errorTitle}</HeadingText>
                    <SubHeadingText>{steps.metaData.errorDescription}</SubHeadingText>
                </div>
                <Button 
                    style="loneNext" 
                    onClick={() => {
                        const body = frontierErrorOccurred(userId, formData)
                        const headers = {
                            Authorization: company,
                            "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                        }
                        fetch(environment.backendUrlToSetData, {
                            method: 'POST',
                            headers,
                            body: JSON.stringify(body),
                        })
                            .then(() => {
                                stepsDispatch({
                                    type: 'SET_CURRENT_STEP',
                                    payload: 10,
                                });
                                stepsDispatch({ type: "REMOVE_ERROR" })
                            })
                    }}
                >{steps.metaData.errorButtonText}</Button>
            </div>
        </ConditionalRender>
    )
}

FrontierError.propTypes = propTypes;
FrontierError.defaultProps = defaultProps;

export default FrontierError;