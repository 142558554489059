// Packages
import React from 'react';
import PropTypes from 'prop-types';
// Stores
import FormDataStore from '../contexts/FormData';
import ValidationStore from '../contexts/Validation';
import EnvironmentStore from '../contexts/Environment';
import StepsStore from '../contexts/Steps';
import IsLoadingStore from '../contexts/IsLoading';
import PopupStore from '../contexts/Popup';
import ConfigStore from '../contexts/Config';
import QueryParams from '../contexts/QueryParams';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
};

function ContextStores({ children }) {
    return (
        <StepsStore>
            <EnvironmentStore>
                <FormDataStore>
                    <IsLoadingStore>
                        <PopupStore>
                            <ConfigStore>
                                <QueryParams>
                                    <ValidationStore>{children}</ValidationStore>
                                </QueryParams>
                            </ConfigStore>
                        </PopupStore>
                    </IsLoadingStore>
                </FormDataStore>
            </EnvironmentStore>
        </StepsStore>
    );
}

ContextStores.propTypes = propTypes;

export default ContextStores;
