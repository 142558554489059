// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import reactStringReplace from 'react-string-replace';
// UI
import Bold from '../ui/Bold';
import Link from '../ui/Link';

// Props of the Component
const propTypes = {
    text: PropTypes.string.isRequired,
};

function StyleDisclosuresText({ text }) {
    const [bodyText, setBodyText] = useState(<></>);
    const styleIdentifiers = [
        {
            pattern: /\[textlink\](.*?)\[\/textlink\]/gm,
            functionToRunOnMatched: (match) => {
                const linkAndLinkText = match.split('|');

                const link = linkAndLinkText[1].startsWith('https://')
                    ? linkAndLinkText[1]
                    : `https://${linkAndLinkText[1]}`;
                const linkText = linkAndLinkText[0];

                return <Link key={Math.random() * (1000 - 0) + 0} href={link}>{linkText}</Link>;
            },
        },
        {
            pattern: /\[bold\](.*?)\[\/bold\]/gm,
            functionToRunOnMatched: (match) => <Bold key={Math.random() * (1000 - 0) + 0}>{match}</Bold>,
        },
        {
            pattern: /\[ul\](.*?)\[\/ul\]/gm,
            functionToRunOnMatched: (match) => {
                // console.log("YES!")
                const bulletPoints = match.split('|');

                return (
                    <ul key={Math.random() * (1000 - 0) + 0}>{
                        bulletPoints.map((bulletText, i) => {
                            return <li key={i}>{bulletText}</li>;
                        })
                    }</ul>
                )
            },
        },
        {
            pattern: /\[ol\](.*?)\[\/ol\]/gm,
            functionToRunOnMatched: (match) => {
                // console.log("YES!")
                const bulletPoints = match.split('|');

                return (
                    <ol key={Math.random() * (1000 - 0) + 0}>{
                        bulletPoints.map((bulletText, i) => {
                            return <li key={i}>{bulletText}</li>;
                        })
                    }</ol>
                )
            },
        },
    ];

    useEffect(() => {
        let tempBodyText = text;

        for (let i = 0; i < styleIdentifiers.length; i += 1) {
            tempBodyText = reactStringReplace(
                tempBodyText,
                styleIdentifiers[i].pattern,
                styleIdentifiers[i].functionToRunOnMatched
            );
        }

        setBodyText(tempBodyText);
    }, [text]);

    return bodyText;
}

StyleDisclosuresText.propTypes = propTypes;

export default StyleDisclosuresText;
