// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// UI
import BodyText from './BodyText'

// Props of the Component
const propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};
const defaultProps = {
    className: ""
};

function Bold({ children, className }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Bold/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Bold/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Bold.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Bold.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Bold/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Bold/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return <BodyText className={`${classNames.bold} ${className}`}>{children}</BodyText>;
}

Bold.propTypes = propTypes;
Bold.defaultProps = defaultProps;

export default Bold;
