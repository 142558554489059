// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import BodyText from './BodyText';

// Props of the Component
const propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    maxWords: PropTypes.number
};
const defaultProps = {
    className: '',
    maxWords: null
};

function SubHeadingText({ children, className, maxWords }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [toggleReadMore, setToggleReadMore] = useState(maxWords === null ? true : false)

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/SubHeadingText/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/SubHeadingText/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/SubHeadingText.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/SubHeadingText.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/SubHeadingText/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/SubHeadingText/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    if (children) {
        if (maxWords) {
            return (
                <BodyText className={`${classNames.subHeading} ${className}`}>
                    <ConditionalRender
                        condition={toggleReadMore}
                        falseReturn={<>{children.split(" ").slice(0, maxWords).join(" ")}... <button className={classNames.readMoreButton} onClick={() => { setToggleReadMore(true) }}>Read More</button></>}
                    >
                        <>{children} <ConditionalRender condition={maxWords !== null}><button className={classNames.readMoreButton} onClick={() => { setToggleReadMore(false) }}>Read Less</button></ConditionalRender></>
                    </ConditionalRender>
                </BodyText>
            )
        } else {
            return (
                <BodyText className={`${classNames.subHeading} ${className}`}>
                    {children}
                </BodyText>
            )
        }
    } else {
        return <></>
    }
}

SubHeadingText.propTypes = propTypes;
SubHeadingText.defaultProps = defaultProps;

export default SubHeadingText;
