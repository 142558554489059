// Packages
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Title() {
    const { company } = useParams();

    useEffect(() => {
        const companyDisplayName = {
            "freeconnect": "FreeConnect",
            "redpocket": "RedPocket",
            "natwireless": "National",
            "via": "Via",
            "optimumfreeacp": "Optimum",
            "gotech": "Go Tech",
            "gotechacp": "Go Tech"
        }

        document.title = companyDisplayName[company] ? `Apply Now | ${companyDisplayName[company]}` : `Apply Now`
    }, [company]);
}

export default Title;
