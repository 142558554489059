// Packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// UI
import SmallText from '../../../../ui/SmallText';

// Props of the Component
const propTypes = {
    className: PropTypes.string
};

function FooterText({ className }) {
    return <SmallText className={className}>&#169;{new Date().getFullYear()} Default Company llc. All Rights Reserved</SmallText>;
}

FooterText.propTypes = propTypes;

export default FooterText;
