// Packages
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ImInfo } from 'react-icons/im';
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import BodyText from './BodyText';
// Contexts
import { ValidationContext } from '../contexts/Validation';

// Props of the Component
const propTypes = {
    inputs: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    labelTextClassName: PropTypes.string,
    validationKey: PropTypes.string,
    validationFunction: PropTypes.func,
    initialCheck: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    step: PropTypes.number,
};
const defaultProps = {
    inputs: [],
    name: "",
    onChange: () => {},
    disabled: false,
    children: '',
    className: '',
    inputClassName: '',
    validationKey: '',
    validationFunction: () => {},
    labelTextClassName: '',
    initialValue: '',
    step: null,
};

function RadioMenu({ 
    inputs, 
    name, 
    onChange, 
    validationKey, 
    validationFunction,
    initialValue,
    step,
    disabled
}) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [isValidState, setIsValidState] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');
    const [validation, ValidationDispatch] = useContext(ValidationContext);
    const [storedReloadValidation, setStoredReloadValidation] = useState(validation.reloadValidation);
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/RadioMenu/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/RadioMenu/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/RadioMenu.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/RadioMenu.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/RadioMenu/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/RadioMenu/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        if (validationKey) {
            if (validation.reloadValidation > storedReloadValidation) {
                const { isValid, message } = validationFunction(value);
                setIsValidState(isValid);
                setValidationMessage(message);
                ValidationDispatch({
                    type: `SET_${validationKey.toUpperCase()}`,
                    payload: { step, isValid },
                });
            }
        }
    }, [validation.reloadValidation, storedReloadValidation]);

    useEffect(() => {
        if ((validationKey && step) || step === 0) {
            ValidationDispatch({
                type: `SET_${validationKey.toUpperCase()}_STEP`,
                payload: step,
            });
        }
    }, [step]);

    useEffect(() => {
        setStoredReloadValidation(validation.reloadValidation);
    }, []);

    return (
        <div className={classNames.container} onChange={(event) => { 
                if (!disabled) {
                    if (validationKey) {
                        const { isValid, message } = validationFunction(event.target.value);
                        setIsValidState(isValid);
                        setValidationMessage(message);
                        ValidationDispatch({
                            type: `SET_${validationKey.toUpperCase()}`,
                            payload: { isValid, step },
                        });
                    }
                    onChange(event.target.value) 
                    // console.log(event.target.value)
                    setValue(event.target.value)
                }
            }}>
            {
                inputs.map((input) => {
                    return (
                        <label className={classNames.label}>
                            <input className={classNames.input} type="radio" value={input} name={name} />
                            {input}
                        </label>
                    )
                })
            }
            <ConditionalRender condition={!isValidState}>
                <BodyText className={classNames.validationMessage}><ImInfo /> {validationMessage}</BodyText>
            </ConditionalRender>
        </div>
    );
}

RadioMenu.propTypes = propTypes;
RadioMenu.defaultProps = defaultProps;

export default RadioMenu;
