// Packages
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ImInfo } from 'react-icons/im';
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import LabelText from './LabelText';
import Switch from './Switch';
// Contexts
import { ValidationContext } from '../contexts/Validation';
import { FormDataContext } from '../contexts/FormData';

// Props of the Component
const propTypes = {
    validationKey: PropTypes.string,
    validationFunction: PropTypes.func,
    step: PropTypes.number,
};
const defaultProps = {
    validationKey: '',
    validationFunction: () => {},
    step: null,
};

function FrontierCommunicationSwitches({
    validationKey,
    validationFunction,
    step,
}) {
    const { company } = useParams();
    const [amountOfSwitchesChecked, setAmountOfSwitchesChecked] = useState(3);
    const [classNames, setClassNames] = useState({});
    const [isValidState, setIsValidState] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');
    const [validation, ValidationDispatch] = useContext(ValidationContext);
    const [storedReloadValidation, setStoredReloadValidation] = useState(validation.reloadValidation);
    const [formData, formDataDispatch] = useContext(FormDataContext);

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/freeconnect/styles/steps/FrontierCommunication/index.less`)
        import(/* webpackPreload: true */ `../companies/freeconnect/styles/steps/FrontierCommunication/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);
    

    useEffect(() => {
        if (validationKey) {
            if (validation.reloadValidation > storedReloadValidation) {
                const { isValid, message } = validationFunction(amountOfSwitchesChecked);
                // console.log(isValid)
                setIsValidState(isValid);
                setValidationMessage(message);
                ValidationDispatch({
                    type: `SET_${validationKey.toUpperCase()}`,
                    payload: { step, isValid },
                });
            }
        }
    }, [validation.reloadValidation, storedReloadValidation]);

    useEffect(() => {
        if ((validationKey && step) || step === 0) {
            ValidationDispatch({
                type: `SET_${validationKey.toUpperCase()}_STEP`,
                payload: step,
            });
        }
    }, [step]);

    useEffect(() => {
        setStoredReloadValidation(validation.reloadValidation);
    }, []);

    return (
        <>
            {validationMessage}
            <div className={`${classNames.contentType} ${classNames.divider}`} >
                <LabelText>Account Information</LabelText>
                <Switch 
                    initialValue={formData.accountInfoSMS}
                    onCheck={(checked) => { 
                        if (checked) {
                            setAmountOfSwitchesChecked(prev => (prev+1))
                        } else {
                            setAmountOfSwitchesChecked(prev => (prev-1))
                        }
                        // console.log("account info ", checked)
                        formDataDispatch({
                            type: 'SET_ACCOUNT_INFO_SMS',
                            payload: checked,
                        }); 
                        formDataDispatch({
                            type: 'SET_ACCOUNT_INFO_EMAIL',
                            payload: checked,
                        }); 
                    }} 
                />
            </div>
            <div  className={`${classNames.contentType} ${classNames.divider}`} >
                <LabelText>Service Updates</LabelText>
                <Switch 
                    initialValue={formData.serviceUpdateSMS}
                    onCheck={(checked) => { 
                        if (checked) {
                            setAmountOfSwitchesChecked(prev => (prev+1))
                        } else {
                            setAmountOfSwitchesChecked(prev => (prev-1))
                        }
                        formDataDispatch({
                            type: 'SET_SERVICE_UPDATE_SMS',
                            payload: checked,
                        }); 
                        formDataDispatch({
                            type: 'SET_SERVICE_UPDATE_EMAIL',
                            payload: checked,
                        });
                    }} />
            </div>
            <div className={`${classNames.contentType}`} >
                <LabelText>Marketing Promotions</LabelText>
                <Switch 
                    initialValue={formData.marketPromotionSMS}
                    onCheck={(checked) => { 
                        if (checked) {
                            setAmountOfSwitchesChecked(prev => (prev+1))
                        } else {
                            setAmountOfSwitchesChecked(prev => (prev-1))
                        }
                        formDataDispatch({
                            type: 'SET_MARKET_PROMOTION_SMS',
                            payload: checked,
                        }); 
                        formDataDispatch({
                            type: 'SET_MARKET_PROMOTION_EMAIL',
                            payload: checked,
                        });
                    }} />
            </div>
        </>
    );
}

FrontierCommunicationSwitches.propTypes = propTypes;
FrontierCommunicationSwitches.defaultProps = defaultProps;

export default FrontierCommunicationSwitches;
