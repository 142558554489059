// Packages
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types'
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import Popup from '../ui/Popup';
// Contexts
import { PopupContext } from '../contexts/Popup';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};
const defaultProps = {
    children: null,
};

function DisplayPopup() {
    const popupState = useContext(PopupContext)[0];

    return (
        <ConditionalRender condition={popupState.display}>
            <Popup title={popupState.title} onClick={ popupState.onClick ? popupState.onClick : () => {} }>{popupState.componentToRender}</Popup>
        </ConditionalRender>
    );
}

DisplayPopup.propTypes = propTypes;
DisplayPopup.defaultProps = defaultProps;

export default DisplayPopup;
