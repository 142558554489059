// Images
import thumb from './thumb.svg';

const classNames = {
    page: "success-page",
    superHeader: "success-page-super-header",
    image: {
        src: thumb,
        altText: "Thumbs up",
        className: "success-page-image"
    }
}

export default classNames