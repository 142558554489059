// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// Components
import Favicon from '../components/Favicon';
import Title from '../components/Title';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
    className: PropTypes.string,
};
const defaultProps = {
    className: '',
};

function Page({ children, className }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Page/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Page/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Page.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Page.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Page/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Page/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <>
            <Favicon />
            <Title />
            <div className={`${classNames.page} ${className}`}>{children}</div>
        </>
    );
}

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
