// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender';
// UI
import Button from '../../../ui/Button';
import ShowMore from '../../../ui/ShowMore';
import LoadingBlocker from '../../../ui/LoadingBlocker';
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function Programs({ step, mode, onNext, onPrev }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [programs, setPrograms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [notServiceable, setNotServiceable] = useState(false);
    const [morePrograms, setMorePrograms] = useState(false);
    const [formDataState, formDataDispatch] = useContext(FormDataContext);
    const environmentState = useContext(EnvironmentContext)[0];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Programs/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/Programs/index.less`);
        });
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Programs/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/Programs/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        const headers = { "Content-Type": "application/json" }
        const body = {
            Abbreviation: company,
            zip_code: formDataState.address?.preferedZip,
            is_tribal: formDataState.isTribal ? formDataState.isTribal : 'N',
            enrollment_type: formDataState.enrollment_type ? formDataState.enrollment_type : "ACP",
            Call: "get_programs",
        };
        fetch(environmentState.vcareUrl, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoading(false);
                if (data[0] === "Please enter valid 5 digit zip code") {
                    setNotServiceable(true);
                } else if (data[0] === "Service is not available for this Zip Code") {
                    setNotServiceable(true);
                } else {
                    setPrograms(data);
                    setNotServiceable(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setNotServiceable(true);
                setIsLoading(false);
            });
    }, []);

    return (
        <div className={classNames.centerItems}>
            <HeadingText>Qualifying Benefits</HeadingText>
            <SubHeadingText>
                {
                    company === "gotech" || company == "gotechacp" ? "Select your benefit program so we can get you qualified for Free Wireless Service." : "Please confirm your benefit information."
                }
            </SubHeadingText>
            <ConditionalRender
                falseReturn={<><SubHeadingText className={classNames.textCenter}>Service is not available for the Zip Code provided</SubHeadingText></>}
                condition={!notServiceable && formDataState.address?.preferedZip}
            >
                <ConditionalRender falseReturn={<LoadingBlocker />} condition={!isLoading}>
                    <div className={classNames.programsContainer}>
                        {programs.map((program, i) => {
                            if (!morePrograms) {
                                if (program.program_code?.includes("SNAP") || program.program_code?.includes("SSI") || program.program_code?.includes("MEDIC") || program.program_code?.includes("MCAID")) {
                                    return (
                                        <Button
                                            key={i}
                                            type="button"
                                            style="label"
                                            labelText={program.program_name}
                                            className={classNames.programsStepButton}
                                            onClick={() => {
                                                formDataDispatch({ type: 'ADD_PROGRAM', payload: program });
                                                onNext()
                                            }}
                                        >
                                            {program.program_code}
                                        </Button>
                                    )
                                } else {
                                    return <Fragment key={i} />
                                }
                            } else {
                                return (
                                    <Button
                                        key={i}
                                        type="button"
                                        style="label"
                                        labelText={program.program_name}
                                        className={classNames.programsStepButton}
                                        onClick={() => {
                                            formDataDispatch({ type: 'ADD_PROGRAM', payload: program });
                                            onNext()
                                        }}
                                    >
                                        {program.program_code}
                                    </Button>
                                )
                            }
                        })}
                        <ShowMore onClick={(moreOrLess) => {
                            if (moreOrLess) {
                                setMorePrograms(true)
                            } else {
                                setMorePrograms(false)
                            }
                            
                        }} />
                    </div>
                </ConditionalRender>
            </ConditionalRender>
        </div>
    );
}

Programs.propTypes = propTypes;
Programs.defaultProps = defaultProps;

export default Programs;
