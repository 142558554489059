// Packages
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
// UI
import SubHeadingText from './SubHeadingText'
import BodyText from './BodyText'
import Button from './Button'
// Components
import ConditionalRender from '../components/ConditionalRender';
// Contexts
import { ValidationContext } from '../contexts/Validation';
import { PopupContext } from '../contexts/Popup';

// Props of the Component
const propTypes = {
    onChecked: PropTypes.func,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    labelTextClassName: PropTypes.string,
    validationKey: PropTypes.string,
    validationFunction: PropTypes.func,
    initialCheck: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    step: PropTypes.number,
    popUpTitle: PropTypes.string,
    popUpContent: PropTypes.node
};
const defaultProps = {
    onChecked: () => {},
    disabled: false,
    children: '',
    className: '',
    inputClassName: '',
    validationKey: '',
    validationFunction: () => {},
    labelTextClassName: '',
    initialCheck: false,
    step: null,
    popUpTitle: null,
    popUpContent: null
};

function CheckBox({
    onChecked,
    name,
    disabled,
    children,
    className,
    inputClassName,
    validationKey,
    validationFunction,
    labelTextClassName,
    initialCheck,
    step,
    popUpTitle,
    popUpContent
}) {
    const { company } = useParams();
    const [checked, setChecked] = useState(initialCheck);
    const [classNames, setClassNames] = useState({});
    const [isValidState, setIsValidState] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');
    const [validation, ValidationDispatch] = useContext(ValidationContext);
    const [storedReloadValidation, setStoredReloadValidation] = useState(validation.reloadValidation);
    const [popupState, popupDispatch] = useContext(PopupContext);

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/CheckBox/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/CheckBox/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/CheckBox.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/CheckBox.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/CheckBox/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/CheckBox/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        if (validationKey) {
            if (validation.reloadValidation > storedReloadValidation) {
                const { isValid, message } = validationFunction(checked);
                setIsValidState(isValid);
                setValidationMessage(message);
                ValidationDispatch({
                    type: `SET_${validationKey.toUpperCase()}`,
                    payload: { step, isValid },
                });
            }
        }
    }, [validation.reloadValidation, storedReloadValidation]);

    useEffect(() => {
        if ((validationKey && step) || step === 0) {
            // console.log(step)
            ValidationDispatch({
                type: `SET_${validationKey.toUpperCase()}_STEP`,
                payload: step,
            });
        }
    }, [step]);

    useEffect(() => {
        setStoredReloadValidation(validation.reloadValidation);
    }, []);

    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <div style={{display: 'flex'}} className={classNames.container}>
            <label
                className={`${classNames.label} ${isValidState ? classNames.label : classNames.errorLabel} ${className}`}
            >
                <div className={`${classNames.text} ${labelTextClassName} ${!isValidState ? classNames.validationMessage : ''}`}>
                    {children}{' '}
                    <ConditionalRender condition={!isValidState}>
                        <div className={classNames.validationMessage} style={{ display: "flex", marginTop: "4px" }}>
                            <div style={{  height: "1em", width: "1em" }}><AiOutlineExclamationCircle /></div><div>&nbsp;</div>
                            {validationMessage}
                        </div>
                    </ConditionalRender>
                </div>
                <input
                    type="checkbox"
                    name={name}
                    checked={checked}
                    onChange={() => {
                        if (!disabled) {
                            if (validationKey) {
                                const { isValid, message } = validationFunction(!checked);
                                setIsValidState(isValid);
                                setValidationMessage(message);
                                ValidationDispatch({
                                    type: `SET_${validationKey.toUpperCase()}`,
                                    payload: { isValid, step },
                                });
                            }
                            onChecked(!checked);
                            setChecked(!checked);
                        }
                    }}
                />
                <span className={classNames.checkmark}></span>
            </label>
            <ConditionalRender condition={popUpTitle && popUpContent}>
                <button type="button" onClick={() => {popupDispatch({ type: 'ADD_POPUP', payload: {
                    title: popUpTitle,
                    onClick: () => { popupDispatch({ type: 'REMOVE_POPUP' }) },
                    component: (
                        <div className={classNames.popUpContent}>
                            <SubHeadingText>{popUpContent}</SubHeadingText>
                            <Button style="previous" onClick={() => { 
                                popupDispatch({ type: 'REMOVE_POPUP' })
                            }} >Close</Button>
                        </div>
                )}})}} className={classNames.infoButton}>  ⓘ</button>
            </ConditionalRender>
        </div>
    );
}

CheckBox.propTypes = propTypes;
CheckBox.defaultProps = defaultProps;

export default CheckBox;
