// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ConditionalRender from '../components/ConditionalRender';
import Link from '../ui/Link';
import BodyText from './BodyText';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string,
    showEditButton: PropTypes.bool,
    editButtonOnClick: PropTypes.func
};
const defaultProps = {
    children: '',
    className: '',
    showEditButton: false,
    editButtonOnClick: () => {}
};

function ConfirmAddressDisplay({ addressObject, title, showEditButton, editButtonOnClick }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ConfirmAddressDisplay/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ConfirmAddressDisplay/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/ConfirmAddressDisplay.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/ConfirmAddressDisplay.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/ConfirmAddressDisplay/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/ConfirmAddressDisplay/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <div className={classNames.main}>
            <h2 className={classNames.title}>{title}</h2>
                <ConditionalRender 
                    condition={showEditButton}
                    falseReturn={
                        <div className={classNames.containerFlexed}>
                            <div>
                                <article className={classNames.textContainer}><h3 className={classNames.subTitle}>Address:</h3> <p className={classNames.text}>{ addressObject?.parsedAddress?.street.trim() + (addressObject?.parsedAddress?.line2 ? `, ${addressObject?.parsedAddress?.line2}` : '') }</p></article>
                                <article className={classNames.textContainer}><h3 className={classNames.subTitle}>City:</h3> <p className={classNames.text}>{ addressObject?.parsedAddress?.city }</p></article>
                                <article className={classNames.textContainer}><h3 className={classNames.subTitle}>State:</h3> <p className={classNames.text}>{ addressObject?.parsedAddress?.state }</p></article>
                                <article className={classNames.textContainer}><h3 className={classNames.subTitle}>Zip Code:</h3> <p className={classNames.text}>{ addressObject?.parsedAddress?.zip }</p></article>
                            </div>
                            <Link onClick={editButtonOnClick}><BodyText className={classNames.link}>Edit Info</BodyText></Link>
                        </div>
                    }
                >
                    <div className={classNames.containerFlexed}>
                        <div>
                            <article className={classNames.textContainer}><h3 className={classNames.subTitle}>Address:</h3> <p className={classNames.text}>{ addressObject?.parsedAddress?.street.trim() + (addressObject?.parsedAddress?.line2 ? `, ${addressObject?.parsedAddress?.line2}` : '') }</p></article>
                            <article className={classNames.textContainer}><h3 className={classNames.subTitle}>City:</h3> <p className={classNames.text}>{ addressObject?.parsedAddress?.city }</p></article>
                            <article className={classNames.textContainer}><h3 className={classNames.subTitle}>State:</h3> <p className={classNames.text}>{ addressObject?.parsedAddress?.state }</p></article>
                            <article className={classNames.textContainer}><h3 className={classNames.subTitle}>Zip Code:</h3> <p className={classNames.text}>{ addressObject?.parsedAddress?.zip }</p></article>
                        </div>
                        <Link onClick={editButtonOnClick}><BodyText className={classNames.link}>Edit Info</BodyText></Link>
                    </div>
                </ConditionalRender>
        </div>
    );
}

ConfirmAddressDisplay.propTypes = propTypes;
ConfirmAddressDisplay.defaultProps = defaultProps;

export default ConfirmAddressDisplay;
