const classNames = {
    main: 'main-confirmaddressdisplay',
    textContainer: 'text-container-confirmaddressdisplay',
    title: 'title-confirmaddressdisplay',
    container: 'container-confirmaddressdisplay',
    subTitle: 'sub-title-confirmaddressdisplay',
    text: 'text-confirmaddressdisplay',
    containerFlexed: 'container-confirmaddressdisplay container-confirmaddressdisplay-flexed',
    link: 'link-confirmaddressdisplay',
}

export default classNames