const classNames = {
    programsContainer: 'programs-container',
    validationMessage: 'programs-validation-error',
    centerItems: 'programs-step-center-items',
    textCenter: 'programs-text-center',
    programContainer: 'programs-step-program-container',
    programsStepButton: 'programs-step-button'
};

export default classNames;
