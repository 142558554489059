// Images
import ErrorImage from './ErrorImage.png';

const classNames = {
    page: "error-page",
    pageFc: "error-page-fc",
    errorSub: "error-sub-fc",
    errorSubContainer: "error-sub-container",
    superHeader: "error-page-super-header",
    image: {
        src: ErrorImage,
        altText: "A confused woman holding a question mark",
        className: "error-page-image"
    }
}

export default classNames