// Packages
import React, { createContext, useReducer, useMemo } from 'react';
// import PropTypes from 'prop-types';

// Contexts
import QueryParamsReducer from './reducers'
import initialState from './initialState'

// const propTypes = {
//     children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
//         .isRequired,
// };

function Store({ children }) {
    const [state, dispatch] = useReducer(QueryParamsReducer, initialState)

    const stateVariables = useMemo(() => [state, dispatch], [state, dispatch])

    return <QueryParamsContext.Provider value={stateVariables}>{children}</QueryParamsContext.Provider>
}

// Store.propTypes = propTypes;

export const QueryParamsContext = createContext({})
export default Store