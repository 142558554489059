export const backendUrlToGetData = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/get';
        default:
            return 'https://glp8ta3547.execute-api.us-east-1.amazonaws.com/prod/get';
    }
};
export const backendUrlToSetData = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/set';
        default:
            return 'https://glp8ta3547.execute-api.us-east-1.amazonaws.com/prod/set';
    }
};
export const backendUrlToSubmitData = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/set';
        default:
            return 'https://glp8ta3547.execute-api.us-east-1.amazonaws.com/prod/submit';
    }
};
export const disclosuresBackendUrlToGetData = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/';
        default:
            return 'https://txya81kbwe.execute-api.us-east-1.amazonaws.com/prod';
    }
};
export const smartyVerificationUrl = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/';
        case 'test':
            return 'https://96ar7fxe0b.execute-api.us-east-1.amazonaws.com/prod/address_verification';
        default:
            return 'https://96ar7fxe0b.execute-api.us-east-1.amazonaws.com/prod/address_verification';
    }
};
export const vcareApiUrl = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/';
        case 'test':
            return 'https://hk5eio0zbk.execute-api.us-east-1.amazonaws.com/dev/Vcare_API';
        default:
            return 'https://hk5eio0zbk.execute-api.us-east-1.amazonaws.com/live/Vcare_API';
    }
}
export const marketplaceServiceabilityBackendUrlToCheck = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/';
        case 'test':
            return 'https://f6ct3tzkk2iqssha5ppdosuinm0xtlhq.lambda-url.us-east-1.on.aws/';
        default:
            return 'https://f6ct3tzkk2iqssha5ppdosuinm0xtlhq.lambda-url.us-east-1.on.aws/';
    }
}

export const backendUrlToSetDataForKore = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/set';
        case 'localhosttest':
            return 'http://localhost:3000/test/set';
        case 'dev':
            return 'https://afadojxfa5.execute-api.us-east-1.amazonaws.com/prod/set';
        case 'devtest':
            return 'https://afadojxfa5.execute-api.us-east-1.amazonaws.com/prod/test/set';
        case 'testing':
            return 'https://ffsvy5gce5.execute-api.us-east-1.amazonaws.com/prod/set';
        case 'testingtest':
            return 'https://ffsvy5gce5.execute-api.us-east-1.amazonaws.com/prod/test/set';
        case 'prodtest':
            return 'https://ftzc141ic1.execute-api.us-east-1.amazonaws.com/prod/test/set';
        default:
            return 'https://ftzc141ic1.execute-api.us-east-1.amazonaws.com/prod/set';
    }
}

export const backendUrlToSetDataForSecurePii = (environment) => {
    switch (environment){
        case 'localhost':
            // return 'http://localhost:3000/';
            // NOTE - Test in the development account
            return {
                url: 'https://4pgmiu5ss8.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_SECUREPII_X_API_KEY
            };
        case 'dev':
            return {
                url: 'https://4pgmiu5ss8.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_SECUREPII_X_API_KEY
            };
        case 'test':
            return {
                url: 'https://htnh2yaalh.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.TEST_SECUREPII_X_API_KEY
            };
        default:
            return {
                url: 'https://f5o35yoipl.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.PROD_SECUREPII_X_API_KEY
            };
    }
}

export const baseURLForFrontierBackend = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/prod/';
        case 'localhosttest':
            return 'http://localhost:3000/ctest/';
        case 'devtest':
            return 'https://w1gfcr97yf.execute-api.us-east-1.amazonaws.com/prod/ctest/';
        case 'dev':
            return ' https://w1gfcr97yf.execute-api.us-east-1.amazonaws.com/prod/prod/';
        case 'testingtest':
            return 'https://6yrd9v3205.execute-api.us-east-1.amazonaws.com/prod/ctest/';
        case 'testing':
            return 'https://6yrd9v3205.execute-api.us-east-1.amazonaws.com/prod/prod/';
        case 'prodtest':
            return 'https://o4tgd15pz6.execute-api.us-east-1.amazonaws.com/prod/ctest/';
        default:
            return 'https://o4tgd15pz6.execute-api.us-east-1.amazonaws.com/prod/prod/';
    }
}

export const urlForFrontierPredictiveSearch = (environment) => {
    return `${baseURLForFrontierBackend(environment)}search`;
}

export const urlForFrontierGetOffersByAddress = (environment) => {
    return `${baseURLForFrontierBackend(environment)}get_offers_by_address`;
}

export const urlForFrontierGetOffersLowIncome = (environment) => {
    return `${baseURLForFrontierBackend(environment)}get_offers_display`;
}

export const urlForFrontierGetDisclosures = (environment) => {
    return `${baseURLForFrontierBackend(environment)}get_disclosures`;
}

export const urlForFrontierAcceptDisclosures = (environment) => {
    return `${baseURLForFrontierBackend(environment)}accept_disclosures`;
}

export const urlForFrontierCreateQuote = (environment) => {
    return `${baseURLForFrontierBackend(environment)}create_quote`;
}

export const urlForFrontierLowIncomeByApp = (environment) => {
    return `${baseURLForFrontierBackend(environment)}low_income_verification_by_app_id`;
}

export const urlForFrontierLowIncomeVerification = (environment) => {
    return `${baseURLForFrontierBackend(environment)}low_income`;
}

export const urlForFrontierQuoteItemPost = (environment) => {
    return `${baseURLForFrontierBackend(environment)}quote_item_post`;
}

export const urlForFrontierQuoteItemPut = (environment) => {
    return `${baseURLForFrontierBackend(environment)}quote_item_put`;
}

export const urlForFrontierEligibilityCheck = (environment) => {
    return `${baseURLForFrontierBackend(environment)}eligibility_check`;
}

export const urlForFrontierFinishQuoteCreate = (environment) => {
    return `${baseURLForFrontierBackend(environment)}finish_quote_create`;
}

export const urlForFrontierCloseSelectOffers = (environment) => {
    return `${baseURLForFrontierBackend(environment)}close_select_offers`;
}

export const urlForFrontierCustomerCreditCheck = (environment) => {
    return `${baseURLForFrontierBackend(environment)}customer_credit_check`;
}

export const urlForFrontierCustomerIdentityVerification = (environment) => {
    return `${baseURLForFrontierBackend(environment)}customer_identity_verification`;
}

export const urlForFrontierVerifyCustomerPreviousAddress = (environment) => {
    return `${baseURLForFrontierBackend(environment)}verify_customer_previous_address`;
}

export const urlForFrontierCustomerCreditBundle = (environment) => {
    return `${baseURLForFrontierBackend(environment)}customer_credit_bundle`;
}

export const urlForFrontierGetQuote = (environment) => {
    return `${baseURLForFrontierBackend(environment)}get_quote`;
}

export const urlForFrontierGetDepositRequirements = (environment) => {
    return `${baseURLForFrontierBackend(environment)}get_deposit_requirements`;
}

export const urlForFrontierGetSchedule = (environment) => {
    return `${baseURLForFrontierBackend(environment)}get_schedule`;
}

export const urlForFrontierFinishReserveSchedule = (environment) => {
    return `${baseURLForFrontierBackend(environment)}finish_reserve_schedule`;
}

export const urlForFrontierSubmitCustomerJourney = (environment) => {
    return `${baseURLForFrontierBackend(environment)}submit_customer_journey`;
}

export const urlForFrontierGetOffersDisplay = (environment) => {
    return `${baseURLForFrontierBackend(environment)}get_offers_display`;
}

export const keyForFrontier = (environment) => {
    switch (environment) {
        case 'localhost':
            return '';
        case 'localhosttest':
            return '';
        case 'devtest':
            return process.env.DEV_FRONTIER_API_KEY;
        case 'dev':
            return process.env.DEV_FRONTIER_API_KEY;
        case 'testingtest':
            return process.env.TESTING_FRONTIER_API_KEY;
        case 'testing':
            return process.env.TESTING_FRONTIER_API_KEY;
        case 'prodtest':
            return process.env.FRONTIER_API_KEY;
        default:
            return process.env.FRONTIER_API_KEY;
    }
}

export const backendUrlToAddToLiveVox = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/';
        default:
            return 'https://ttwghiedyd.execute-api.us-east-1.amazonaws.com/prod';
    }
}


export const backendUrlOptimumServiceability = (environment) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/';
        default:
            return 'https://91ae08bf74.execute-api.us-east-1.amazonaws.com/v1/';
    }
}

export const backendURLFCUSServiceability = (environment) => {
    switch(environment){
        case 'localhost':
            return {
                url: 'https://wfk79psoll.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_FC_SERVICEABILITY_KEY,
                devEnv: true
            };
        case 'dev':
            return {
                url: 'https://wfk79psoll.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_FC_SERVICEABILITY_KEY,
                devEnv: true
            };
        case 'testing':
            return {
                url: 'https://ndz1cq9q7b.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.TEST_FC_SERVICEABILITY_KEY
            };
        default:
            return {
                url: 'https://auqskz8w51.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.PROD_FC_SERVICEABILITY_KEY
            };
    }
}

export const backendURLSpectrumServiceability = (environment) => {
    switch(environment){
        case 'localhost':
            return {
                url: 'https://6qlj91vxh5.execute-api.us-east-1.amazonaws.com/prod/spectrum/results',
                key: process.env.DEV_SPECTRUM_SERVICEABILITY_KEY
            };
        case 'testing':
            return {
                url: 'https://qhk53dtlw0.execute-api.us-east-1.amazonaws.com/prod/spectrum/results',
                key: process.env.TEST_SPECTRUM_SERVICEABILITY_KEY
            };
        default:
            return {
                url: 'https://5365dpw59f.execute-api.us-east-1.amazonaws.com/prod/spectrum/results',
                key: process.env.PROD_SPECTRUM_SERVICEABILITY_KEY
            };
    }
}

export const backendURLFixedRedirectReporting = (environment) => {
    switch(environment){
        case 'localhost':
            return{
                url: "https://ukbll4wlg5.execute-api.us-east-1.amazonaws.com/prod/write_entry",
                key: process.env.DEV_FIXED_REDIRECT_KEY
            }
        case 'testing':
            return{
                url: "https://0jxwy56fj3.execute-api.us-east-1.amazonaws.com/prod/write_entry",
                key: process.env.TESTING_FIXED_REDIRECT_KEY
            }
        default:
            return{
                url: "https://skvwgu0g38.execute-api.us-east-1.amazonaws.com/prod/write_entry",
                key: process.env.PROD_FIXED_REDIRECT_KEY
            }
    }
}

export const backendURLActiveProspect = (environment) => {
    switch(environment){
        case 'localhost':
            return{
                url: "https://localhost:3000/",
                key: "akey"
            }
        case 'dev':
            return{
                url: "https://r23stnwe35.execute-api.us-east-1.amazonaws.com/prod/",
                key: process.env.DEV_ACTIVE_PROSPECT
            }
        case 'testing':
            return{
                url: "https://l1kjab94b3.execute-api.us-east-1.amazonaws.com/prod/",
                key: process.env.TESTING_ACTIVE_PROSPECT
            }
        default:
            return{
                url: "https://51pgwq2om4.execute-api.us-east-1.amazonaws.com/prod/",
                key: process.env.PROD_ACTIVE_PROSPECT
            }
    }
}

export const backendUrlGetParameter = (environment) => {
    switch (environment) {
        case 'localhost':
            return {
                url: 'http://localhost:3000/',
                key: "akey"
            }
        default:
            return {
                url: 'https://r7d5uykoy2.execute-api.us-east-1.amazonaws.com/prod/get-parameter-from-store',
                key: process.env.UTILITY_X_API_KEY
            }
    }
};

export const backendUrlCNX1 = (environment) => {
    switch (environment) {
        case 'localhost':
            return {
                url: 'http://localhost:3000/',
                key: "akey"
            }
        case 'dev':
            return {
                url: 'https://lboyueuzli.execute-api.us-east-1.amazonaws.com/prod/process-demoform',
                key: process.env.DEV_CNX1_X_API_KEY
            };
        default:
            return {
                url: 'https://fpqo866loe.execute-api.us-east-1.amazonaws.com/prod/process-demoform',
                key: process.env.PROD_CNX1_X_API_KEY
            }
    }
};

export const backendUrlToGenerateDisclosuresPdf = (environment) => {
    switch (environment){
        case 'localhost':
            return {
                url: 'http://localhost:3000/',
                key: process.env.DEV_PDFGEN_X_API_KEY
            };
        case 'dev':
            return {
                url: 'https://99rjlldazg.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_PDFGEN_X_API_KEY
            };
        case 'test':
            return {
                url: 'https://52y7pwwjc1.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.TEST_PDFGEN_X_API_KEY
            };
        default:
            return {
                url: 'https://p7t4hzl9rl.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.PROD_PDFGEN_X_API_KEY
            };
    }
}

export const backendUrlForUtilitiesStack = (environment) => {
    switch (environment){
        case 'localhost':
            return {
                url: 'http://localhost:3000/',
                key: process.env.UTILITY_X_API_KEY
            };
        default:
            return {
                url: 'https://r7d5uykoy2.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.UTILITY_X_API_KEY
            };
    }
}

export const backendUrlForAceUsersDB = (environment) => {
    switch (environment){
        case 'localhost':
            return {
                url: 'https://qzyjr68nhk.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_ACE_USERS_DB_KEY
            };
        case 'dev':
            return {
                url: 'https://qzyjr68nhk.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_ACE_USERS_DB_KEY
            };
        case 'test':
            return {
                url: 'https://jxjaw30uv5.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.TESTING_ACE_USERS_DB_KEY
            };
        default:
            return {
                url: 'https://19wmms03u9.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.PROD_ACE_USERS_DB_KEY
            };
    }
}


export const backendUrlKlaviyo = (environment) => {
    switch (environment){
        case 'localhost':
            return {
                url: 'http://localhost:3000/',
                key: process.env.DEV_KLAVIYO_X_API_KEY
            };
        case 'dev':
            return {
                url: 'https://7vgskekzui.execute-api.us-east-1.amazonaws.com/prod/',
                key: process.env.DEV_KLAVIYO_X_API_KEY
            };
        case 'test':
            return {
                url: 'https://v5ez5pj936.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.TEST_KLAVIYO_X_API_KEY
            };
        default:
            return {
                url: 'https://ijvupgelel.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.PROD_KLAVIYO_X_API_KEY
            };
    }
}


export const backendUrlForMetaConversionAPI = (environment) => {
    switch (environment){
        case 'localhost':
            return {
                url: 'https://0mzop1juh9.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_CONVERSION_API_KEY
            };
        case 'dev':
            return {
                url: 'https://0mzop1juh9.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_CONVERSION_API_KEY
            };
        case 'test':
            return {
                url: 'https://dy9aq4ubq5.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.TESTING_CONVERSION_API_KEY
            };
        default:
            return {
                url: 'https://7uwu6bnxtc.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.PROD_CONVERSION_API_KEY
            };
    }
}

export const backendUrlCTAMReporting = (environment) => {
    switch (environment) {
        case 'localhost':
            return {
                url: 'https://l803sulf06.execute-api.us-east-1.amazonaws.com/prod/send_to_sqs',
                key: process.env.DEV_CTAM_REPORTING_X_API_KEY
            }
        case 'dev':
            return {
                url: 'https://l803sulf06.execute-api.us-east-1.amazonaws.com/prod/send_to_sqs',
                key: process.env.DEV_CTAM_REPORTING_X_API_KEY
            };
        default:
            return {
                url: 'https://ws3xmo3y80.execute-api.us-east-1.amazonaws.com/prod/send_to_sqs',
                key: process.env.PROD_CTAM_REPORTING_X_API_KEY
            }
    }
};

export const backendUrlComplianceOrderSubmission = (environment) => {
    switch(environment) {
        case 'localhost':
            return {
                url: 'https://ij2l6hfd82.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_COMPLIANCE_ORDER_SUBMISSION_API_KEY
            }
        case 'dev':
            return {
                url: 'https://ij2l6hfd82.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.DEV_COMPLIANCE_ORDER_SUBMISSION_API_KEY
            };
        default:
            return {
                url: 'https://9q03dchcfk.execute-api.us-east-1.amazonaws.com/prod',
                key: process.env.PROD_COMPLIANCE_ORDER_SUBMISSION_API_KEY
            }
    }
}

export const backendUrlSesEmail = (environment) => {
    switch(environment) {
        case 'localhost':
            return {
                url: 'https://5nn8im961g.execute-api.us-east-1.amazonaws.com/prod/sendToSqs',
                key: process.env.PROD_SES_EMAIL_KEY
            }
        case 'dev':
            return {
                url: 'https://5nn8im961g.execute-api.us-east-1.amazonaws.com/prod/sendToSqs',
                key: process.env.PROD_SES_EMAIL_KEY
            };
        default:
            return {
                url: 'https://5nn8im961g.execute-api.us-east-1.amazonaws.com/prod/sendToSqs',
                key: process.env.PROD_SES_EMAIL_KEY
            }
    }
}