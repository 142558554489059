// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Props of the Component
const propTypes = {
    className: PropTypes.string,
};
const defaultProps = {
    className: '',
};

function FooterFCCUSAC({ className }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [footerText, setFooterText] = useState(<></>);
    const [footerImage, setFooterImage] = useState('');

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/FooterFCCUSAC/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/FooterFCCUSAC/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/FooterFCCUSAC.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/FooterFCCUSAC.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/FooterFCCUSAC/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/FooterFCCUSAC/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/FooterText.jsx`)
            .then((module) => {
                setFooterText(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/variables/FooterText.jsx`).then(
                    (module) => {
                        setFooterText(module.default);
                    }
                );
            });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/FooterFCCUSAC/fcc-usac.png`)
            .then((module) => {
                setFooterImage(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/FooterFCCUSAC/fcc-usac.png`).then(
                    (module) => {
                        setFooterImage(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <footer className={`${classNames.footer} ${className}`}>
            <img className={classNames.footerImage} src={footerImage} alt="FCC and USACS's Logo" />
            {React.cloneElement(footerText, { className: classNames.footerText, company: company })}
        </footer>
    );
}

FooterFCCUSAC.propTypes = propTypes;
FooterFCCUSAC.defaultProps = defaultProps;

export default FooterFCCUSAC;
