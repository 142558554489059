// Packages
import { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
// Contexts
import { StepsContext } from '../contexts/Steps';
import { FormDataContext } from '../contexts/FormData';
import { EnvironmentContext } from '../contexts/Environment';
import { ValidationContext } from '../contexts/Validation';
import { IsLoadingContext } from '../contexts/IsLoading';
import { ConfigContext } from '../contexts/Config';
// Freeconnect
import { baseFrontierSteps, optimumSteps } from '../companies/freeconnect/contexts/form/steps'

// Props of the Component
const propTypes = {
    page: PropTypes.string.isRequired,
    split: PropTypes.bool
};
const defaultProps = {
    split: false,
};

function SetUpContexts({ page, split }) {
    const { userId, company, pageNumber } = useParams();
    const [filledFormData, setFilledFormData] = useState(false);
    const [fromCompany, setFromCompany] = useState(false);
    const [paramCheck, setParamCheck] = useState(0);
    const [loadedSplitSteps, setLoadedSplitSteps] = useState(false);
    const [stepsState, stepsDispatch] = useContext(StepsContext);
    const [formDataState, formDataDispatch] = useContext(FormDataContext);
    const [environmentState] = useContext(EnvironmentContext);
    const [validationState, validationDispatch] = useContext(ValidationContext);
    const [configState, configDispatch] = useContext(ConfigContext); 
    const queryParameters = new URLSearchParams(location.search)
    const [IsLoadingState, isLoadingDispatch] = useContext(IsLoadingContext);

    useEffect(() => {
        isLoadingDispatch({ type: "ADD_PAGE_BLOCKER", payload: "setUpContextsValidaton" })
        isLoadingDispatch({ type: "ADD_PAGE_BLOCKER", payload: "setUpContextsSteps" })
        isLoadingDispatch({ type: "ADD_PAGE_BLOCKER", payload: "setUpContextsFormData" })
        isLoadingDispatch({ type: "ADD_PAGE_BLOCKER", payload: "setUpContextsConfig" })
    }, [])

    // useEffect(() => {
    //     if(!paramCheck && stepsState.steps.length > 0 && !IsLoadingState?.blockPage?.setUpContextsFormData){
    //         const fbclid = queryParameters.get("fbclid");
    //         if(fbclid){
    //             formDataDispatch({ type: 'SET_FBCLID', payload: fbclid });
    //         }
    //         setParamCheck(1);
    //     }
    // }, [queryParameters, stepsState.steps, IsLoadingState?.blockPage?.setUpContextsFormData])

    useEffect(() => {
        if (split && !loadedSplitSteps) {
            if (stepsState.steps.length > 0 && filledFormData) {
                const returnAmountToSubtractFromBarOnSplit = () => {
                    return { stepsAdded: [], stepsAddedThatDontMoveBar: 0 }
                }
                let { stepsAdded, stepsAddedThatDontMoveBar } = stepsState?.metaData?.returnAmountToSubtractFromBarOnSplit ? 
                    stepsState?.metaData?.returnAmountToSubtractFromBarOnSplit(formDataState, stepsDispatch, ) : 
                    returnAmountToSubtractFromBarOnSplit()

                const tempCurrentStep = Number(pageNumber) - 1
                const tempStepsArray = [ ...stepsState.steps, ...stepsAdded ]
                let amountToSubtract = tempCurrentStep
                
                if (stepsState?.metaData?.ProgressStepperStyle) {
                    amountToSubtract += stepsAddedThatDontMoveBar
                }

                for (let i = 0; i < tempCurrentStep; i++) {
                    if (tempStepsArray?.[i]?.dontMoveProgressBar) {
                        amountToSubtract -= 1
                    }
                }

                stepsDispatch({
                    type: 'ADD_X_TO_METADATA_SUBTRACTFROMSTEPSLENGTH',
                    payload: amountToSubtract
                });
                isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsSteps" })
                isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsFormData" })
                setLoadedSplitSteps(true)
            }
        }
    }, [stepsState, formDataState])

    useEffect(() => {
        if (Object.keys(validationState.fields).length > 0) {
            isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsValidaton" })
        }
    }, [validationState]);
    useEffect(() => {
        if (!split) {
            if (stepsState.steps.length > 0 || stepsState.steps?.steps?.length > 0) {
                isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsSteps" })
            }
        }
    }, [stepsState]);
    useEffect(() => {
        if (Object.keys(configState).length > 0) {
            isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsConfig" })
        }
    }, [configState]);
    useEffect(() => {
        if (Object.keys(formDataState).length > 0) {
            if ((
                // What pages to run below code on
                (
                page === 'edit' || 
                page === 'form' || 
                page === 'disclosures' || 
                page === 'disclosuressignature' || 
                (page === "lgnoname" && queryParameters.get("from")) || 
                (page === "leadgen" && queryParameters.get("from")) || 
                (page === "combochoice") || 
                (page === "combo" && queryParameters.get("userId"))
                )
                // Stops the below code from running multiple times
                && !filledFormData && !fromCompany && company !== 'redpocketai'
            )) {
                let headers = {}
                let tempFromCompany = false
                if ((page === "lgnoname" && queryParameters.get("from")) || (page === "leadgen" && queryParameters.get("from"))) {
                    headers = {
                        Authorization: queryParameters.get("from"),
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                    setFromCompany(true)
                    tempFromCompany = true
                } else {
                    headers = {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    }
                }
                const body = {
                    userId: userId ? userId : queryParameters.get("userId"),
                };
                fetch(environmentState.backendUrlToGetData, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(body),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        const tempFormData = { ...formDataState };

                        const fieldMapping = {
                            "mc_firstName": "firstName",
                            "mc_lastName": "lastName",
                            "display_dob": "dob",
                            "mc_dob": "formattedDob",
                            "mc_ssn": "ssn",
                            "tempAddress": "temporaryAddress",
                            "mc_phone": "phoneNumber",
                            "mc_email": "email",
                            "mc_address": "address",
                            "mc_addressLn2": "addressline2",
                            "full_address": "fullAddress",
                            "mc_city": "addresscity",
                            "mc_middleName": "middleName",
                            "mc_secondLastName": "secondLastName",
                            "mc_shippingAddress": "shippingAddress",
                            "mc_shippingAddressLn2": "shippingAddressline2",
                            "mc_shippingCity": "shippingAddresscity",
                            "mc_shippingState": "shippingAddressstate",
                            "mc_shippingZipCode": "shippingAddresszip",
                            "mc_state": "addressstate",
                            "mc_suffix": "suffix",
                            "bqp_first_name": "bqpFirstName",
                            "bqp_last_name": "bqpLastName",
                            "bqp_dob": "bqpDob",
                            "bqp_ssn": "bqpSsn",
                            "mc_tribal_bool": "isTribal",
                            "mc_zipCode": "addresszip",
                            "enrollment_type": "enrollment_type",
                            "clicked_activate_demo": "clickedActivateDemo",
                            "serviceableCompany": "serviceableCompany",
                            "is_existing_customer": "existingCustomer",
                            "optimum_skip": "optimum_skip",
                            "mc_benefitCode": "programCode",
                            "environment_code": "environmentCode",
                            "control_number": "controlNumber",
                            "quoteId": "quoteId",
                            "accountUuid": "accountUuid",
                            "freeform_address_times_at_page_1": "freeformAddressTimesAtPage1",
                            "freeform_address_times_at_page_2": "freeformAddressTimesAtPage2",
                            "freeform_address_times_at_page_3": "freeformAddressTimesAtPage3",
                            "from_widget": "fromWidget",
                            "fromIG": "fromIg",
                            "move_to_next_company": "moveToNextCompany",
                            "serviceable_companies": "serviceableCompanies",
                            "optimum_skip": "optimum_skip",
                            "liveVoxUserLeadPath": "liveVoxLeadPath",
                            "nv_application_id": "applicationId",
                            "add_to_fixed": "addToFixed",
                            "original_wireless_company": "originalWirelessCompany",
                            "enrollment_id": "enrollmentId",
                            "ChatURL": "chatURL",
                            "sponsor_id": "sponsor_id"
                        }

                        let addressObject = {}
                        let shippingAddressObject = {}

                        data.data.custom_fields.forEach((field) => {
                            const mappedField = fieldMapping[field.name]

                            if (mappedField) {
                                if (mappedField.startsWith("address")) {
                                    let parsedAddressKey = mappedField.split("address")[1]
                                    if (!parsedAddressKey) {
                                        parsedAddressKey = "street"
                                    }
                                    addressObject = { parsedAddress: {...addressObject?.parsedAddress, [parsedAddressKey]: field.value } }
                                } else if (mappedField.startsWith("shippingAddress")) {
                                    let parsedAddressKey = mappedField.split("shippingAddress")[1]
                                    if (!parsedAddressKey) {
                                        parsedAddressKey = "street"
                                    }
                                    shippingAddressObject = { parsedAddress: {...shippingAddressObject?.parsedAddress, [parsedAddressKey]: field.value } }
                                } else if (mappedField === "programCode") {
                                    tempFormData["programs"] = [{ program_code: field.value }]
                                } else {
                                    if (mappedField === "phoneNumber" && field.value.startsWith("+1")) {
                                        tempFormData[mappedField] = field.value.slice(2);
                                    } else {
                                        tempFormData[mappedField] = field.value;
                                    }
                                }
                            }
                        });

                        if (!addressObject?.parsedAddress?.street && addressObject?.parsedAddress?.zip) {
                            addressObject = { preferedZip: addressObject.parsedAddress.zip }
                        } else {
                            addressObject = { ...addressObject, preferedZip: addressObject?.parsedAddress?.zip }
                        }
                        if (!shippingAddressObject?.parsedAddress?.street && shippingAddressObject?.parsedAddress?.zip) {
                            shippingAddressObject = { preferedZip: shippingAddressObject.parsedAddress.zip }
                        } else if (shippingAddressObject?.parsedAddress?.street && shippingAddressObject?.parsedAddress?.zip) {
                            shippingAddressObject = { ...shippingAddressObject, preferedZip: addressObject.parsedAddress.zip }
                        } else {
                            shippingAddressObject = null
                        }

                        tempFormData.address = addressObject
                        tempFormData.shippingAddress = shippingAddressObject
                        if (tempFromCompany) {
                            tempFormData.fromCompany = queryParameters.get("from")
                        }
                        tempFormData.initialStartTime = moment(Date.now()).tz('America/Los_Angeles').format().replace('T', ' ').replace('-08:00', '.000').replace('-07:00', '.000')
                        // console.log("TEMP FORM DATA:", tempFormData)
                        formDataDispatch({ type: 'SET', payload: tempFormData });
                        setFilledFormData(true);
                        if (!split) {
                            isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsFormData" })
                        }
                        // const currentMomentTime = moment(Date.now()).tz('America/Los_Angeles').format().replace('T', ' ').replace('-08:00', '.000').replace('-07:00', '.000')
                        // formDataDispatch({
                        //     type: "SET_FORM_START_TIME",
                        //     payload: currentMomentTime
                        // })
                    })
                    .catch((error) => {
                        console.error(error);
                        if (!split) {
                            isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsFormData" })
                        }
                        setFilledFormData(true);
                    });
            } else if (page === "combo" && queryParameters.get("uuid") && !filledFormData) {
                let dbHeaders = {
                    Authorization: "optimum",
                    "X-Api-Key": environmentState.aceUsersDbApi.key
                }
                fetch(environmentState.aceUsersDbApi.url + "/get_user?tenant_id=optimum&tables=short_uuid&PK=" + queryParameters.get("uuid"), {
                    method: 'GET',
                    headers: dbHeaders
                })
                    .then((response) => response.json())
                    .then((data) => {
                        let acePk = data["data"]["short_uuid"]["ace_pk"]
                        let dbHeaders = {
                            Authorization: "optimum",
                            "X-Api-Key": environmentState.aceUsersDbApi.key
                        }
                        fetch(environmentState.aceUsersDbApi.url + "/get_user?tenant_id=optimum&tables=pii&PK=" + acePk, {
                            method: 'GET',
                            headers: dbHeaders
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                // const tempFormData = { ...formDataState };

                                const tempFormData = {
                                    "firstName": data.data.pii.first_name,
                                    "lastName": data.data.pii.last_name,
                                    "dob": data.data.pii.dob,
                                    "phoneNumber": data.data.pii.phone_number,
                                    "email": data.data.pii.email,
                                    "address": {
                                        "addressLine1": data.data.pii.address,
                                        "parsedAddress": {
                                            "city": data.data.pii.city, 
                                            "state": data.data.pii.state,
                                            "zip": data.data.pii.zipCode,
                                            "street": data.data.pii.address,
                                            "line2": data.data.pii.address_line2
                                        }
                                    },
                                    "middleName": data.data.pii.middle_name,
                                    "secondLastName": data.data.pii.second_last_name,
                                    "suffix": data.data.pii.name_suffix,
                                    "userSMSConsent": data.data.pii.sms_consent,
                                    "userMobileConsent": true,
                                    "shippingAddress": null
                                }

                                formDataDispatch({ type: 'SET', payload: tempFormData });
                                setFilledFormData(true);
                                if (!split) {
                                    isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsFormData" })
                                }
                            })
                    })
                    .catch((error) => {
                        console.error(error);
                        if (!split) {
                            isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsFormData" })
                        }
                        setFilledFormData(true);
                    })
            } else {
                if (!split) {
                    isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsFormData" })
                }
            }
        }
    }, [formDataState, filledFormData]);

    useEffect(() => {
        const asyncFunction = async () => {
            let setConfigValue = {}

            const headersConfig = {
                Authorization: company,
                "X-Api-Key": environmentState.backendUrlGetParameter.key
            }
            const bodyConfig = { parameterName:  `/Form/Config/${page}/${company}`}
            const configResponse = await fetch(environmentState.backendUrlGetParameter.url, {
                method: 'POST',
                headers: headersConfig,
                body: JSON.stringify(bodyConfig),
            })
            const configResponseObject = await configResponse.json()

            let configObject = {}
            if (configResponseObject?.message !== 'An error has ocurred') {
                const configParsed = JSON.parse(configResponseObject.parameterValue)
                configObject = configParsed
            } else {
                isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpContextsConfig" })
            }

            if (queryParameters.get("formId")){
                const headersFromIdParam = {
                    Authorization: company,
                    "X-Api-Key": environmentState.backendUrlGetParameter.key
                }
                const bodyFromIdParam = { parameterName:  `/Form/Config/LeadParameters/${queryParameters.get("formId")}`}
                const formIdParamStoreResponse = await fetch(environmentState.backendUrlGetParameter.url, {
                    method: 'POST',
                    headers: headersFromIdParam,
                    body: JSON.stringify(bodyFromIdParam),
                })
                const formIdParamStoreResponseObject = await formIdParamStoreResponse.json()
                const formIdParamStoreParsed = JSON.parse(formIdParamStoreResponseObject["parameterValue"])

                setConfigValue = { ...formIdParamStoreParsed, ...configObject }
            } else {
                setConfigValue = configObject
            }

            // if form version does NOT exist within the configValue retrieved from parameter store
            if(!setConfigValue?.formVersion){
                const bodyGlobalParam = { parameterName:  `/Form/Config/Globals`}
                const globalParamStoreResponse = await fetch(environmentState.backendUrlGetParameter.url, {
                    method: 'POST',
                    headers: headersConfig,
                    body: JSON.stringify(bodyGlobalParam),
                });
                const globalParamResponseObject = await globalParamStoreResponse.json();
                const globalParamParsed = JSON.parse(globalParamResponseObject["parameterValue"])
                setConfigValue.formVersion = globalParamParsed.formVersion
            }

            configDispatch({
                type: 'SET_CONFIG',
                payload: setConfigValue
            });

            import(/* webpackPreload: true */ `../companies/${company}/contexts/${page}/steps.jsx`)
                .then(async (module) => {
                    const companySteps = await module.default(company, setConfigValue)
                    if (Array.isArray(companySteps)) {
                        stepsDispatch({ type: 'SET_STEPS', payload: companySteps });
                    } else {
                        stepsDispatch({ type: 'SET_STEPS_AND_METADATA', payload: companySteps });
                    }
                })
                .catch((error) => {
                    console.error(error) 
                    import(/* webpackPreload: true */ `../companies/default/contexts/${page}/steps.jsx`).then(async (module) => {
                        // stepsDispatch({ type: 'SET_STEPS', payload: await module.default(company) });
                        const companySteps = await module.default(company, setConfigValue)
                        if (Array.isArray(companySteps)) {
                            stepsDispatch({ type: 'SET_STEPS', payload: companySteps });
                        } else {
                            stepsDispatch({ type: 'SET_STEPS_AND_METADATA', payload: companySteps });
                        }
                    })
                });
            import(/* webpackPreload: true */ `../companies/${company}/contexts/${page}/formData.js`)
                .then(async (module) => {
                    const initFormData = await module.default(company)
                    initFormData.initialStartTime = moment(Date.now()).tz('America/Los_Angeles').format().replace('T', ' ').replace('-08:00', '.000').replace('-07:00', '.000')
                    formDataDispatch({ type: 'SET', payload: initFormData });
                })
                .catch(() => {
                    import(/* webpackPreload: true */ `../companies/default/contexts/${page}/formData.js`).then(async (module) => {
                        const initFormData = await module.default(company)
                        initFormData.initialStartTime = moment(Date.now()).tz('America/Los_Angeles').format().replace('T', ' ').replace('-08:00', '.000').replace('-07:00', '.000')
                        formDataDispatch({ type: 'SET', payload: initFormData });
                    });
                });
            import(/* webpackPreload: true */ `../companies/${company}/contexts/${page}/validation.js`)
                .then(async (module) => {
                    validationDispatch({ type: 'SET_FIELDS', payload: await module.default(company) });
                })
                .catch(() => {
                    import(/* webpackPreload: true */ `../companies/default/contexts/${page}/validation.js`).then(
                        async (module) => {
                            validationDispatch({ type: 'SET_FIELDS', payload: await module.default(company) });
                        }
                    );
                });
        }
        if (environmentState.backendUrlGetParameter?.url) {
            asyncFunction()
        }
    }, [company, page, environmentState.backendUrlGetParameter]);

    return '';
}

SetUpContexts.propTypes = propTypes;
SetUpContexts.defaultProps = defaultProps;

export default SetUpContexts;
