export const hardcodedPrograms = [
    {
        program_code: "SNAP",
        abbreviation: "Food Stamps",
        program_name: "Food Stamps",
        code: "100004"
    },
    {
        program_code: "MEDIC",
        abbreviation: "Medical Assistance",
        program_name: "Medical Assistance",
        code: "100001"
    },
    {
        program_code: "SSI",
        abbreviation: "Supplemental Security Income",
        program_name: "Supplemental Security Income",
        code: "100006"
    },
    {
        program_code: "135P",
        abbreviation: "Household Income",
        program_name: "Household Income",
        code: "100002"
    },
    {
        program_code: "FPHA",
        abbreviation: "Federal Public Housing Assistance",
        program_name: "Federal Public Housing Assistance",
        code: "100000"
    },
    {
        program_code: "VETERAN'S PENSION",
        abbreviation: "Veteran's Pension Assistance",
        program_name: "Veteran's Pension Assistance",
        code: "100014"
    },
    {
        program_code: "TTANF",
        abbreviation: "Tribally-Administered Temporary Assistance for Needy Families",
        program_name: "Tribally-Administered Temporary Assistance for Needy Families",
        code: "100008"
    },
    {
        program_code: "HEAD START",
        abbreviation: "Tribally-Administered Head Start",
        program_name: "Tribally-Administered Head Start",
        code: "100009"
    },
    {
        program_code: "FDPIR",
        abbreviation: "Food Distribution Program on Indian Reservations",
        program_name: "Food Distribution Program on Indian Reservations",
        code: "100010"
    },
    {
        program_code: "BIAGA",
        abbreviation: "Bureau of Indian Affairs General Assistance",
        program_name: "Bureau of Indian Affairs General Assistance",
        code: "100011"
    },
    {
        program_code: "PELL",
        abbreviation: "Federal Pell Grant",
        program_name: "Federal Pell Grant",
        code: "110001"
    },
]

export const freeconnectPrograms = [
    {
        program_code: "MEDIC", 
        program_name: "Medicaid"
    }, 
    {
        program_code: "SNAP", 
        program_name: "Supplemental Nutrition Assistance Program (SNAP) Food Stamps"
    }, 
    {
        program_code: "SSI", 
        program_name: "Supplemental Security Income (SSI)"
    }, 
    {
        program_code: "FPH", 
        program_name: "Federal Public Housing Assistance"
    }, 
    {
        program_code: "VPSBP", 
        program_name: "Veterans Pension and Survivors Benefit Program"
    }, 
    {
        program_code: "BIAGA",
        program_name: "Bureau of Indian Affairs (BIA) General Assistance"
    },
    {
        program_code: "TATAN",
        program_name: "Tribal Temporary Assistance for Needy Families (Tribal TANF)"
    },
    {
        program_code: "TFDP",
        program_name: "Food Distribution Program on Indian Reservations (FDPIR)"
    },
    {
        program_code: "HEADS",
        program_name: "Tribal Head Start (only households that meet the income qualifying standard)"
    },
    {
        program_code: "WIC", 
        program_name: "Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)"
    }, 
    {
        program_code: "FPG", 
        program_name: "Federal Pell Grant"
    }, 
    {
        program_code: "NSL", 
        program_name: "School Lunch/Breakfast Program"
    }
]