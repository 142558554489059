// Packages
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender';
// UI
import Button from '../../../ui/Button';
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';
import { ValidationContext } from '../../../contexts/Validation';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function CheckPrograms({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [noMatch, setNoMatch] = useState(false);
    const [formDataState, formDataDispatch] = useContext(FormDataContext);
    const [isLoading, isLoadingDispatch] = useContext(IsLoadingContext);
    const [steps, stepsDispatch] = useContext(StepsContext);
    const [validation, validationDispatch] = useContext(ValidationContext);
    const environmentState = useContext(EnvironmentContext)[0];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Programs/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/Programs/index.less`);
        });
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Programs/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../../default/styles/steps/Programs/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        isLoadingDispatch({ type: "ADD_INPUT_BLOCKER", payload: "LoadPrograms" })

        const asyncFunction = async () => {     
            if (formDataState.serviceableCompany === 'optimum' || formDataState.serviceableCompany === 'windstream' || formDataState.serviceableCompany === 'frontier') {
                isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "LoadPrograms" })
                onNext()
            } else {
                const mcData = await fetch(environmentState.backendUrlToGetData, {
                    method: 'POST',
                    headers: {
                        Authorization: company,
                        "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                    },
                    body: JSON.stringify({
                        userId: userId,
                    }),
                })
                const mcDataObject = await mcData.json()
    
                let serviceableCompanies = []
    
                mcDataObject.data.custom_fields.every((field) => {
                    if (field.name === "serviceable_companies") {
                        serviceableCompanies = field.value
                        return false
                    } else {
                        return true
                    }
                });

                let foundProgramMatch = false

                for (let i = 0; i < serviceableCompanies.length; i += 1) {
                    const programsData = await fetch(environmentState.vcareUrl, {
                        method: 'POST',
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            Abbreviation: serviceableCompanies[i],
                            zip_code: formDataState.address?.parsedAddress.zip,
                            is_tribal: formDataState.isTribal ? formDataState.isTribal : 'N',
                            enrollment_type: formDataState.enrollment_type ? (formDataState.enrollment_type !== "ACP,LIFELINE,COMBO" ? formDataState.enrollment_type : "ACP") : "ACP",
                            Call: "get_programs",
                        }),
                    })
                    const programsDataObject = await programsData.json()
    
                    if (programsDataObject.some(program => program.program_code === formDataState.programs[0].program_code)) {
                        foundProgramMatch = true
                        formDataDispatch({
                            type: 'SET_SERVICEABLE_COMPANY',
                            payload: serviceableCompanies[i],
                        });
                        const headers = {
                            Authorization: company,
                            "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                        }
                        if (serviceableCompanies[i] !== formDataState.serviceableCompany) {
                            fetch(environmentState.backendUrlToSetData, {
                                method: 'POST',
                                headers,
                                body: JSON.stringify({ "serviceableCompany": serviceableCompanies[i], userId }),
                            })
                        }
                        break
                    } else {
                        continue
                    }
                }
                
                if (foundProgramMatch) {
                    isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "LoadPrograms" })
                    onNext()
                } else {
                    setNoMatch(true)
                    isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "LoadPrograms" })
                }
            }  
        }

        asyncFunction()
    }, []);

    return (
        <ConditionalRender condition={noMatch}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', flexGrow: '1' }}>
            <div>
                <HeadingText>Whoops!</HeadingText>
                <SubHeadingText>Looks like we weren’t able to find you a match using the address and benefit information that you provided. Click the button below to try again with a different address or benefit program.</SubHeadingText>
            </div>
            <div className={classNames.bottom}>
                <Button
                    style="loneNext"
                    onClick={() => { 
                        formDataDispatch({
                            type: 'CLEAR_PROGRAM',
                        });
                        formDataDispatch({
                            type: 'CLEAR_ADDRESS',
                        });
                        formDataDispatch({
                            type: 'CLEAR_TEMPORARY_ADDRESS',
                        });
                        formDataDispatch({
                            type: 'SET_SHIPPING_ADDRESS_NULL',
                        });
                        validationDispatch({
                            type: 'SET_PROGRAMS_ISVALID',
                            payload: false
                        })
                        validationDispatch({
                            type: 'SET_ADDRESSOBJECT_ISVALID',
                            payload: false
                        })
                        validationDispatch({
                            type: 'SET_SHIPPINGADDRESSOBJECT_ISVALID',
                            payload: true
                        })
                        stepsDispatch({
                            type: 'SET_CURRENT_STEP',
                            payload: 0
                        })
                    }}>Try Again</Button>
                    
            </div>
        </div>
        </ConditionalRender>
    );
}

CheckPrograms.propTypes = propTypes;
CheckPrograms.defaultProps = defaultProps;

export default CheckPrograms;
