// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// UI
import BodyText from './BodyText';

// Props of the Component
const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
const defaultProps = {
    className: ""
};

function Card({ children, className }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Card/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Card/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Card.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Card.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Card/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Card/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <div className={`${classNames.card} ${className}`}>
            {children}
        </div>
    )
}

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
