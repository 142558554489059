// Packages
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// UI
import AddressField from '../../../ui/AddressField';
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
// Helpers
import CheckBox from '../../../ui/CheckBox';
import ConditionalRender from '../../../components/ConditionalRender';
import {
    runMultipleValidationFunctions,
    requireNonEmptyValue,
    requireNotPoBox,
    requireValidAddress,
    requireResidentialAddress,
    requireAddressLine2IfAddressHasUnits,
    requireValidAddressLine2
} from '../../../helpers/validation';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { ValidationContext } from '../../../contexts/Validation';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function FrontierPreviousAddress({ step, mode, onNext, onPrev }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [formData, formDataDispatch] = useContext(FormDataContext);

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Address/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/Address/index.less`);
        });
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Address/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/Address/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <div className={classNames.centerItems}>
            <HeadingText>Previous Address</HeadingText>
            <SubHeadingText>We need some additional information to confirm your eligibility. What was your address prior to your current residence?</SubHeadingText>
            <AddressField
                subLabelText="(Cannot be a PO Box)"
                addAsterisk={true}
                step={step}
                initialValue={formData.previousAddress}
                validationKey="previousAddressObject"
                validationFunction={(value) =>
                    runMultipleValidationFunctions(value, [
                        requireNotPoBox,
                        requireValidAddress,
                        requireResidentialAddress,
                        requireAddressLine2IfAddressHasUnits,
                        requireValidAddressLine2
                    ])
                }
                line1ValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                cityValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                stateValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                zipValidationFunction={(value) => runMultipleValidationFunctions(value, [requireNonEmptyValue])}
                onChange={(addressObject) => {
                    formDataDispatch({
                        type: 'SET_PREVIOUS_ADDRESS',
                        payload: addressObject,
                    });
                }}
            >
                Home Address
            </AddressField>
        </div>
    );
}

FrontierPreviousAddress.propTypes = propTypes;
FrontierPreviousAddress.defaultProps = defaultProps;

export default FrontierPreviousAddress;
