const classNames = { 
    pricesContainer: 'offer-text-prices-container',
    strike: 'offer-text-strike',
    container: 'offer-text-container',
    bold: 'offer-text-bold',
    checkbox: 'offer-text-checkbox',
    checkboxActive: 'offer-text-checkbox-active',
    checkmark: 'offer-text-checkmark',
    month: 'offer-text-month',
    realValueContainer: 'offer-text-real-value-container'
};

export default classNames;
