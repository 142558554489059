// Packages
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender';
// UI
import TextField from '../../../ui/TextField';
import HeadingText from '../../../ui/HeadingText';
import DatePicker from '../../../ui/DatePicker';
import CheckBox from '../../../ui/CheckBox';
import DropDown from '../../../ui/DropDown';
import SubHeadingText from '../../../ui/SubHeadingText';
import LegalText from '../../../ui/LegalText';
// Helpers
import {
    runMultipleValidationFunctions,
    requireNonEmptyValue,
    requireOnlyValidNLADCharacters,
    requireOnlyNumbers,
    requireXCharacters,
    requireDatePicker,
    requireAgeToBeGreaterThan18,
    requireMoreThanXCharacters,
    requireNotToBeAllSpaces
} from '../../../helpers/validation';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { ValidationContext } from '../../../contexts/Validation';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function PII({ step, mode, onNext, onPrev }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [hasSecondLastName, setHasSecondLastName] = useState(formData.secondLastName ? true : false);
    const [isBqp, setIsBqp] = useState(formData.bqp ? true : false);
    const validationDispatch = useContext(ValidationContext)[1];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/PII/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/PII/index.less`);
        });
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/PII/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/PII/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        if (formData.firstName) {
            validationDispatch({ type: 'RELOAD_VALIDATION' });
        }
    }, []);

    return (
        <div className={classNames.centerItems}>
            <HeadingText>Your Information</HeadingText>
            <SubHeadingText>
                {
                    company === "gotech" || company == "gotechacp" ? "Tell us a little bit about yourself so we can get you signed up for service." : "Tell us a little bit about yourself so we can submit your application for approval. This won’t affect your credit."
                }
            </SubHeadingText>
            <TextField
                name="First Name"
                step={step}
                validationKey="firstName"
                validationFunction={(value) =>
                    runMultipleValidationFunctions(value, [requireNonEmptyValue, requireOnlyValidNLADCharacters, () => { return requireMoreThanXCharacters(value, 2, "letters") }, requireNotToBeAllSpaces])
                }
                placeHolder="John"
                initialValue={formData.firstName}
                onChange={(value) => {
                    formDataDispatch({
                        type: 'SET_FIRST_NAME',
                        payload: value,
                    });
                }}
            >Legal First Name*</TextField>
            <TextField
                name="Middle Name"
                step={step}
                validationKey="middleName"
                validationFunction={(value) => runMultipleValidationFunctions(value, [requireOnlyValidNLADCharacters, requireNotToBeAllSpaces])}
                initialValue={formData.middleName}
                placeHolder="Lee"
                onChange={(value) => {
                    formDataDispatch({
                        type: 'SET_MIDDLE_NAME',
                        payload: value,
                    });
                }}
            >Middle Name</TextField>
            <TextField
                name="Last Name"
                step={step}
                validationKey="lastName"
                validationFunction={(value) =>
                    runMultipleValidationFunctions(value, [requireNonEmptyValue, requireOnlyValidNLADCharacters, () => { return requireMoreThanXCharacters(value, 2, "letters") }, requireNotToBeAllSpaces])
                }
                initialValue={formData.lastName}
                placeHolder="Smith"
                onChange={(value) => {
                    formDataDispatch({
                        type: 'SET_LAST_NAME',
                        payload: value,
                    });
                }}
            >Last Name*</TextField>
            <CheckBox
                name="Has Second Last Name"
                initialCheck={formData.secondLastName}
                onChecked={(checked) => {
                    setHasSecondLastName(checked);
                    if (!checked) {
                        formDataDispatch({
                            type: 'SET_SECOND_LAST_NAME',
                            payload: "",
                        });
                    }
                }}
            >
                I have a second last name
            </CheckBox>
            <ConditionalRender condition={hasSecondLastName || formData.secondLastName}>
                <TextField
                    name="Second Last Name"
                    step={step}
                    validationKey="secondLastName"
                    validationFunction={(value) => {
                        if (value) {
                            return runMultipleValidationFunctions(value, [requireOnlyValidNLADCharacters, requireNotToBeAllSpaces, () => { return requireMoreThanXCharacters(value, 2, "letters") }])
                        } else {
                            return { isValid: true, message: '' }
                        }
                    }}
                    initialValue={formData.secondLastName}
                    placeHolder="Doe"
                    onChange={(value) => {
                        formDataDispatch({
                            type: 'SET_SECOND_LAST_NAME',
                            payload: value,
                        });
                    }}
                >Second Last Name</TextField>
            </ConditionalRender>
            <DropDown
                options={[
                    { name: 'Suffix', value: '' },
                    { name: 'Jr', value: 'Jr.' },
                    { name: 'Sr', value: 'Sr.' },
                    { name: 'II', value: 'II' },
                    { name: 'III', value: 'III' },
                    { name: 'IV', value: 'IV' },
                    { name: 'V', value: 'V' },
                ]}
                name="Suffix"
                initialValue={formData.suffix}
                onChange={(value) => {
                    formDataDispatch({
                        type: 'SET_SUFFIX',
                        payload: value,
                    });
                }}
            >Suffix</DropDown>
            <DatePicker
                name="Birthday"
                step={step}
                validationKey="dob"
                validationFunction={(value) => runMultipleValidationFunctions(value, [requireDatePicker, requireAgeToBeGreaterThan18])}
                initialValue={formData.dob}
                onChange={(value) => {
                    formDataDispatch({
                        type: 'SET_DOB',
                        payload: value,
                    });
                }}
            >
                Birthday*
            </DatePicker>
            <TextField
                name="Last 4 digits of your SSN"
                subLabel={"(We only use this to confirm your eligibility)"}
                step={step}
                type="tel"
                showBadge
                validationKey="ssn"
                validationFunction={(value) =>
                    runMultipleValidationFunctions(value, [
                        requireNonEmptyValue,
                        requireOnlyNumbers,
                        (ssn) => requireXCharacters(ssn, 4),
                    ])
                }
                initialValue={formData.ssn}
                placeHolder="0000"
                onChange={(value) => {
                    formDataDispatch({
                        type: 'SET_SSN',
                        payload: value,
                    });
                }}
                popUpTitle="How This Helps"
                popUpContent="Providing the last four digits of your SSN helps us verify your identity for benefit approval. Your data is confidential and will only be used to find out if you qualify."
            >
                Last 4 digits of your SSN*
            </TextField>
            <TextField
                name="Phone Number"
                step={step}
                phoneNumber
                validationKey="phoneNumber"
                validationFunction={(value) =>
                    runMultipleValidationFunctions(value, [
                        requireNonEmptyValue,
                        requireOnlyNumbers,
                        (phoneNumber) => requireXCharacters(phoneNumber, 10),
                    ])
                }
                initialValue={formData.phoneNumber}
                placeHolder="555-555-5555"
                onChange={(value) => {
                    formDataDispatch({
                        type: 'SET_PHONE_NAME',
                        payload: value,
                    });
                }}
            >
                Phone Number*
            </TextField>
        </div>
    );
}

PII.propTypes = propTypes;
PII.defaultProps = defaultProps;

export default PII;
