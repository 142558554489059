// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import Bold from "./Bold"
import SuperHeadingText from './SuperHeadingText'
import LabelText from './LabelText';
import SubHeadingText from './SubHeadingText';
import LegalText from './LegalText';

// Props of the Component
const propTypes = {
    title: PropTypes.string,
    realValue: PropTypes.string,
    fakeValue: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool
};
const defaultProps = {
    title: "",
    realValue: "",
    fakeValue: "",
    description: "",
    active: false
};

function OfferText({ title, realValue, fakeValue, description, active }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/OfferText/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/OfferText/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/OfferText.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/OfferText.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/OfferText/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/OfferText/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <div className={classNames.container}>
            <div style={{ display: "flex", gap: "var(--mobile-green-spacing)" }}>
                <div className={active ? `${classNames.checkbox} ${classNames.checkboxActive}` : classNames.checkbox}>
                    <ConditionalRender condition={active}>
                        <div className={classNames.checkmark}></div>
                    </ConditionalRender>
                </div>
                <Bold className={classNames.bold}>{title === "Internet" ? title : title.replace(new RegExp("Internet"), "")}</Bold>
            </div>
            <div className={classNames.pricesContainer}>
                <LabelText className={classNames.strike}>{fakeValue}</LabelText>
                <div className={classNames.realValueContainer}>
                    <SuperHeadingText>{realValue}</SuperHeadingText><LegalText className={classNames.month}>/mo.</LegalText>
                </div>
            </div>
        </div>
    );
}

OfferText.propTypes = propTypes;
OfferText.defaultProps = defaultProps;

export default OfferText;
