// Packages
import { useEffect, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
// Contexts
import { QueryParamsContext } from '../contexts/QueryParams'

function SetUpQueryParameters() {
    // Contexts
    const [, queryParamsDispatch] = useContext(QueryParamsContext)
    // Query Params
    const location = useLocation()
    const queryParameters = useMemo(() => new URLSearchParams(location.search), [location.search])

    useEffect(() => {
        // console.log("QUERY PARAMS:", queryParameters)
        const tempQueryParams = {}
        queryParameters.forEach((value, key) => {
            tempQueryParams[key] = value
        })
        queryParamsDispatch({ type: 'SET', payload: tempQueryParams })
        // initialLoadBlockersDispatch({ type: 'SET_IS_QUERY_PARAMS_LOADED', payload: true })
        // isLoadingDispatch({ type: "ADD_PAGE_BLOCKER", payload: "setUpContextsValidaton" })
    }, [ queryParameters, queryParamsDispatch])

    return null
}

export default SetUpQueryParameters