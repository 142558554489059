// Packages
import React from 'react'
// Helpers
import initialState from './initialState';

const PopupReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_POPUP':
            const popupPayload = { 
                display: true,
                title: action.payload.title,
                onClick: action.payload.onClick,
                componentToRender: action.payload.component
            };
            if("popupName" in action.payload){
                popupPayload.popupName = action.payload.popupName
                popupPayload.timePopupStart = Date.now()
            }
            return popupPayload
        case 'REMOVE_POPUP':
            const removePopupState = {     
                display: false,
                componentToRender: <></>
            };
            if(state?.popupName){
                removePopupState.popupTimeEnd = Date.now()
                removePopupState.popupTimeElapsed = removePopupState.popupTimeEnd - state.timePopupStart
                removePopupState.lastDisplayedPopup = state.popupName
            }
            return removePopupState;
        case 'CLEAR_POPUP':
            return initialState;
        default:
            return state;
    }
};

export default PopupReducer;
