export const usState = [
    /** Empty Value */
    { name: 'ST', value: '' },
    /** Alaska */
    { name: 'AK', value: 'AK' },
    /** Alabama */
    { name: 'AL', value: 'AL' },
    /** Arkansas */
    { name: 'AR', value: 'AR' },
    // /** American Samoa */
    // { name: 'AS', value: 'AS' },
    /** Arizona */
    { name: 'AZ', value: 'AZ' },
    /** California */
    { name: 'CA', value: 'CA' },
    /** Colorado */
    { name: 'CO', value: 'CO' },
    /** Connecticut */
    { name: 'CT', value: 'CT' },
    /** District Of Columbia */
    { name: 'DC', value: 'DC' },
    /** Delaware */
    { name: 'DE', value: 'DE' },
    /** Florida */
    { name: 'FL', value: 'FL' },
    // /** Federated States Of Micronesia */
    // { name: 'FM', value: 'FM' },
    /** Georgia */
    { name: 'GA', value: 'GA' },
    // /** Guam */
    // { name: 'GU', value: 'GU' },
    /** Hawaii */
    { name: 'HI', value: 'HI' },
    /** Iowa */
    { name: 'IA', value: 'IA' },
    /** Idaho */
    { name: 'ID', value: 'ID' },
    /** Illinois */
    { name: 'IL', value: 'IL' },
    /** Indiana */
    { name: 'IN', value: 'IN' },
    /** Kansas */
    { name: 'KS', value: 'KS' },
    /** Kentucky */
    { name: 'KY', value: 'KY' },
    /** Louisiana */
    { name: 'LA', value: 'LA' },
    /** Massachusetts */
    { name: 'MA', value: 'MA' },
    /** Maryland */
    { name: 'MD', value: 'MD' },
    /** Maine */
    { name: 'ME', value: 'ME' },
    // /** Marshall Islands */
    // { name: 'MH', value: 'MH' },
    /** Michigan */
    { name: 'MI', value: 'MI' },
    /** Minnesota */
    { name: 'MN', value: 'MN' },
    /** Missouri */
    { name: 'MO', value: 'MO' },
    // /** Northern Mariana Islands */
    // { name: 'MP', value: 'MP' },
    /** Mississippi */
    { name: 'MS', value: 'MS' },
    /** Montana */
    { name: 'MT', value: 'MT' },
    /** North Carolina */
    { name: 'NC', value: 'NC' },
    /** North Dakota */
    { name: 'ND', value: 'ND' },
    /** Nebraska */
    { name: 'NE', value: 'NE' },
    /** New Hampshire */
    { name: 'NH', value: 'NH' },
    /** New Jersey */
    { name: 'NJ', value: 'NJ' },
    /** New Mexico */
    { name: 'NM', value: 'NM' },
    /** Nevada */
    { name: 'NV', value: 'NV' },
    /** New York */
    { name: 'NY', value: 'NY' },
    /** Ohio */
    { name: 'OH', value: 'OH' },
    /** Oklahoma */
    { name: 'OK', value: 'OK' },
    /** Oregon */
    { name: 'OR', value: 'OR' },
    /** Pennsylvania */
    { name: 'PA', value: 'PA' },
    /** Puerto Rico */
    { name: 'PR', value: 'PR' },
    // /** Palau */
    // { name: 'PW', value: 'PW' },
    /** Rhode Island */
    { name: 'RI', value: 'RI' },
    /** South Carolina */
    { name: 'SC', value: 'SC' },
    /** South Dakota */
    { name: 'SD', value: 'SD' },
    /** Tennessee */
    { name: 'TN', value: 'TN' },
    /** Texas */
    { name: 'TX', value: 'TX' },
    /** Utah */
    { name: 'UT', value: 'UT' },
    /** Virginia */
    { name: 'VA', value: 'VA' },
    // /** Virgin Islands */
    // { name: 'VI', value: 'VI' },
    /** Vermont */
    { name: 'VT', value: 'VT' },
    /** Washington */
    { name: 'WA', value: 'WA' },
    /** Wisconsin */
    { name: 'WI', value: 'WI' },
    /** West Virginia */
    { name: 'WV', value: 'WV' },
    /** Wyoming */
    { name: 'WY', value: 'WY' },
]