const classNames = { 
    card: 'offer-card-card',
    buttonMargins: 'offer-card-button-margins',
    label: 'offer-card-label',
    labelPurple: 'offer-card-label-purple',
    purple: 'offer-card-purple',
    labelGreen: 'offer-card-label-green',
    activeCard: 'offer-card-active',
    checkmark: "offer-card-checkmark",
    labelMargin: "offer-card-label-margin",
    descriptionContainer: "offer-card-description-container",
    description: "offer-card-description"
};

export default classNames;
