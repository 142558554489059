// Packages
import {React, useEffect} from 'react';
// Components
import Routers from './components/Routers';
// CSS
import 'normalize.css';

function App() {
    useEffect(() => {
        window.location.href = "https://freeconnect.wifiapply.com/form?instanceId=295720"
    }, [])
    return
    // return <Routers />;
}

export default App;
