// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender'
// UI
import SubHeadingText from '../../../ui/SubHeadingText'
import HeadingText from '../../../ui/HeadingText';
import Bold from '../../../ui/Bold';
import Link from '../../../ui/Link';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';


// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function FrontierPositiveId({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [startedTimeout, setStartedTimeout] = useState(false);
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [isLoading, isLoadingDispatch] = useContext(IsLoadingContext);
    const [steps, stepsDispatch] = useContext(StepsContext);
    const environment = useContext(EnvironmentContext)[0];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierPositiveId/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierPositiveId/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    useEffect(() => {
        classNames.lastBold
    }, [classNames])

    return (
        <>
            <HeadingText>Additional Verification</HeadingText>
            <SubHeadingText className={classNames.subHeader} >It looks like we need to confirm some of your information before we move forward. Follow the directions below to finish your application.</SubHeadingText>
            <Bold>1. Click the link and download the Positive Identification Form found at the bottom of the page: <Link>https://frontier.com/discount-programs/positiveid</Link></Bold>
            <br />
            <Bold>2. Fill out the form using a PDF editor, or print out the form and fill in your information. To complete the form, you will need the following quote #: {formData.quoteId}</Bold>
            <br />
            <Bold>3. Email an image, screenshot, or the full PDF to CustomerID@ftr.com</Bold>
            <br />
            <Bold className={classNames.lastBold}>4. We will email you a response within 48 hours once we've confirmed your info.</Bold>
            <SubHeadingText>You can close out of this page once you've completed the above steps, or click “I'm All Done” to return to Messenger.</SubHeadingText>
        </>
    )
}

FrontierPositiveId.propTypes = propTypes;
FrontierPositiveId.defaultProps = defaultProps;

export default FrontierPositiveId;