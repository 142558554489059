// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string
};
const defaultProps = {
    href: '',
    onClick: () => {},
    target: "_blank"
};

function Link({ children, href, onClick, target, className }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Link/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Link/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Link.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Link.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Link/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Link/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    if (!href) {
        return (
            <a className={`${classNames.link} ${className}`} onClick={onClick} target={target}>
                {children}
            </a>
        );
    } else {
        return (
            <a className={`${classNames.link} ${className}`} onClick={onClick} href={href} target={target}>
                {children}
            </a>
        );
    }
}

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
