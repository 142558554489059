// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// Components
import ConditionalRender from "../components/ConditionalRender"
// UI
import OfferText from "./OfferText"
import Button from './Button';
import SubHeadingText from './SubHeadingText';
import Bold from './Bold';

// Props of the Component
const propTypes = {
    title: PropTypes.string,
    realValue: PropTypes.string,
    fakeValue: PropTypes.string,
    description: PropTypes.array,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    onCardClick: PropTypes.func,
    label: PropTypes.string,
    labelColor: PropTypes.string
};
const defaultProps = {
    title: "",
    realValue: "",
    fakeValue: "",
    description: [],
    onClick: () => {},
    active: false,
    onCardClick: () => {},
    label: null,
    labelColor: ""
};

function OfferCard({ title, realValue, fakeValue, description, onClick, active, onCardClick, label, labelColor }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/OfferCard/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/OfferCard/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/OfferCard.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/OfferCard.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/OfferCard/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/OfferCard/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <div style={{ position: "relative" }} className={`${classNames.card} ${active ? classNames.activeCard : ''} ${labelColor ? classNames.labelMargin : ''} ${labelColor === "purple" ? classNames.purple : ''}`} onClick={() => { onCardClick() }}>
            <ConditionalRender condition={label}>
                <Bold className={`${classNames.label} ${labelColor === "purple" ? classNames.labelPurple : (labelColor === "green" ? classNames.labelGreen : "")}`}>
                    {label}
                </Bold>
            </ConditionalRender>
            <OfferText 
                title={title}
                realValue={realValue}
                fakeValue={fakeValue}
                description={description}
                active={active}
            />
            <ConditionalRender condition={active}>
                <div className={classNames.descriptionContainer}>
                    <div>
                        {
                            description.map((text, i) => {
                                return (
                                    <div className={classNames.description}>
                                        <div className={classNames.checkmark}></div>
                                        <SubHeadingText key={i}>{text}</SubHeadingText>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <Button className={classNames.buttonMargins} style="nextNoBottomMargin" onClick={() => { onClick() }}>Select</Button>
                </div>
            </ConditionalRender>
        </div>
    );
}

OfferCard.propTypes = propTypes;
OfferCard.defaultProps = defaultProps;

export default OfferCard;
