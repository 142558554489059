const classNames = {
    filled: 'filled-button',
    outlined: 'outlined-button',
    next: 'filled-button next-button',
    loneNext: 'filled-button lone-next-button',
    loneCall: 'outlined-button lone-call-button',
    loneCallRed: 'filled-button lone-call-button-red',
    loneCallMaxWidth: 'outlined-button lone-call-button-max-width',
    loneCallRedFullWidth: 'filled-button lone-call-button-red-full-width',
    previous: 'outlined-button previous-button',
    nextNoBottomMargin: 'filled-button next-button-no-bottom-margin',
    disclosuresPrevious: 'outlined-button disclosures-previous-button',
    disclosuresNext: 'filled-button disclosures-next-button',
    halfOutlined: "outlined-button button-half-width",
    singleDisclosuresNext: "filled-button single-disclosures-next-button",
    fiftyPercentFilled: 'fifty-button filled-button',
    fiftyPercentOutlined: 'fifty-button outlined-button',
    welcomePage: "welcome-button",
    underlined: "underlined-button",
    label: {
        div: 'label-button-div',
        label: 'label-button-label',
        button: 'label-button-button outlined-button'
    },
};

export default classNames;