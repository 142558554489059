// Packages
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import StyleDisclosuresText from '../../../components/StyleDisclosuresText';
// UI
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
import LoadingBlocker from '../../../ui/LoadingBlocker';
import DisclosuresContainer from '../../../ui/DisclosuresContainer';
import DisclosuresSection from '../../../ui/DisclosuresSection';
// Helpers
import CheckBox from '../../../ui/CheckBox';
import ConditionalRender from '../../../components/ConditionalRender';
import { requiredCheckbox } from '../../../helpers/validation';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import Loading from '../../../ui/Loading';
import { data } from 'autoprefixer';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    disableHead: PropTypes.bool,
    disableCheck: PropTypes.bool
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
    disableHead: false,
    disableCheck: false
};
    
const frontierAdditionalDisclosures = [
    {
        "Sequence": 0,
        "Type": "CheckBox",
        "Message": `The ACP is a government program to help qualify households afford the internet they need for work, school, healthcare and more. With your ACP credit, your internet service is discounted up to $30 per month or up to $75 per month if you live on qualifying tribal lands. You can apply the ACP credit to any Frontier internet service on the same terms available to customers not eligible for ACP, and you can get the ACP credit from any participating provider you choose, with one monthly service discount allowed per household.\nImportant: Remember, your ACP credit stops (and your service reverts to full price) when:`
    },
    {
        "Sequence": 1,
        "Type": "CheckBox",
        "Message": `[ul]You don't use the service at least once every 30 days | A bill associated with your service is not paid for 90 consecutive days | You de-enroll from the ACP | The program ends | You transfer your ACP benefit to another provider while receiving service from us [/ul]`,
    },
    {
        "Sequence": 2,
        "Type": "CheckBox",
        "Message": `If we later show that you're enrolled in ACP with another provider, we need you to confirm now that you wish to use your ACP benefit with Frontier`,
    },
    {
        "Sequence": 3,
        "Type": "CheckBox",
        "Message": `The ACP grants only one monthly service discount per household. And the discount can be applied to only one service provider. Transferring your ACP benefit to Frontier means the ACP credit will no longer be applied to the service you’re receiving from your current provider. Important:`
    },
    {
        "Sequence": 4,
        "Type": "CheckBox",
        "Message": `[ol]If you keep your service with the other provider, you may be subject to their undiscounted rates. | With limited exceptions, your household is allowed only one ACP transfer per service month: to reverse an erroneous transfer or if you are unable to receive service from your current provider.[/ol]`
    }
]

function Disclosures({ step, mode, onNext, onPrev, disableHead, disableCheck }) {
    const { company, userId, disclosureCompany } = useParams();
    const [classNames, setClassNames] = useState({});
    const [disclosuresObjects, setDisclosuresObjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingState, isLoadingDispatch] = useContext(IsLoadingContext);
    const [disableCheckBox, setDisabledCheckBox] = useState(false);
    const environment = useContext(EnvironmentContext)[0];
    const [formData, formDataDispatch] = useContext(FormDataContext);

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Disclosures/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/Disclosures/index.less`);
        });
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/Disclosures/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../../../companies/default/styles/steps/Disclosures/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        if (formData.serviceableCompany === "frontier") {
            const headers = { 
                Authorization: "frontier",
                "X-Api-Key": environment.frontierGetDisclosuresUrl.key,
            }
            fetch(`${environment.frontierGetDisclosuresUrl.url}?quoteId=${formData.quoteId}`, {
                method: 'GET',
                headers,
            })
                .then((response) => response.json())
                .then((data) => {
                    setDisclosuresObjects(frontierAdditionalDisclosures);
                    // console.log(disclosuresObjects)
                    // console.log(data)
                    for (let i = 0; i < data.body.length; i++) {
                        formDataDispatch({ 
                            type: 'SET_FRONTIER_DISCLOSURE_FIELDS', 
                            payload: data.body[i]
                        });
                        data.body[i].Sequence += 5
                    }

                    setDisclosuresObjects([...frontierAdditionalDisclosures, ...data.body]);
                    setIsLoading(false);
                })
        } else {
            const request = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': process.env.DISCLOSURES_X_API_KEY,
                },
                body: JSON.stringify({
                    company: formData.serviceableCompany,
                    userId: userId,
                    action: 'get',
                }),
            };

            fetch(environment.disclosuresBackendUrlToGetData, request)
                .then((response) => response.json())
                .then((data) => {
                    const { disclosures } = data;

                    if (Array.isArray(disclosures)) {
                        if (disclosures[0].Type === 'Error') {
                            setDisabledCheckBox(true);
                        }
                        setDisclosuresObjects(disclosures);
                    } else {
                        // TODO: Add a error display telling the user that the disclosure's form was unable to be grabbed, need to work with ceative to get this mocked
                        setDisclosuresObjects([
                            {
                                Sequence: 0,
                                Type: 'Error',
                                Message: 'An error ocurred. Please try refreshing this page, or going back',
                            },
                        ]);
                        setDisabledCheckBox(true);
                    }

                    setIsLoading(false);
                })
                .catch(() => {
                    setDisclosuresObjects([
                        {
                            Sequence: 0,
                            Type: 'Error',
                            Message: 'An error ocurred. Please try refreshing this page, or going back',
                        },
                    ]);
                    setIsLoading(false);
                    setDisabledCheckBox(true);
                });
        }
    }, []);

    return (
        <div className={classNames.centerItems}>
            <ConditionalRender condition={isLoading}>
                <LoadingBlocker />
            </ConditionalRender>
            <ConditionalRender condition={!disableHead}>
                <ConditionalRender condition={formData.serviceableCompany === "excess"}>
                    <HeadingText>ACP Consents & Disclosures</HeadingText>
                </ConditionalRender>
                <ConditionalRender condition={formData.serviceableCompany !== "excess"}>
                    <HeadingText>Terms & Conditions</HeadingText>
                </ConditionalRender>
                <ConditionalRender condition={company === "excess"}>
                    <SubHeadingText>
                        In order to continue with your application, please read the information below, and check the box to accept the following ACP Consents and Disclosures.
                    </SubHeadingText>
                </ConditionalRender>
                <ConditionalRender condition={company !== "excess"}>
                    <SubHeadingText>
                        In order to continue with your application, please read the information below, and check the box to accept the Terms & Conditions.
                    </SubHeadingText>
                </ConditionalRender>
            </ConditionalRender>
            <DisclosuresContainer displayLogo={true} companyProp={formData.serviceableCompany} className={classNames.disclosuresContainer}>
                <ConditionalRender falseReturn={<Loading />} condition={!isLoading}>
                    {disclosuresObjects.map((disclosuresObject) => (
                        <DisclosuresSection key={disclosuresObject.Sequence}>
                            <StyleDisclosuresText text={disclosuresObject.Message} />
                        </DisclosuresSection>
                    ))}
                </ConditionalRender>
            </DisclosuresContainer>
            <ConditionalRender condition={!disableCheckBox && formData.serviceableCompany === "excess"}>
                <CheckBox
                    step={step}
                    validationKey="acceptedDisclosures"
                    validationFunction={requiredCheckbox}
                    initialCheck={formData.acceptedDisclosures}
                    disabled={disableCheckBox}
                    onChecked={(checked) => {
                        formDataDispatch({ type: 'SET_ACCEPTED_DISCLOSURES', payload: checked });
                    }}
                    name="Agree"
                >
                    I Agree to all the Above ACP Consents and Disclosures.
                </CheckBox>
            </ConditionalRender>
            <ConditionalRender 
                condition={!disableCheckBox && formData.serviceableCompany !== "excess"}>
                <CheckBox
                    step={step}
                    validationKey="acceptedDisclosures"
                    validationFunction={requiredCheckbox}
                    initialCheck={formData.acceptedDisclosures}
                    disabled={disableCheckBox}
                    onChecked={(checked) => {
                        formDataDispatch({ type: 'SET_ACCEPTED_DISCLOSURES', payload: checked });
                    }}
                    name="Agree"
                >
                    I Agree to all the Above Terms and Conditions.
                </CheckBox>
            </ConditionalRender>
            {/* </ConditionalRender> */}
        </div>
    );
}

Disclosures.propTypes = propTypes;
Disclosures.defaultProps = defaultProps;

export default Disclosures;
