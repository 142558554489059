// Helpers
import initialState from './initialState';

const EnvironmentReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_PAGE_BLOCKER':
            return { ...state, blockPage: { ...state.blockPage, [action.payload]: true }};
        case 'REMOVE_PAGE_BLOCKER':
            let tempPageState = { ...state.blockPage }
            delete tempPageState[action.payload]; 

            return {...state, blockPage: tempPageState};
        case 'ADD_INPUT_BLOCKER':
            // payload is always a string
            const inputBlockerObj = { ...state, blockInput: { ...state.blockInput, [action.payload]: true },};
            if(state?.addedBlockerArr){
                inputBlockerObj.addedBlockerArr = [...state.addedBlockerArr, { blockerName: action.payload.toLowerCase().replace(/ /g, "_"), blockerStartTimestamp: Date.now() }]
            } else {
                inputBlockerObj.addedBlockerArr = [{ blockerName: action.payload.toLowerCase().replace(/ /g, "_"), blockerStartTimestamp: Date.now() }]
            }
            // console.log(inputBlockerObj)
            return inputBlockerObj
        case 'ADD_INPUT_BLOCKER_WITH_TEXT':
            let tempTextArray = [...action.payload.textArray, action.payload.textArray[0]]
            return { ...state, blockInput: { ...state.blockInput, [action.payload.key]: true }, textArray: tempTextArray};
        case 'REMOVE_INPUT_BLOCKER':
            let tempInputState = { ...state.blockInput }
            
            //loop over the items in the added addedBlockerArr
            // if the the action.payload matches the item.blockerName, add the endtime and diff
            const newAddedBlockerArr = []
            if(state?.addedBlockerArr){
                state.addedBlockerArr.forEach((item) => {
                    if(item.blockerName == action.payload.toLowerCase().replace(/ /g, "_")){
                        const newItem = {
                            blockerName: item.blockerName,
                            blockerStartTimestamp: item.blockerStartTimestamp
                        }
                        const itemEndTime = Date.now()
                        newItem.endBlockerTimeStamp = itemEndTime
                        newItem.blockerDiff = itemEndTime - item.blockerStartTimestamp
                        newAddedBlockerArr.push(newItem)
                    } else {
                        newAddedBlockerArr.push(item)
                    }
                });
            }
            delete tempInputState[action.payload];

            return {...state, blockInput: tempInputState, addedBlockerArr: newAddedBlockerArr};
        case 'REMOVE_INPUT_BLOCKER_WITH_TEXT':
            let tempInputStateWithText = { ...state.blockInput }
            delete tempInputStateWithText[action.payload]; 

            return {...state, blockInput: tempInputStateWithText, textArray: null};
        case 'CLEAR_LOADING_BLOCKERS':
            return initialState;
        default:
            return state;
    }
};

export default EnvironmentReducer;
