// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender'
// UI
import LoadingBlocker from '../../../ui/LoadingBlocker'
import HeadingText from '../../../ui/HeadingText';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';


// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function FrontierNV({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [startedTimeout, setStartedTimeout] = useState(false);
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [isLoading, isLoadingDispatch] = useContext(IsLoadingContext);
    const [steps, stepsDispatch] = useContext(StepsContext);
    const environment = useContext(EnvironmentContext)[0];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierNV/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierNV/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    useEffect(() => {
        const asyncFunction = async () => {
            const eligibilityHeaders = { 
                "Authorization" : "frontier",
                "X-Api-Key": environment.frontierEligibilityCheckUrl.key,
            }
            const eligibilityBody = {
                firstName: formData.firstName,
                middleName: formData.middleName ? formData.middleName : '',
                lastName: formData.lastName,
                address: formData.address.parsedAddress.street + (formData.address.parsedAddress.line2 ? `, ${formData.address.parsedAddress.line2}` : ""),
                city: formData.address.parsedAddress.city,
                state: formData.address.parsedAddress.state,
                zipCode: formData.address.parsedAddress.zip,
                ssn4: formData.ssn, 
                dob: formData.formattedDob,
                contactPhoneNumber: formData.phoneNumber,
                bqpFirstName: formData.bqpFirstName ? formData.bqpLastName : '',
                bqpLastName: formData.bqpLastName ? formData.bqpLastName : '',
                bqpDob: formData.bqpDob ? formData.bqpDob : '',
                bqpSsn4: formData.bqpSsn ? formData.bqpSsn : '',
                eligibilityProgramCode: formData.programs[0].programCode,
                consentInd: 'Y',
                contactEmail: formData.email,
                contactAddress: formData.address.parsedAddress.street + (formData.address.parsedAddress.line2 ? `, ${formData.address.parsedAddress.line2}` : ""),
                contactCity: formData.address.parsedAddress.city,
                contactState: formData.address.parsedAddress.state,
                contactZipCode: formData.address.parsedAddress.zip
            }
            const eligibilityResponse = await fetch(environment.frontierEligibilityCheckUrl.url, {
                method: 'POST',
                headers: eligibilityHeaders,
                body: JSON.stringify(eligibilityBody),
            })
            const eligibilityResponseObject = await eligibilityResponse.json();
            if (eligibilityResponseObject?.body?.applicationId) {
                formDataDispatch({
                    type: 'SET_APPLICATION_ID',
                    payload: eligibilityResponseObject?.body?.applicationId,
                });
                formDataDispatch({
                    type: 'SET_APPLICATION_STATUS',
                    payload: eligibilityResponseObject?.body?.status,
                });
                const mcHeaders =  {
                    Authorization: company,
                    "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                }
                const mcBody = { 
                    userId,
                    'nv_application_id': eligibilityResponseObject?.body?.applicationId,
                    "application_status": eligibilityResponseObject?.body?.status
                }
                const setFieldResponse = await fetch(environment.backendUrlToSetData, {
                    method: 'POST',
                    headers: mcHeaders,
                    body: JSON.stringify(mcBody),
                })
                const setFieldResponseObj = await setFieldResponse.json()
            }
            onNext()
        }
        asyncFunction();
    }, [])

    return (
        <LoadingBlocker>
            <div className={classNames.loadingAnimation}>
                <div>
                    <HeadingText>Checking the National Verifier...</HeadingText>
                    <HeadingText>Confirming your eligibility...</HeadingText>
                    <HeadingText>Finishing the boring stuff...</HeadingText>
                    <HeadingText>Checking the National Verifier...</HeadingText>
                </div>
            </div>
        </LoadingBlocker>
    )
}

FrontierNV.propTypes = propTypes;
FrontierNV.defaultProps = defaultProps;

export default FrontierNV;