// Packages
import React, { useContext, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
// Components
import ConditionalRender from './ConditionalRender';
// UI
import Button from '../ui/Button';
import ProgressBar from '../ui/ProgressBar';
import ProgressStepper from '../ui/ProgressStepper';
import StepperButtonsContainer from '../ui/StepperButtonsConatiner';
import Step from '../ui/Step';
// Contexts
import { StepsContext } from '../contexts/Steps';
import { ValidationContext } from '../contexts/Validation';
import { FormDataContext } from '../contexts/FormData';
import { EnvironmentContext } from '../contexts/Environment';
import { IsLoadingContext } from '../contexts/IsLoading';
import { PopupContext } from '../contexts/Popup';
import { ConfigContext } from '../contexts/Config';
import { QueryParamsContext } from '../contexts/QueryParams';
// Helpers
import { validateAllFieldsInStep } from '../helpers/validation';

// Props of the Component
const propTypes = {
    onSubmit: PropTypes.func,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    validateOnNext: PropTypes.bool,
    validateOnSubmit: PropTypes.bool,
    validateOnPrevious: PropTypes.bool,
    mode: PropTypes.string,
    incrementOnLastStep: PropTypes.bool
};
const defaultProps = {
    onSubmit: () => {},
    onNext: () => {},
    onPrevious: () => {},
    validateOnNext: false,
    validateOnSubmit: false,
    validateOnPrevious: false,
    mode: "form",
    incrementOnLastStep: false
};

function Stepper({ onSubmit, onNext, onPrevious, validateOnNext, validateOnSubmit, validateOnPrevious, mode, incrementOnLastStep }) {
    const { company, userId, disclosureCompany } = useParams();
    const [reRunButton, setReRunButton] = useState(false)
    const [onNextOccurred, setOnNextOccurred] = useState({bool: false})
    const [onPreviousOccurred, setOnPreviousOccurred] = useState({bool: false})
    const [onSubmitOccurred, setOnSubmitOccurred] = useState({bool: false})
    const [buttonLastClicked, setButtonLastClicked] = useState({function: () => {}})
    const [subtractFromLength, setSubtractFromLength] = useState(0)
    const [subtractFromCurrentStep, setSubtractFromCurrentStep] = useState(0)
    const [stepsState, stepDispatch] = useContext(StepsContext);
    const [popupState, popupDispatch] = useContext(PopupContext);
    const [validation, validationDispatch] = useContext(ValidationContext);
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [configState, configDispatch] = useContext(ConfigContext); 
    const [queryParamsState, queryParamsDispatch] = useContext(QueryParamsContext);
    const environment = useContext(EnvironmentContext)[0];
    const isLoadingDispatch = useContext(IsLoadingContext)[1];
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const topOfStep = React.useRef(null);

    const scrollToTopOfStep = () => {
        topOfStep.current.scrollIntoView({
            behavior: "smooth"
        });
    };

    const onPrevFunction = (i, onStepFunction=()=>{}, reload=true, fields=null) => {
        if (validateOnPrevious) {
            if (reload) {
                validationDispatch({ type: 'RELOAD_VALIDATION' });
            }
            if (validateAllFieldsInStep(i, fields ? fields : validation.fields)) {
                stepDispatch({ type: 'DECREMENT_STEP' });
                onPrevious();
                setOnPreviousOccurred(prev => ({...prev, bool: true, onStepFunction}))
                scrollToTopOfStep()
            }
        } else {
            stepDispatch({ type: 'DECREMENT_STEP' });
            onPrevious();
            setOnPreviousOccurred(prev => ({...prev, bool: true, onStepFunction}))
            scrollToTopOfStep()
        }
    }
    const onNextFunction = (i, onStepFunction=()=>{},reload=true, fields=null) => {
        if (i >= stepsState.steps.length - 1 || mode !== 'form') {
            if (validateOnSubmit) {
                if (reload) {
                    validationDispatch({ type: 'RELOAD_VALIDATION' });
                }
                if (validateAllFieldsInStep(i, fields ? fields : validation.fields)) {
                    if (incrementOnLastStep) {
                        stepDispatch({ type: 'INCREMENT_STEP' });
                    }
                    onSubmit(i, validation.fields && !reload);
                    setOnSubmitOccurred(prev => ({...prev, bool: true, onStepFunction}))
                }
            } else {
                onSubmit(i, validation.fields && !reload);
                setOnSubmitOccurred(prev => ({...prev, bool: true, onStepFunction}))
            }
        } else if (validateOnNext) {
            if (reload) {
                validationDispatch({ type: 'RELOAD_VALIDATION' });
            }

            if (validateAllFieldsInStep(i, fields ? fields : validation.fields)) {
                stepDispatch({ type: 'INCREMENT_STEP' });
                onNext(i, validation.fields && !reload);
                setOnNextOccurred(prev => ({...prev, bool: true, onStepFunction}))
                scrollToTopOfStep()
            }
        } else {
            stepDispatch({ type: 'INCREMENT_STEP' });
            onNext(i, validation.fields && !reload);
            setOnNextOccurred(prev => ({...prev, bool: true, onStepFunction}))
            scrollToTopOfStep()
        }
    }

    useEffect(() => {
        if (stepsState?.metaData) {
            if (subtractFromLength !== stepsState.metaData.subtractFromStepsLength && subtractFromCurrentStep !== stepsState.metaData.subtractFromCurrentStep) {
                setSubtractFromLength((stepsState.metaData.subtractFromStepsLength ? stepsState.metaData.subtractFromStepsLength : 0 ))
                setSubtractFromCurrentStep((stepsState.metaData.subtractFromCurrentStep ? stepsState.metaData.subtractFromCurrentStep : 0))
            }
        }
    }, [stepsState])

    useEffect(() => {
        if (onNextOccurred.bool) {
            onNextOccurred.onStepFunction({ formData, userId, company, environment, validationDispatch, formDataDispatch, setIsLoadingOnStep: (bool) => { 
                if (bool) {
                    isLoadingDispatch({ type: "ADD_INPUT_BLOCKER", payload: "StepperOnNext" })
                } else {
                    isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "StepperOnNext" })
                }
                isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "StepperAsyncValidation" })
            }, queryParameters, disclosureCompany, stepDispatch, formDataDispatch, popupDispatch, configState, queryParamsState})
            setOnNextOccurred(prev => ({...prev, bool: false, onStepFunction: () => {}}))
        }
    }, [onNextOccurred])
    useEffect(() => {
        if (onSubmitOccurred.bool) {
            onSubmitOccurred.onStepFunction({ formData, userId, company, environment, validationDispatch, formDataDispatch, setIsLoadingOnStep: (bool) => { 
                if (bool) {
                    isLoadingDispatch({ type: "ADD_INPUT_BLOCKER", payload: "StepperOnSubmit" })
                } else {
                    isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "StepperOnSubmit" })
                }
                isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "StepperAsyncValidation" })
            }, queryParameters, disclosureCompany, stepDispatch, formDataDispatch, popupDispatch, configState, queryParamsState})
            setOnSubmitOccurred(prev => ({...prev, bool: false, onStepFunction: () => {}}))
        }
    }, [onSubmitOccurred])
    useEffect(() => {
        if (onPreviousOccurred.bool) {
            onPreviousOccurred.onStepFunction({ formData, userId, company, environment, validationDispatch, formDataDispatch, setIsLoadingOnStep: (bool) => { 
                if (bool) {
                    isLoadingDispatch({ type: "ADD_INPUT_BLOCKER", payload: "StepperOnPrevious" })
                } else {
                    isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "StepperOnPrevious" })
                }
                isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "StepperAsyncValidation" })
            }, queryParameters, disclosureCompany, stepDispatch, formDataDispatch, popupDispatch, configState, queryParamsState})
            setOnPreviousOccurred(prev => ({...prev, bool: false, onStepFunction: () => {}}))
        }
    }, [onPreviousOccurred])

    useEffect(() => {
        const ifAsyncValidationIsAllTrue = Object.keys(validation.waitingForValidationCompletion).some((element) => validation.waitingForValidationCompletion[element] === true)
        const ifAsyncValidationIsAllFalse = Object.keys(validation.waitingForValidationCompletion).some((element) => validation.waitingForValidationCompletion[element] === false)
        
        if (ifAsyncValidationIsAllTrue) {
            setReRunButton(true)
            isLoadingDispatch({ type: "ADD_INPUT_BLOCKER", payload: "StepperAsyncValidation" })
        } else if (ifAsyncValidationIsAllFalse && reRunButton) {
            if (!stepsState.steps[stepsState.currentStep].disableAsyncOnEvent) {
                buttonLastClicked.function(false, validation.fields)
            } else {
                isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "StepperAsyncValidation" })
            }
            setReRunButton(false)
            if (!validateAllFieldsInStep(stepsState.currentStep, validation.fields) || !buttonLastClicked.name) {
                isLoadingDispatch({ type: "REMOVE_INPUT_BLOCKER", payload: "StepperAsyncValidation" })
            }
        }
    }, [validation.waitingForValidationCompletion])

    return (
        <>
            <div ref={topOfStep}></div>
            <ConditionalRender condition={mode === "form" && stepsState.steps.length > 1}>
                <ConditionalRender condition={!stepsState?.steps[stepsState?.currentStep]?.disableProgressBar}>
                    <ConditionalRender condition={stepsState.metaData?.ProgressStepperStyle !== undefined}>
                        <ProgressBar />
                    </ConditionalRender>
                    <ConditionalRender condition={!stepsState.metaData?.ProgressStepperStyle}>
                        <ProgressStepper />
                    </ConditionalRender>
                </ConditionalRender>
            </ConditionalRender>
            {
                stepsState.steps.map((step, i) => {
                    if (step.isMetaData) {
                        return <Fragment key={i} />
                    }
                    return (
                        <ConditionalRender key={i} condition={stepsState.currentStep === i}>
                            <Step>
                                <div style={{ flexGrow: "1", position: "relative", display: "flex", flexDirection: "column" }}>
                                    {React.cloneElement(step.component, { 
                                        step: i, 
                                        mode: mode,
                                        onNext: () => {
                                            let onStepFunction = step.onNextFunction ? step.onNextFunction : () => {}
                                            if (i >= stepsState.steps.length - 1 || mode !== "form") {
                                                onStepFunction = step.onSubmitFunction ? step.onSubmitFunction : () => {}
                                            }
        
                                            setButtonLastClicked({name: "next", function: (reload=true, fields=null) => {onNextFunction(i, onStepFunction, reload, fields)}})
                                            onNextFunction(i, onStepFunction)
                                        },
                                        onPrev: () => {
                                            setButtonLastClicked({name: "prev", function: (reload=true, fields=null) => {onPrevFunction(i, step.onPrevFunction ? step.onPrevFunction : () => {}, reload, fields)}})
                                            onPrevFunction(i, step.onPrevFunction ? step.onPrevFunction : () => {})
                                        } 
                                    })}
                                </div>
                                <ConditionalRender condition={!step.disableButtons}>
                                    <StepperButtonsContainer>
                                        <ConditionalRender condition={!step.disableNextButton}>
                                            <Button
                                                type="button"
                                                className="stepperButton"
                                                style={(i > 0 && mode === "form") ? (step.disablePrevButton ? "loneNext" :"next") : "loneNext"}
                                                onClick={() => {
                                                    let onStepFunction = step.onNextFunction ? step.onNextFunction : () => {}
                                                    if (i >= stepsState.steps.length - 1 || mode !== 'form') {
                                                        onStepFunction = step.onSubmitFunction ? step.onSubmitFunction : () => {}
                                                    }
        
                                                    setButtonLastClicked({name: "next", function: (reload=true, fields=null) => {onNextFunction(i, onStepFunction, reload, fields)}})
                                                    onNextFunction(i, onStepFunction)
                                                }}
                                            >
                                                {i >= stepsState.steps.length - 1 || mode !== 'form' ? (step.submitText ? step.submitText : 'Submit') : (step.nextText ? step.nextText : 'Next')}
                                            </Button>
                                        </ConditionalRender>
                                        <ConditionalRender condition={!step.disablePrevButton}>
                                            <ConditionalRender condition={i > 0 && mode === "form"}>
                                                <Button
                                                    type="button"
                                                    style="previous"
                                                    className="stepperButton"
                                                    onClick={() => {
                                                        setButtonLastClicked({name: "prev", function: (reload=true, fields=null) => {onPrevFunction(i, step.onPrevFunction ? step.onPrevFunction : () => {}, reload, fields)}})
                                                        onPrevFunction(i, step.onPrevFunction ? step.onPrevFunction : () => {})
                                                    }}
                                                >
                                                    {(step.previousText ? step.previousText : 'Previous')}
                                                </Button>
                                            </ConditionalRender>
                                        </ConditionalRender>
                                    </StepperButtonsContainer>
                                </ConditionalRender>
                            </Step>
                        </ConditionalRender>
                    )
                })
            }
        </>
    )
}

Stepper.propTypes = propTypes;
Stepper.defaultProps = defaultProps;

export default Stepper;
