// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender'
// UI
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
import RadioMenu from '../../../ui/RadioMenu';
import BodyText from '../../../ui/BodyText';
import Card from '../../../ui/Card';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';
import { requireNonEmptyValue, runMultipleValidationFunctions} from '../../../helpers/validation';


// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function FrontierSecurityQuestions({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});

    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [isLoading, isLoadingDispatch] = useContext(IsLoadingContext);
    const [steps, stepsDispatch] = useContext(StepsContext);
    const environment = useContext(EnvironmentContext)[0];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierNV/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierNV/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    return (
        <>
            <HeadingText>Security Questions</HeadingText>
            <SubHeadingText>Please verify your identity by answering the questions below.</SubHeadingText>
            {
                formData.securityChallengeQuestions.map((securityQuestion, i) => {
                    // /let answerIndex = 0
                    let answers = []
                    securityQuestion?.answerChoices?.forEach((choice) => {
                        answers.push(
                            choice.text
                        )
                    })
                    return (
                        <Card>
                            <BodyText>{`${i + 1}. ${securityQuestion.question}`}</BodyText>
                            <RadioMenu 
                                inputs={answers} 
                                name={securityQuestion.question}
                                onChange={(value) => {
                                    formDataDispatch({
                                        type: 'SET_FRONTIER_SECURITY_ANSWERS',
                                        payload: {[i + 1]: (answers.indexOf(value) + 1)}
                                    });
                                }}
                                step={step}
                                validationKey={`frontierSecurityQuestions${i}`}
                                validationFunction={(value) =>
                                    runMultipleValidationFunctions(value, [
                                        requireNonEmptyValue
                                    ])
                                }
                            />
                        </Card>
                    )
                })
            }
        </>
    )
}

FrontierSecurityQuestions.propTypes = propTypes;
FrontierSecurityQuestions.defaultProps = defaultProps;

export default FrontierSecurityQuestions;