// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// UI
import Logo from './Logo';

// Props of the Component
const propTypes = {
    className: PropTypes.string,
};
const defaultProps = {
    className: '',
};

function Header({ className }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Header/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Header/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Header.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Header.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Header/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Header/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <header className={`${classNames.header} ${className}`}>
            <Logo />
        </header>
    );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
