// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// UI
import BodyText from './BodyText'
// Helpers 
import { formatPhoneNumber } from '../helpers/phone'

// Props of the Component
const propTypes = {
    children: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    noOne: PropTypes.bool
};
const defaultProps = {
    onClick: () => {},
    className: "",
    noOne: false
};

function DisplayPhoneNumber({ children, onClick, className, noOne }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/DisplayPhoneNumber/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/DisplayPhoneNumber/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/DisplayPhoneNumber.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/DisplayPhoneNumber.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/DisplayPhoneNumber/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/DisplayPhoneNumber/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    return <a onClick={() => { onClick() }} className={`${classNames.phoneNumber} ${className}`} href={`tel:${children}`}>{formatPhoneNumber(noOne ? children : 1 + children)}</a>;
}

DisplayPhoneNumber.propTypes = propTypes;
DisplayPhoneNumber.defaultProps = defaultProps;

export default DisplayPhoneNumber;
