// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Props of the Component
const propTypes = {
    className: PropTypes.string,
};
const defaultProps = {
    className: '',
};

function Logo({ className }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [logo, setLogo] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Logo/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Logo/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/Logo.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/Logo.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/Logo/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/Logo/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/logo/logoObject.js`)
            .then((module) => {
                setLogo(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/variables/logo/logoObject.js`).then(
                    (module) => {
                        setLogo(module.default);
                    }
                );
            });
    }, [company]);

    return (
        <img 
            src={logo.image} 
            className={`${classNames.image} ${className}`} 
            alt={logo.altText}
        />
    );
}

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
