const classNames = {
    ProgressStepperContainer: "container-ProgressStepper",
    circle: "circle-ProgressStepper",
    bar: "bar-ProgressStepper",
    currentCircle: "current-circle-ProgressStepper",
    filledBar: "filled-bar-ProgressStepper",
    filledCircle: "filled-circle-ProgressStepper",
    outlinedBar: "outlined-bar-ProgressStepper",
    outlinedCircle: "outlined-circle-ProgressStepper",
};

export default classNames;