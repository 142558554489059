// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// UI
import LegalText from './LegalText'

// Props of the Component
const propTypes = {
    children: PropTypes.element.isRequired,
};

function DisclosuresSection({ children }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/DisclosuresSection/index.less`).catch(
            () => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/DisclosuresSection/index.less`);
            }
        );
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/DisclosuresSection.less`)
        .catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/DisclosuresSection.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/DisclosuresSection/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(
                    /* webpackMode: "eager" */ `../companies/default/styles/ui/DisclosuresSection/classNames.js`
                ).then((module) => {
                    setClassNames(module.default);
                });
            });
    }, [company]);

    return <article className={classNames.section}><LegalText>{children}</LegalText></article>;
}

DisclosuresSection.propTypes = propTypes;

export default DisclosuresSection;
