// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// Components
import ConditionalRender from '../components/ConditionalRender'
// UI
import FreeconnectCompanyLogo from './FreeconnectCompanyLogo'

// Props of the Component
const propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    displayLogo: PropTypes.bool,
    companyProp: PropTypes.string
};
const defaultProps = {
    className: '',
    displayLogo: false,
    companyProp: null
};

function DisclosuresContainer({ children, className, displayLogo, companyProp }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/DisclosuresContainer/index.less`)
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/DisclosuresContainer/index.less`);
            });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/DisclosuresContainer.less`)
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/DisclosuresContainer.less`);
            });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/DisclosuresContainer/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(
                    /* webpackMode: "eager" */ `../companies/default/styles/ui/DisclosuresContainer/classNames.js`
                ).then((module) => {
                    setClassNames(module.default);
                });
            });
    }, [company]);

    return (
        <section className={`${classNames.outerDiv} ${className}`}>
            <ConditionalRender condition={displayLogo}>
                <div className={classNames.logoContainer}>
                    <FreeconnectCompanyLogo className={classNames.logo} companyProp={companyProp} />
                </div>
            </ConditionalRender>
            <div className={classNames.innerDiv}>{children}</div>
        </section>
    );
}

DisclosuresContainer.propTypes = propTypes;
DisclosuresContainer.defaultProps = defaultProps;

export default DisclosuresContainer;
