// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Props of the Component
const propTypes = {
    className: PropTypes.string,
    compcompanyPropany: PropTypes.string
};
const defaultProps = {
    className: '',
    companyProp: null
};

function FreeconnectCompanyLogo({ className, companyProp }) {
    const { company } = useParams();
    // const [classNames, setClassNames] = useState({});
    const [logos, setLogos] = useState({});

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/FreeconnectCompanyLogo/logos.jsx`)
            .then((module) => {
                setLogos(module.default);
            })
            .catch(() => {
                setLogos({})
            });
    }, [company]);

    if (logos[companyProp]?.image && logos[companyProp]?.altText) {
        return (
            <img 
                src={logos[companyProp].image} 
                className={className}
                alt={logos[companyProp].altText}
            />
        );
    } else {
        return <></>
    }
}

FreeconnectCompanyLogo.propTypes = propTypes;
FreeconnectCompanyLogo.defaultProps = defaultProps;

export default FreeconnectCompanyLogo;
