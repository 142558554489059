// Packages
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function Fonts() {
    const { company } = useParams();

    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/fonts/fonts.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/fonts/fonts.less`);
        });
    }, [company]);

    return '';
}

export default Fonts;
