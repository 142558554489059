import SmartySDK from 'smartystreets-javascript-sdk';

// Smarty
const SmartyCore = SmartySDK.core;
const { Lookup } = SmartySDK.usAutocompletePro;
const key = process.env.SMARTY_API_KEY;
const credentials = new SmartyCore.SharedCredentials(key);
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
const client = clientBuilder.buildUsAutocompleteProClient();

export const parseAddressObject = (addressObject) => {
    const parsedObject = {};

    parsedObject.addressLine1 = '';
    parsedObject.parsedAddress = {};

    if (addressObject.streetLine) {
        parsedObject.addressLine1 += addressObject.streetLine;
        parsedObject.addressLine1 += addressObject.city ? `, ${addressObject.city}` : '';
        parsedObject.addressLine1 += addressObject.state ? `, ${addressObject.state}` : '';
        parsedObject.addressLine1 += addressObject.zipcode ? `, ${addressObject.zipcode}` : '';

        if (addressObject.city || addressObject.state || addressObject.zipcode) {
            parsedObject.parsedAddress.street = addressObject.streetLine;
            parsedObject.parsedAddress.city = addressObject.city ? addressObject.city : '';
            parsedObject.parsedAddress.state = addressObject.state ? addressObject.state : '';
            parsedObject.parsedAddress.zip = addressObject.zipcode ? addressObject.zipcode : '';
        }
        if (addressObject.secondary) {
            parsedObject.parsedAddress.secondary = addressObject.secondary
        }
    }

    return parsedObject;
};
export const reverseParseAddressObject = (addressObject, exludeSecondary = false) => {
    const parsedObject = { streetLine: '' };

    if (addressObject.parsedAddress?.street) {
        parsedObject.streetLine = addressObject.parsedAddress.street ? addressObject.parsedAddress.street : '';
        parsedObject.streetLine += addressObject.parsedAddress.line2 ? ` ${addressObject.parsedAddress.line2}` : '';
        parsedObject.city = addressObject.parsedAddress.city ? addressObject.parsedAddress.city : '';
        parsedObject.state = addressObject.parsedAddress.state ? addressObject.parsedAddress.state : '';
        parsedObject.zipcode = addressObject.parsedAddress.zip ? addressObject.parsedAddress.zip : '';
        if (!exludeSecondary) {
            parsedObject.secondary = addressObject.parsedAddress.secondary ? addressObject.parsedAddress.secondary : '';
        }
        parsedObject.entries = addressObject.parsedAddress.entries ? addressObject.parsedAddress.entries : 0;
    } else if (addressObject.addressLine1) {
        parsedObject.streetLine = addressObject.addressLine1;
    }

    return parsedObject;
};

export const parseSmartyValidationObject = (smartyValidationObject) => {
    const parsedObject = {};

    parsedObject.addressLine1 = '';
    parsedObject.parsedAddress = {};

    if (smartyValidationObject?.components?.primary_number) {
        parsedObject.addressLine1 = smartyValidationObject?.delivery_line_1
            ? smartyValidationObject.delivery_line_1
            : '';
        parsedObject.addressLine1 += smartyValidationObject?.components?.city_name
            ? `, ${smartyValidationObject.components.city_name}`
            : '';
        parsedObject.addressLine1 += smartyValidationObject?.components?.state_abbreviation
            ? `, ${smartyValidationObject.components.state_abbreviation}`
            : '';
        parsedObject.addressLine1 += smartyValidationObject?.components?.zipcode
            ? `, ${smartyValidationObject.components.zipcode}`
            : '';

        if (
            smartyValidationObject?.components?.city_name ||
            smartyValidationObject?.components?.state_abbreviation ||
            smartyValidationObject?.components?.zipcode
        ) {
            parsedObject.parsedAddress.street = smartyValidationObject?.delivery_line_1
            ? smartyValidationObject.delivery_line_1
            : '';
            parsedObject.parsedAddress.city = smartyValidationObject?.components?.city_name
                ? smartyValidationObject.components.city_name
                : '';
            parsedObject.parsedAddress.state = smartyValidationObject?.components?.state_abbreviation
                ? smartyValidationObject.components.state_abbreviation
                : '';
            parsedObject.parsedAddress.zip = smartyValidationObject?.components?.zipcode
                ? smartyValidationObject.components.zipcode
                : '';
            parsedObject.parsedAddress.zipPlus4 = smartyValidationObject?.components?.zipcode && smartyValidationObject?.components?.plus4_code
                ? `${smartyValidationObject.components.zipcode}-${smartyValidationObject.components.plus4_code}`
                : '';
        }

        if (
            smartyValidationObject?.components?.secondary_designator &&
            smartyValidationObject?.components?.secondary_number
        ) {
            parsedObject.addressLine2 = `${smartyValidationObject.components.secondary_designator} ${smartyValidationObject.components.secondary_number}`;
            parsedObject.parsedAddress.line2 = `${smartyValidationObject.components.secondary_designator} ${smartyValidationObject.components.secondary_number}`;

            parsedObject.parsedAddress.street = parsedObject.parsedAddress.street.replace(parsedObject.parsedAddress.line2,'')
            parsedObject.addressLine1 = parsedObject.addressLine1.replace(parsedObject.parsedAddress.line2,'')
        }
    }

    return parsedObject;
};

export const requestToQuery = (request) => {
    const street = request.streetLine ? `${request.streetLine} ` : '';
    const secondary = request?.secondary ? `${request.secondary} ` : '';
    const entries = request?.entries > 0 ? `(${request.entries}) ` : '';
    const city = request?.city ? `${request.city} ` : '';
    const state = request?.state ? `${request.state}, ` : '';
    const zip = request?.zipcode ? `${request.zipcode}` : '';

    return street + secondary + entries + city + state + zip;
};

export const querySmartyAutoComplete = async (smartyObjet, preferZIPCodes = [], maxResults = 5) => {
    const query = typeof smartyObjet === 'string' || smartyObjet instanceof String ? smartyObjet : requestToQuery(smartyObjet);
    const lookup = new Lookup(query);
    lookup.maxResults = maxResults;
    lookup.source = 'all';

    if (preferZIPCodes?.length > 0) {
        lookup.preferZIPCodes = preferZIPCodes
    }

    if (smartyObjet?.entries > 0) {
        lookup.selected = query;
    }

    const results = await client.send(lookup);

    return results.result;
};

export default parseAddressObject;
