const initialState = {
    backendUrlToGetData: '',
    backendUrlToSetData: '',
    backendUrlToSubmitData: '',
    disclosuresBackendUrlToGetData: '',
    smartyUrl: '',
    vcareUrl: '',
    marketplaceServiceabilityBackendUrlToCheck: '',
    backendUrlToSetDataForKore: '',
    frontierPredictiveSearchUrl: '',
    frontierGetOffersByAddressUrl: '',
    frontierEligibilityCheckUrl: '',
    frontierGetOffersLowIncomeUrl: '',
    frontierLowIncomeByAppUrl: '',
    frontierLowIncomeVerificationUrl: '',
    frontierQuoteItemPostUrl: '',
    frontierQuoteItemPutUrl: '',
    frontierGetDisclosuresUrl: '',
    frontierAccpetedDisclosuresUrl: '',
    frontierCreateQuoteUrl: '',
    frontierFinishQuoteCreateUrl: '',
    frontierCloseSelectOffersUrl: '',
    frontierCustomerCreditCheckUrl: '',
    frontierCustomerCreditBundleUrl: '',
    frontierGetQuoteUrl: '',
    frontierGetDepositRequirementsUrl: '',
    frontierGetScheduleUrl: '',
    frontierFinishReserveScheduleUrl: '',
    frontierSubmitCustomerJourneyUrl: '',
};

export default initialState;
