// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender'
// UI
import LoadingBlocker from '../../../ui/LoadingBlocker'
import SuperHeadingText from '../../../ui/SuperHeadingText';
// Steps
import CallNow from './CallNow';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';
// Freeconnect
import { baseFrontierSteps, optimumSteps } from '../contexts/form/steps'

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function DetermineCompany({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [renderFirstAnimationText, setRenderFirstAnimationText] = useState(true);
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [isLoading, isLoadingDispatch] = useContext(IsLoadingContext);
    const [steps, stepsDispatch] = useContext(StepsContext);
    const environment = useContext(EnvironmentContext)[0];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/DetermineCompany/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/DetermineCompany/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    useEffect(() => {
        fetch(environment.marketplaceServiceabilityBackendUrlToCheck, {
            method: 'POST',
            body: JSON.stringify({
                company: "free",
                address1: formData.address.parsedAddress.street ? formData.address.parsedAddress.street : "",
                address2: formData.address.parsedAddress.line2 ? formData.address.parsedAddress.line2 : "",
                city: formData.address.parsedAddress.city ? formData.address.parsedAddress.city : "",
                state: formData.address.parsedAddress.state ? formData.address.parsedAddress.state : "",
                zip: formData.address.parsedAddress.zip ? formData.address.parsedAddress.zip : "",
                user_id: userId,     
                devorlive: formData.devorlive ? formData.devorlive : "live",
                existingCustomer: formData.existingCustomer ? formData.existingCustomer : false,   
                optimum_skip: formData.optimum_skip ? formData.optimum_skip : false         
            }),
        })
            .then((response) => response.json())
            .then(async (data) => {
                let continueAfterFrontierCheck = true

                if (data.serviceableCompany === 'No Service') {
                    window.location.href = "https://m.me/FreeConnect.us?ref=origin"
                }

                if (data.serviceableCompany !== 'No Service') {
                    formDataDispatch({
                        type: 'SET_SERVICEABLE_COMPANY',
                        payload: data.serviceableCompany,
                    });
                }
                if (data.serviceableCompany === 'optimum' || data.serviceableCompany === 'windstream') {
                    // stepsDispatch({
                    //     type: 'ADD_ASYNC_STEP_ADD',
                    //     payload: { name: "CallNow", indexOccursOn: 7 }
                    // });
                    // stepsDispatch({
                    //     type: 'APPEND_STEP',
                    //     payload: { component: <CallNow />, onNextFunction: (url, formData) => { }, disableButtons: true },
                    // });
                    // onNext()
                } else if (formData.predictiveSearchAddressObject) {
                    const environmentCode = formData.predictiveSearchAddressObject?.samRecords[0].environment
                    const controlNumber = formData.predictiveSearchAddressObject?.samRecords[0].controlNumber
                    const offersByAddressResponse = await fetch(`${environment.frontierGetOffersByAddressUrl.url}?environment=${environmentCode}&controlNumber=${controlNumber}`, {
                        method: 'GET',
                        headers: { 
                            Authorization: "frontier",
                            "X-Api-Key": environment.frontierGetOffersByAddressUrl.key,
                        }
                    })
                    const offersByAddressResponseObject = await offersByAddressResponse.json()

                    if (offersByAddressResponseObject.body.filter(offer => offer.serviceType === "Broadband").length > 0) {
                        continueAfterFrontierCheck = false
                        if (!steps?.metaData?.addedDynamicSteps) {
                            stepsDispatch({
                                type: 'ADD_ASYNC_STEP_ADD',
                                payload: { name: "FrontierSteps", indexOccursOn: 5, amountToSubtractFromLength: 1 }
                            });
                            stepsDispatch({
                                type: 'APPEND_STEPS',
                                payload: baseFrontierSteps,
                            });
                        }
                        
                        const mcData = await fetch(environment.backendUrlToGetData, {
                            method: 'POST',
                            headers: {
                                Authorization: company,
                                "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                            },
                            body: JSON.stringify({
                                userId: userId,
                            }),
                        })

                        const mcDataObject = await mcData.json()

                        let serviceableCompanies = []

                        mcDataObject.data.custom_fields.every((field) => {
                            if (field.name === "serviceable_companies") {
                                serviceableCompanies = field.value
                                return false
                            } else {
                                return true
                            }
                        });

                        const newServiceableCompanies = [ 'frontier', ...serviceableCompanies ]

                        const headers = {
                            Authorization: company,
                            "X-Api-Key": process.env.FORMBACKEND_X_API_KEY
                        }
                        const body = {
                            userId,
                            servicableCompany: "frontier",
                            serviceableCompany: "frontier",
                            display_name: "Frontier",
                            serviceable_companies: newServiceableCompanies,
                            serviceable_companies_string: newServiceableCompanies.join(';')

                        }
            
                        fetch(environment.backendUrlToSetData, {
                            method: 'POST',
                            headers,
                            body: JSON.stringify(body),
                        })

                        formDataDispatch({
                            type: 'SET_SERVICEABLE_COMPANY',
                            payload: "frontier",
                        });

                        onNext()
                    }
                }
                
                if (continueAfterFrontierCheck) {
                    if (data.serviceableCompany !== 'No Service') {
                        formDataDispatch({
                            type: 'SET_SERVICEABLE_COMPANY',
                            payload: data.serviceableCompany,
                        });
                    }
                    if (data.serviceableCompany === 'optimum' || data.serviceableCompany === 'windstream') {
                        if (!steps?.metaData?.addedDynamicSteps) {
                            // stepsDispatch({
                            //     type: 'ADD_ASYNC_STEP_ADD',
                            //     payload: { name: "CallNow", indexOccursOn: 7 }
                            // });
                            // stepsDispatch({
                            //     type: 'APPEND_STEP',
                            //     payload: optimumSteps,
                            // });
                        }
                        onNext()
                    } else {
                        onNext()
                    }
                }
            }).catch((error) => {
                console.log(error)
            })

            setTimeout(() => {
                setRenderFirstAnimationText(false)
                document.documentElement.style.setProperty('--amount-of-loading-texts', '13');
                document.documentElement.style.setProperty('--speed', '1.75s');
            }, 3800);
    }, [])

    return (
        <LoadingBlocker>
            <div className={classNames.loadingAnimation}>
                <div>
                    <SuperHeadingText>Getting best offers...</SuperHeadingText>
                    <SuperHeadingText>Responding to trolls...</SuperHeadingText>
                    <SuperHeadingText>Wheeling and dealing...</SuperHeadingText>
                    <SuperHeadingText>Balling on a budget...</SuperHeadingText>
                    <SuperHeadingText>Touching grass...</SuperHeadingText>
                    <SuperHeadingText>Hunting for treasure...</SuperHeadingText>
                    <SuperHeadingText>Pleasing your pockets...</SuperHeadingText>
                    <SuperHeadingText>Yoga break...</SuperHeadingText>
                    <SuperHeadingText>Snatching savings...</SuperHeadingText>
                    <SuperHeadingText>Burning your bill...</SuperHeadingText>
                    <SuperHeadingText>Cutting the cost...</SuperHeadingText>
                    <SuperHeadingText>Fetching the free...</SuperHeadingText>
                    <SuperHeadingText>Getting best offers...</SuperHeadingText>
                    <ConditionalRender condition={renderFirstAnimationText}>
                        <SuperHeadingText>Getting best offers...</SuperHeadingText>
                        <SuperHeadingText>Getting best offers...</SuperHeadingText>
                        <SuperHeadingText>Ready in 30 sec, squad!</SuperHeadingText>
                    </ConditionalRender>
                </div>
            </div>
        </LoadingBlocker>
    )
}

DetermineCompany.propTypes = propTypes;
DetermineCompany.defaultProps = defaultProps;

export default DetermineCompany;
