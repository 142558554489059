// Packages
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Steps
import Disclosures from './Disclousres';
// UI
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
import Button from '../../../ui/Button';
import FreeconnectSuccessContent from '../../../ui/FreeconnectSuccessContent';
import StepperButtonsContainer from '../../../ui/StepperButtonsConatiner';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { PopupContext } from '../../../contexts/Popup';
import { ValidationContext } from '../../../contexts/Validation';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { ConfigContext } from '../../../contexts/Config';
import { QueryParamsContext } from '../../../contexts/QueryParams';
// Helpers
import { freeconnectCnx1Body } from '../../../helpers/backendRequestGenerators'

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    onNext: () => { },
    onPrev: () => { },
};

function Congrats({ step, onNext, onPrev }) {
    const { userId, company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [clickedAgree, setClickedAgree] = useState(0);
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [popupState, popupDispatch] = useContext(PopupContext);
    const [validation, validationDispatch] = useContext(ValidationContext);
    const isLoadingDispatch = useContext(IsLoadingContext)[1];
    const [environment, environmentDispatch] = useContext(EnvironmentContext);
    const [configState, configDispatch] = useContext(ConfigContext); 

    const offerText = {
        excess: "You’ve been approved for 15GB of FREE Data with Unlimited Talk and Text from Excess Telecom. Click the button below to wrap up your enrollment and see if you qualify for a brand new tablet!",
        frontier: "You’ve qualified for high-speed Internet as low as $0 a month through Frontier Communications! All there is left to do is read the terms of service and schedule your installation.",
        infiniti: "You’ve qualified for a FREE SIM Card with up to 10GB of high-speed Data from Infiniti Mobile. Click the button below to wrap up your enrollment and see if you qualify for a brand new tablet!",
        natwireless: "You’ve been approved for 10GB of FREE Data with Unlimited Talk and Text from National Wireless. Click the button below to wrap up your enrollment and see if you qualify for a brand new tablet!",
        optimum: "You’ve qualified for FREE high-speed Internet through Optimum! To wrap up, click the button below to schedule your installation date.",
        redpocket: <>You’ve qualified for FREE UNLIMITED Data, Talk & Text through Red Pocket Mobile<sup>&reg;</sup>. Click the button below to wrap up your enrollment and see if you qualify for a brand new tablet!</>,
        via: "You’ve been approved for 10GB of FREE Data with Unlimited Talk and Text from Via Wireless. Click the button below to wrap up your enrollment and see if you qualify for a brand new tablet!",
        windstream: "You’ve qualified for FREE high-speed Internet through Kinetic by Windstream! To wrap up, click the button below to schedule your installation date.",
    }

    const frontierProgramCodes = {
        SNAP: "E1",
        MEDIC: "E2",
        SSI: "E3",
        VPSBP: "E15"
    }

    useEffect(() => {
        import(/* webpackMode: "eager" */ `../styles/steps/CallNow/index.less`).catch((error) => {
            console.log(error)
        });
        import(/* webpackMode: "eager" */ `../styles/steps/CallNow/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch((error) => console.log(error))
    }, [company]);

    useEffect(() => {
        if (clickedAgree > 0) {
            onNext()
        }
    }, [clickedAgree])

    useEffect(() => {
        if (formData.serviceableCompany === "optimum") {
            if (Object.keys(configState).length > 0) {
                let cnx1 = freeconnectCnx1Body(formData, configState, QueryParamsContext)
                let headers = {
                    "X-Api-Key": environment.backendUrlCNX1.key,
                    "company": formData.serviceableCompany,
                    "Authorization": company
                }
                fetch(environment.backendUrlCNX1.url, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(cnx1),
                })
                    .then((response) => response.json())
                    .then((data) => {})
            } else {
                const headers = {
                    "X-Api-Key": process.env.LIVEVOX_X_API_KEY
                }
    
                fetch(environment.liveVoxApiUrl, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify({
                        "action": "add_contact",
                        "active": true,
                        "email": formData.email,
                        "address1": formData.address.parsedAddress.street,
                        "address2": formData.address.parsedAddress.line2,
                        "city": formData.address.parsedAddress.city,
                        "zipCode": formData.address.parsedAddress.zip,
                        "state": formData.address.parsedAddress.state,
                        "country": "UNITED_STATES_OF_AMERICA",
                        "firstName": formData.firstName,
                        "lastName": formData.lastName,
                        "phoneNumber": formData.phoneNumber,
                        "phoneOrdinal": 1,
                        "smsConsent": formData.acceptedDisclosures,
                        "cellConsent": formData.acceptedDisclosures,
                        "primaryEmailConsent": formData.acceptedDisclosures,
                        "company": "Optimum",
                        "leadStatus": "",
                        "origin": "FreeConnect.us",
                        "leadPath": formData.liveVoxLeadPath,
                        "checkForTribal": true,
                        "currentCustomer": formData.existingCustomer,
                        "serviceabilityCheckResults": true,
                        "dateOfBirth": new Date(formData.dob).getTime(),
                        "ssnLead": false,
                        "send_to_joey": true,
                    }),
                })
                    // .then((response) => response.json())
                    .then((data) => {})
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', flexGrow: '1' }}>
            <div>
                <HeadingText>Congratulations!</HeadingText>
                <SubHeadingText>{offerText[formData.serviceableCompany]}</SubHeadingText>
                <FreeconnectSuccessContent companyProp={formData.serviceableCompany} />
            </div>
            <Button
                style="loneNext"
                onClick={() => { 
                    if (formData.serviceableCompany === "frontier") {
                        formData.programs[0].programCode = frontierProgramCodes[formData.programs[0].program_code]
                        formDataDispatch({
                            type: 'ADD_PROGRAM',
                            payload: formData.programs[0]
                        })
                        formDataDispatch({
                            type: 'SET_FULL_ADDRESS',
                            payload: formData.address.parsedAddress.street + (formData.address.parsedAddress.line2 ? `, ${formData.address.parsedAddress.line2}` : "")
                        });
                        onNext()
                    } else if (formData.serviceableCompany === "optimum") {
                        onNext()
                    } else {
                        let disclosureTitle = ""
                        if (formData.serviceableCompany === "excess") {
                            disclosureTitle = "ACP Consents & Disclosures"
                        } else {
                            disclosureTitle = "Terms & Conditions"
                        }
                        popupDispatch({ type: 'ADD_POPUP', payload: {
                            title: disclosureTitle,
                            onClick: () => { popupDispatch({ type: 'REMOVE_POPUP' }) },
                            component: (
                                <>
                                    <Disclosures step={step} disableHead={true} disableCheck={true} />
                                    <Button style="disclosuresNext" onClick={() => { 
                                        setClickedAgree(prev => prev + 1)
                                    }} >
                                        Accept and Continue
                                    </Button>
                                    <Button style="disclosuresPrevious" onClick={() => { 
                                        popupDispatch({ type: 'REMOVE_POPUP' })
                                    }} >Previous</Button>
                                </>
                        )}})
                    }
                }}
            >Continue</Button>
        </div>
    );
}

Congrats.propTypes = propTypes;
Congrats.defaultProps = defaultProps;

export default Congrats;