// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender'
// UI
import LoadingBlocker from '../../../ui/LoadingBlocker'
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
import CheckBox from '../../../ui/CheckBox';
import LabelText from '../../../ui/LabelText';
import Switch from '../../../ui/Switch';
import FrontierCommunicationSwitches from '../../../ui/FrontierCommunicationSwitches';
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { StepsContext } from '../../../contexts/Steps';
import { requiredCheckbox, runMultipleValidationFunctions, requireAtLeastOneCommuniation } from '../../../helpers/validation';


// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function FrontierCommunication({ step, mode, onNext, onPrev }) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [startedTimeout, setStartedTimeout] = useState(false);
    const [formData, formDataDispatch] = useContext(FormDataContext);
    const [isLoading, isLoadingDispatch] = useContext(IsLoadingContext);
    const [steps, stepsDispatch] = useContext(StepsContext);
    const environment = useContext(EnvironmentContext)[0];

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierCommunication/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/FrontierCommunication/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    useEffect(() => {
        
    }, [])

    return (
        <>
            <HeadingText>Communication Preferences</HeadingText>
            <SubHeadingText>Let’s stay in touch. What information would you like to receive regarding your order?</SubHeadingText>
            <FrontierCommunicationSwitches validationKey={'frontierCommunicationToggles'} step={step} validationFunction={(value) => {
                return runMultipleValidationFunctions(value, [requireAtLeastOneCommuniation])
            }}/>
            <CheckBox
                name="Frontier Communication Checkbox"
                initialCheck={formData.frontierCommunication}
                onChecked={(checked) => {
                    // console.log("Frontier Communication checkbox", checked),
                    formDataDispatch({
                        type: 'SET_FRONTIER_COMMUNICATION',
                        payload: checked,
                    });
                }}
                step={step}
                validationKey="frontierCommunication"
                validationFunction={(value) =>
                    runMultipleValidationFunctions(value, [
                        requiredCheckbox
                    ])
                }
            >
                I agree that Frontier Communications and its associated partners may send me automated marketing messages to my mobile number and email address. I’m aware that consent is not a condition of service.
            </CheckBox>
        </>
    )
}

FrontierCommunication.propTypes = propTypes;
FrontierCommunication.defaultProps = defaultProps;

export default FrontierCommunication;