// Packages
import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
// Helpers
import {
    backendUrlToGetData,
    backendUrlToSetData,
    backendUrlToSubmitData,
    disclosuresBackendUrlToGetData,
    smartyVerificationUrl,
    vcareApiUrl,
    marketplaceServiceabilityBackendUrlToCheck,
    backendUrlToSetDataForKore,
    backendUrlToSetDataForSecurePii,
    urlForFrontierPredictiveSearch,
    urlForFrontierGetOffersByAddress,
    keyForFrontier,
    urlForFrontierGetOffersLowIncome,
    urlForFrontierGetDisclosures,
    urlForFrontierAcceptDisclosures,
    urlForFrontierCreateQuote,
    urlForFrontierQuoteItemPost,
    urlForFrontierQuoteItemPut,
    urlForFrontierLowIncomeByApp,
    urlForFrontierLowIncomeVerification,
    urlForFrontierEligibilityCheck,
    urlForFrontierFinishQuoteCreate,
    urlForFrontierCloseSelectOffers,
    urlForFrontierCustomerCreditCheck,
    urlForFrontierCustomerIdentityVerification,
    urlForFrontierVerifyCustomerPreviousAddress,
    urlForFrontierCustomerCreditBundle,
    urlForFrontierGetQuote,
    urlForFrontierGetDepositRequirements,
    urlForFrontierGetSchedule,
    urlForFrontierFinishReserveSchedule,
    urlForFrontierSubmitCustomerJourney,
    urlForFrontierGetOffersDisplay,
    keyForFrontierGetOffersByAddress,
    keyForFrontierPredictiveSearch,
    backendUrlToAddToLiveVox,
    backendUrlOptimumServiceability,
    backendURLFCUSServiceability,
    backendURLSpectrumServiceability,
    backendURLFixedRedirectReporting,
    backendURLActiveProspect,
    backendUrlGetParameter,
    backendUrlCNX1,
    backendUrlToGenerateDisclosuresPdf,
    backendUrlForUtilitiesStack,
    backendUrlForAceUsersDB,
    backendUrlKlaviyo,
    backendUrlForMetaConversionAPI,
    backendUrlCTAMReporting,
    backendUrlComplianceOrderSubmission,
    backendUrlSesEmail
} from '../helpers/backendURL';
// Contexts
import { EnvironmentContext } from '../contexts/Environment';
import { IsLoadingContext } from '../contexts/IsLoading';

function SetUpEnvironment() {
    const { environment } = useParams();
    const [environmentState, environmentDispatch] = useContext(EnvironmentContext);
    const isLoadingDispatch = useContext(IsLoadingContext)[1];

    useEffect(() => {
        isLoadingDispatch({ type: "ADD_PAGE_BLOCKER", payload: "setUpEnvironment" })
    }, [])

    useEffect(() => {
        if (environmentState.backendUrlToGetData && environmentState.backendUrlToSetData) {
            isLoadingDispatch({ type: "REMOVE_PAGE_BLOCKER", payload: "setUpEnvironment" })
        }
    }, [environmentState]);

    useEffect(() => {
        // console.log("ENVIRONMENT IN JSX:", environment)
        environmentDispatch({ type: 'SET_BACKEND_URL_TO_GET_DATA', payload: backendUrlToGetData(environment) });
        environmentDispatch({ type: 'SET_BACKEND_URL_TO_SET_DATA', payload: backendUrlToSetData(environment) });
        environmentDispatch({ type: 'SET_BACKEND_URL_TO_SUBMIT_DATA', payload: backendUrlToSubmitData(environment) });
        environmentDispatch({ type: 'SET_SECURE_PII_URL', payload: backendUrlToSetDataForSecurePii(environment) });

        environmentDispatch({
            type: 'SET_DISCLOSURES_BACKEND_URL_TO_GET_DATA',
            payload: disclosuresBackendUrlToGetData(environment),
        });
        environmentDispatch({
            type: 'SET_SMARTY_URL',
            payload: smartyVerificationUrl(environment),
        });
        environmentDispatch({
            type: 'SET_VCARE_URL',
            payload: vcareApiUrl(environment),
        });
        environmentDispatch({
            type: 'SET_MARKETPLACE_SERVICEABILITY_URL',
            payload: marketplaceServiceabilityBackendUrlToCheck(environment),
        });
        environmentDispatch({
            type: 'SET_KORE_SET_URL',
            payload: backendUrlToSetDataForKore(environment),
        });
        environmentDispatch({
            type: 'SET_FRONTIER_PREDICTIVE_SEARCH_URL',
            payload: { 
                url: urlForFrontierPredictiveSearch(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_GET_OFFERS_BY_ADDRESS_URL',
            payload: { 
                url: urlForFrontierGetOffersByAddress(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_GET_OFFERS_LOW_INCOME_URL',
            payload: { 
                url: urlForFrontierGetOffersLowIncome(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_QUOTE_ITEM_POST_URL',
            payload: { 
                url: urlForFrontierQuoteItemPost(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_QUOTE_ITEM_PUT_URL',
            payload: { 
                url: urlForFrontierQuoteItemPut(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_GET_DISCLOSURES_URL',
            payload: { 
                url: urlForFrontierGetDisclosures(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_ACCEPT_DISCLOSURES_URL',
            payload: { 
                url: urlForFrontierAcceptDisclosures(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_CREATE_QUOTE_URL',
            payload: { 
                url: urlForFrontierCreateQuote(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_LOW_INCOME_BY_APP_URL',
            payload: { 
                url: urlForFrontierLowIncomeByApp(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_ELIGIBILITY_CHECK_URL',
            payload: { 
                url: urlForFrontierEligibilityCheck(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_LOW_INCOME_VERIFICATION_URL',
            payload: { 
                url: urlForFrontierLowIncomeVerification(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_FINISH_QUOTE_CREATE_URL',
            payload: { 
                url: urlForFrontierFinishQuoteCreate(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_CLOSE_SELECT_OFFERS_URL',
            payload: { 
                url: urlForFrontierCloseSelectOffers(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_CUSTOMER_CREDIT_CHECK_URL',
            payload: { 
                url: urlForFrontierCustomerCreditCheck(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_CUSTOMER_IDENTITY_VERIFICATION_URL',
            payload: { 
                url: urlForFrontierCustomerIdentityVerification(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_VERIFY_CUSTOMER_PREVIOUS_ADDRESS_URL',
            payload: { 
                url: urlForFrontierVerifyCustomerPreviousAddress(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_CUSTOMER_CREDIT_BUNDLE_URL',
            payload: { 
                url: urlForFrontierCustomerCreditBundle(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_GET_QUOTE_URL',
            payload: { 
                url: urlForFrontierGetQuote(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_GET_DEPOSIT_REQUIREMENTS_URL',
            payload: { 
                url: urlForFrontierGetDepositRequirements(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_GET_SCHEDULE_URL',
            payload: { 
                url: urlForFrontierGetSchedule(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_FINISH_RESERVE_SCHEDULE_URL',
            payload: { 
                url: urlForFrontierFinishReserveSchedule(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_SUBMIT_CUSTOMER_JOURNEY_URL',
            payload: { 
                url: urlForFrontierSubmitCustomerJourney(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_FRONTIER_GET_OFFERS_DISPLAY_URL',
            payload: { 
                url: urlForFrontierGetOffersDisplay(environment),
                key: keyForFrontier(environment),
            },
        });
        environmentDispatch({
            type: 'SET_LIVE_VOX_API_URL',
            payload: backendUrlToAddToLiveVox(environment),
        });
        environmentDispatch({
            type: 'SET_OPTIMUM_SERVICEABILITY',
            payload: backendUrlOptimumServiceability(environment),
        });
        environmentDispatch({
            type: 'SET_FCUS_SERVICEABILITY_CHECK',
            payload: backendURLFCUSServiceability(environment),
        });
        environmentDispatch({
            type: 'SET_SPECTRUM_SERVICEABILITY_CHECK',
            payload: backendURLSpectrumServiceability(environment),
        });
        environmentDispatch({
            type: 'SET_FIXED_REDIRECT_URL',
            payload: backendURLFixedRedirectReporting(environment),
        });
        environmentDispatch({
            type: 'SET_ACTIVE_PROSPECT_URL',
            payload: backendURLActiveProspect(environment),
        });
        environmentDispatch({
            type: 'SET_GET_PARAMETER_URL',
            payload: backendUrlGetParameter(environment),
        });
        environmentDispatch({
            type: 'SET_CNX1_URL',
            payload: backendUrlCNX1(environment),
        });
        environmentDispatch({
            type: 'SET_KLAVIYO_URL',
            payload: backendUrlKlaviyo(environment),
        });
        environmentDispatch({
            type: 'SET_PDF_GEN',
            payload: backendUrlToGenerateDisclosuresPdf(environment),
        });
        environmentDispatch({
            type: 'SET_UTILITY_URL',
            payload: backendUrlForUtilitiesStack(environment),
        });
        environmentDispatch({
            type: 'SET_ACE_DB_URL',
            payload: backendUrlForAceUsersDB(environment),
        });
        environmentDispatch({
            type: 'SET_META_CONVERSION_API',
            payload: backendUrlForMetaConversionAPI(environment),
        });
        environmentDispatch({
            type: 'SET_CTAM_REPORTING_URL',
            payload: backendUrlCTAMReporting(environment),
        });
        environmentDispatch({
            type: 'SET_COMPLIANCE_ORDER_SUBMISSION_URL',
            payload: backendUrlComplianceOrderSubmission(environment),
        });
        environmentDispatch({
            type: 'SET_SES_EMAIL_URL',
            payload: backendUrlSesEmail(environment),
        });
    }, [environment]);

    return '';
}

export default SetUpEnvironment;
