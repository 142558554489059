// Packages
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../../../components/ConditionalRender';
// UI
import Button from '../../../ui/Button';
import ShowMore from '../../../ui/ShowMore';
import LoadingBlocker from '../../../ui/LoadingBlocker';
import HeadingText from '../../../ui/HeadingText';
import SubHeadingText from '../../../ui/SubHeadingText';
import ConfirmAddressDisplay from '../../../ui/ConfirmAddressDisplay';
// Helpers
import { hardcodedPrograms } from '../../../helpers/programs'
// Contexts
import { FormDataContext } from '../../../contexts/FormData';
import { EnvironmentContext } from '../../../contexts/Environment';
import { IsLoadingContext } from '../../../contexts/IsLoading';
import { ValidationContext } from '../../../contexts/Validation';
import { PopupContext } from '../../../contexts/Popup';

// Props of the Component
const propTypes = {
    step: PropTypes.number,
    mode: PropTypes.string,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
const defaultProps = {
    step: -1,
    mode: 'form',
    onNext: () => {},
    onPrev: () => {},
};

function ConfirmAddress({ step, mode, onNext, onPrev }) {
    const { company } = useParams();
    const [classNames, setClassNames] = useState({});
    const [formDataState, formDataDispatch] = useContext(FormDataContext);
    const [validation, validationDispatch] = useContext(ValidationContext);
    const [popupState, popupDispatch] = useContext(PopupContext);

    useEffect(() => {
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/ConfirmAddress/index.less`)
        import(/* webpackPreload: true */ `../../../companies/${company}/styles/steps/ConfirmAddress/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
    }, [company]);

    return (
        <div className={classNames.centerItems}>
            <SubHeadingText>Did we get everything right? This is your last chance to edit your information before we move on, so please double-check that it's good to go.</SubHeadingText>
            <ConfirmAddressDisplay
                title="Home Address" 
                addressObject={formDataState.address} 
                editButtonOnClick={() => { 
                    validationDispatch({ type: "SET_ADDRESSOBJECT_ISVALID", payload: false })

                    if (formDataState.shippingAddress !== null) {
                        validationDispatch({ type: 'SET_SHIPPINGADDRESSOBJECT', payload: { isValid: false, step }});
                    }

                    popupDispatch({ type: 'REMOVE_POPUP' }) 
                }} 
            />
            <ConditionalRender condition={formDataState.shippingAddress}>
                <ConfirmAddressDisplay 
                    title="Mailing Address" 
                    addressObject={formDataState.shippingAddress} 
                    editButtonOnClick={() => { 
                        validationDispatch({ type: "SET_ADDRESSOBJECT_ISVALID", payload: false })
                        validationDispatch({ type: 'SET_SHIPPINGADDRESSOBJECT', payload: { isValid: false, step }});
                        popupDispatch({ type: 'REMOVE_POPUP' }) 
                    }} 
                />
            </ConditionalRender>
        </div>
    );
}

ConfirmAddress.propTypes = propTypes;
ConfirmAddress.defaultProps = defaultProps;

export default ConfirmAddress;
