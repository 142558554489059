// Packages
import React, { useState, useEffect, useContext, Fragment, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ImInfo } from 'react-icons/im';
import { MdLocationOn } from 'react-icons/md';
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import TextField from './TextField';
import Button from './Button';
import SmallText from './SmallText';
import LoadingBlocker from './LoadingBlocker';
// Helpers
import {
    parseAddressObject,
    querySmartyAutoComplete,
    reverseParseAddressObject,
    parseSmartyValidationObject,
} from '../helpers/smarty';
import { usState } from '../helpers/usStates';
// Contexts
import { ValidationContext } from '../contexts/Validation';
import { EnvironmentContext } from '../contexts/Environment';
import DropDown from './DropDown';
import BodyText from './BodyText';

// Props of the Component
const propTypes = {
    onChange: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string,
    validationKey: PropTypes.string,
    validationFunction: PropTypes.func,
    line1ValidationFunction: PropTypes.func,
    line2ValidationFunction: PropTypes.func,
    cityValidationFunction: PropTypes.func,
    stateValidationFunction: PropTypes.func,
    zipValidationFunction: PropTypes.func,
    step: PropTypes.number,
    inputClassName: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    initialValue: PropTypes.object,
    disableAutocompleteProp: PropTypes.bool,
    addAsterisk: PropTypes.bool,
    line2Label: PropTypes.string,
    subLabelText: PropTypes.string,
    poweredByText: PropTypes.string
};
const defaultProps = {
    onChange: () => {},
    children: '',
    className: '',
    validationKey: '',
    inputClassName: '',
    validationFunction: () => {},
    step: null,
    initialValue: { addressLine1: '', parsedAddress: {} },
    line1ValidationFunction: () => ({ isValid: true, message: '' }),
    line2ValidationFunction: () => ({ isValid: true, message: '' }),
    cityValidationFunction: () => ({ isValid: true, message: '' }),
    stateValidationFunction: () => ({ isValid: true, message: '' }),
    zipValidationFunction: () => ({ isValid: true, message: '' }),
    disableAutocompleteProp: false,
    addAsterisk: false,
    line2Label: "Apt, Unit, or Lot #",
    subLabelText: "",
    poweredByText: ""
};

function AddressField({
    onChange,
    children,
    className,
    validationKey,
    validationFunction,
    step,
    inputClassName,
    initialValue,
    line1ValidationFunction,
    line2ValidationFunction,
    cityValidationFunction,
    stateValidationFunction,
    zipValidationFunction,
    disableAutocompleteProp,
    addAsterisk,
    line2Label,
    subLabelText,
    poweredByText
}) {
    const { company, userId } = useParams();
    const [classNames, setClassNames] = useState({});
    const [value, setValue] = useState(initialValue || {});
    const [isValidState, setIsValidState] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');
    const [line1IsValidState, setLine1IsValidState] = useState(true);
    const [line1ValidationMessage, setLine1ValidationMessage] = useState('');
    const [line2IsValidState, setLine2IsValidState] = useState(true);
    const [line2ValidationMessage, setLine2ValidationMessage] = useState('');
    const [cityIsValidState, setCityIsValidState] = useState(true);
    const [cityValidationMessage, setCityValidationMessage] = useState('');
    const [stateIsValidState, setStateIsValidState] = useState(true);
    const [stateValidationMessage, setStateValidationMessage] = useState('');
    const [zipIsValidState, setZipIsValidState] = useState(true);
    const [zipValidationMessage, setZipValidationMessage] = useState('');
    const [timer, setTimer] = useState(0);
    const [lastSmartyLookup, setLastSmartyLookup] = useState('');
    const [lastSmartyLookupLine2, setLastSmartyLookupLine2] = useState('');
    const [autoCompleteAddresses, setAutoCompleteAddresses] = useState([]);
    const [autoCompleteAddressesLine2, setAutoCompleteAddressesLine2] = useState([]);
    const [disableAutocomplete, setDisableAutocomplete] = useState(false);
    const [selectedAutoCompleteOption, setSelectedAutoCompleteOption] = useState(false);
    const [disableLine2Autocomplete, setDisableLine2Autocomplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [secondaryDesignator, setSecondaryDesignator] = useState("");
    const [validation, ValidationDispatch] = useContext(ValidationContext);
    const environmentState = useContext(EnvironmentContext)[0];
    const [storedReloadValidation, setStoredReloadValidation] = useState(validation.reloadValidation);
    const addressLine1Ref = React.useRef(null);
    const addressLine2Ref = React.useRef(null);
    const scrollToAddressLine1 = () => {
        addressLine1Ref.current.scrollIntoView({
            behavior: "smooth"
        });
    };
    const scrollToAddressLine2 = () => {
        addressLine2Ref.current.scrollIntoView({
            behavior: "smooth"
        });
    };


    useEffect(() => {
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/AddressField/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/AddressField/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/AddressField.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/AddressField.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/AddressSugestions/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/AddressSugestions/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/AddressSugestions.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/AddressSugestions.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/AddressUnitSugestions/index.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/ui/AddressUnitSugestions/index.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/variables/components/AddressUnitSugestions.less`).catch(() => {
            import(/* webpackMode: "eager" */ `../companies/default/styles/variables/components/AddressUnitSugestions.less`);
        });
        import(/* webpackPreload: true */ `../companies/${company}/styles/ui/AddressField/classNames.js`)
            .then((module) => {
                setClassNames(module.default);
            })
            .catch(() => {
                import(/* webpackMode: "eager" */ `../companies/default/styles/ui/AddressField/classNames.js`).then(
                    (module) => {
                        setClassNames(module.default);
                    }
                );
            });
    }, [company]);

    useEffect(() => {
        const asyncFunction = async () => {
            if (validationKey) {
                if (validation.reloadValidation > storedReloadValidation) {
                    ValidationDispatch({
                        type: `SET_WAITING_TRUE`,
                        payload: validationKey,
                    });

                    const headers = {
                        Authorization: process.env.SMARTY_AUTHORIZATION,
                        'x-api-key': process.env.SMARTY_X_API_KEY,
                    };
                    const body = {
                        line_one: value?.parsedAddress?.street,
                        line_two: value?.parsedAddress?.line2,
                        city: value?.parsedAddress?.city,
                        state: value?.parsedAddress?.state,
                        zip: value?.parsedAddress?.zip,
                        user_id: userId,
                    };
                    console.log("ALERT VALIDATION!!!")

                    let smartyObject = { data: {} }

                    if (value?.parsedAddress?.street) {
                        const smartyResponse = await fetch(environmentState.smartyUrl, {
                            method: 'POST',
                            headers,
                            body: JSON.stringify(body),
                        });
                        smartyObject = await smartyResponse.json();   
                    }

                    const Line1Validation = line1ValidationFunction(value?.parsedAddress?.street);
                    const Line2Validation = line2ValidationFunction(value?.parsedAddress?.line2);
                    const cityValidation = cityValidationFunction(value?.parsedAddress?.city);
                    const stateValidation = stateValidationFunction(value?.parsedAddress?.state);
                    const zipValidation = zipValidationFunction(value?.parsedAddress?.zip);
                    const isLine1Valid = Line1Validation?.isValid;
                    const isLine2Valid = Line2Validation?.isValid;
                    const isCityValid = cityValidation?.isValid;
                    const isStateValid = stateValidation?.isValid;
                    const isZipValid = zipValidation?.isValid;
                    const { isValid, message } = validationFunction(smartyObject);

                    setLine1IsValidState(isLine1Valid)
                    setLine1ValidationMessage(Line1Validation.message)
                    setLine2IsValidState(isLine2Valid)
                    setLine2ValidationMessage(Line2Validation.message)
                    setCityIsValidState(isCityValid)
                    setCityValidationMessage(cityValidation.message)
                    setStateIsValidState(isStateValid)
                    setStateValidationMessage(stateValidation.message)
                    setZipIsValidState(isZipValid)
                    setZipValidationMessage(zipValidation.message)

                    if (isValid) {
                        const validatedAddressObject = parseSmartyValidationObject(smartyObject.data);

                        setValue(validatedAddressObject);
                        onChange(validatedAddressObject);
                    }

                    if (message === 'Missing suite, apartment or lot number' || message === 'Please enter a valid suite, apartment or lot number (leave blank if none)') {
                        setLine2IsValidState(isValid)
                        setLine2ValidationMessage(message)
                        setIsValidState(true);
                        setValidationMessage("");
                        scrollToAddressLine2()
                    } else {
                        setIsValidState(isValid);
                        setValidationMessage(message);
                        setLine2IsValidState(true)
                        setLine2ValidationMessage("")
                        scrollToAddressLine1()
                    }
                    ValidationDispatch({
                        type: `SET_${validationKey.toUpperCase()}`,
                        payload: {
                            step,
                            // If you want all fields to count towards validation
                            // isValid: isValid && isLine1Valid && isLine2Valid && isCityValid && isStateValid && isZipValid,
                            isValid: isValid,
                        },
                    });

                    ValidationDispatch({
                        type: `SET_WAITING_FALSE`,
                        payload: validationKey,
                    });
                }
            }
        };

        asyncFunction();
    }, [validation.reloadValidation, storedReloadValidation]);

    useEffect(() => {
        if ((validationKey && step) || step === 0) {
            ValidationDispatch({
                type: `SET_${validationKey.toUpperCase()}_STEP`,
                payload: step,
            });
        }
    }, [step]);

    useEffect(() => {
        setStoredReloadValidation(validation.reloadValidation);
    }, []);
    useEffect(() => {
        const timerMilliseconds = 1000;
        setInterval(() => {
            setTimer((prev) => prev + timerMilliseconds);
        }, timerMilliseconds);
    }, []);

    useEffect(() => {
        const asyncFunction = async () => {
            if (value?.parsedAddress?.street && !disableAutocomplete) {
                // console.log('ALERT!!!');
                let preferedZip = []
                if (initialValue?.preferedZip) {
                    preferedZip = [initialValue.preferedZip]
                }
                setAutoCompleteAddresses(await querySmartyAutoComplete(reverseParseAddressObject(value, true), preferedZip));
            } else {
                setAutoCompleteAddresses([]);
            }
            setLastSmartyLookup(value?.parsedAddress?.street);
        };

        if (value?.parsedAddress?.street !== lastSmartyLookup) {
            asyncFunction();
        }
    }, [value?.parsedAddress?.street]);
    useEffect(() => {
        const asyncFunction = async () => {
            if (value?.parsedAddress?.line2 && !disableLine2Autocomplete) {
                // console.log('ALERT LINE2!!!');
                let preferedZip = []
                if (initialValue?.preferedZip) {
                    preferedZip = [initialValue.preferedZip]
                }
                setAutoCompleteAddressesLine2(await querySmartyAutoComplete(reverseParseAddressObject(value, true), preferedZip));
            } else {
                setAutoCompleteAddressesLine2([]);
            }
            setLastSmartyLookupLine2(value?.parsedAddress?.line2);
        };

        if (value?.parsedAddress?.line2 !== lastSmartyLookupLine2) {
            asyncFunction();
        }
    }, [value?.parsedAddress?.line2]);

    useEffect(() => {
        if (!validationKey) {
            const addressObject = { parsedAddress: { ...value.parsedAddress } };

            addressObject.addressLine1 = value?.parsedAddress?.street ? value?.parsedAddress?.street : '';
            addressObject.addressLine1 += value?.parsedAddress?.city ? `, ${value?.parsedAddress?.city}` : '';
            addressObject.addressLine1 += value?.parsedAddress?.state ? `, ${value?.parsedAddress?.state}` : '';
            addressObject.addressLine1 += value?.parsedAddress?.zip ? `, ${value?.parsedAddress?.zip}` : '';

            if (value?.parsedAddress?.line2) {
                addressObject.addressLine2 = value?.parsedAddress?.line2;
            }

            onChange(addressObject);
        }
    }, [value]);

    const handleBlur = (event) => {
        const { currentTarget } = event;
        requestAnimationFrame(() => {
            if (!currentTarget.contains(document.activeElement)) {
                setAutoCompleteAddresses([]);
                setDisableAutocomplete(true);
                setDisableLine2Autocomplete(true);
            }
        })
    };
    const handleLine2Blur = (event) => {
        const { currentTarget } = event;
        requestAnimationFrame(() => {
            if (!currentTarget.contains(document.activeElement)) {
                setDisableAutocomplete(false);
                setDisableLine2Autocomplete(true);
            }
        });
    };

    return (
        <div
            className={classNames.addressFieldContainer}
            onFocus={() => {
                setDisableAutocomplete(false);
                setDisableLine2Autocomplete(false);
            }}
            onBlur={handleBlur}
            ref={addressLine1Ref}
        >
            <TextField
                innerRef={addressLine1Ref}
                setIsValidStateTo={isValidState}
                setValidationMessageTo={validationMessage}
                autoComplete="off"
                initialValue={initialValue?.parsedAddress?.street}
                setValueTo={value?.parsedAddress?.street}
                name={`${children} line one`}
                validationKey="line1"
                validationFunction={line1ValidationFunction}
                step={step}
                inputClassName={inputClassName}
                className={`${classNames.label} ${className}`}
                placeHolder="123 Main St"
                onChange={(line1) => {
                    setValue((prev) => ({ parsedAddress: { ...prev.parsedAddress, street: line1 } }));
                    setValue({ parsedAddress: { street: line1, state: "", zip: "", city: "", line2: "" } })
                    setIsValidState(true)
                    setValidationMessage("")
                    setLine1IsValidState(true)
                    setLine1ValidationMessage("")
                    setLine2IsValidState(true)
                    setLine2ValidationMessage("")
                    setCityIsValidState(true)
                    setCityValidationMessage("")
                    setStateIsValidState(true)
                    setStateValidationMessage("")
                    setZipIsValidState(true)
                    setZipValidationMessage("")
                    // This if will only clear fields if user selected an option
                    // if (selectedAutoCompleteOption) {
                    //     setValue({ parsedAddress: { street: line1, state: "", zip: "", city: "", line2: "" } })
                    // }
                }}
                isDropdownPresent={autoCompleteAddresses.length > 0 && !(autoCompleteAddressesLine2.length > 0) && !disableAutocomplete && !disableAutocompleteProp}
                dropdown={autoCompleteAddresses.map((address, i) => {
                        if (
                            i < 5 && 
                            `${address.streetLine}, ${address.city}, ${address.state}, ${address.zipcode}` !== 
                            `${value.parsedAddress.street}, ${value.parsedAddress.city}, ${value.parsedAddress.state}, ${value.parsedAddress.zip}`
                        ) {
                            if (disableAutocomplete) {
                                setDisableAutocomplete(false);
                            }
                            return (
                                <button
                                    key={i}
                                    className={`${classNames.button} ${i === autoCompleteAddresses.length - 1 ? classNames.buttonLast : '' }`}
                                    // eslint-disable-next-line react/style-prop-object
                                    onClick={() => { 
                                        const parsedAddress = parseAddressObject(address);

                                        if (address.secondary) {
                                            setValue((prev) => ({
                                                ...prev,
                                                parsedAddress: {
                                                    ...parsedAddress.parsedAddress,
                                                    line2: address.secondary + " ",
                                                    entries: 5,
                                                },
                                            }));
                                            setAutoCompleteAddresses([]);
                                            setSelectedAutoCompleteOption(true)
                                            setSecondaryDesignator(address.secondary + " ")
                                        } else {
                                            setValue(parsedAddress);
                                            setAutoCompleteAddresses([]);
                                            setSelectedAutoCompleteOption(true)
                                        }
                                    }}
                                >
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="13" cy="13" r="13" fill="#F0F0F0"/>
                                        <path d="M12.9997 4C9.68607 4 7 6.7966 7 10.2466C7 10.8394 7.08126 11.4124 7.22936 11.9554C7.32041 12.2902 7.42069 12.622 7.57801 12.9226C8.80838 15.268 12.9997 21.9994 12.9997 21.9994C12.9997 21.9994 17.1916 15.2674 18.422 12.9226C18.5793 12.622 18.679 12.2902 18.7706 11.9554C18.9187 11.4118 19 10.8394 19 10.2466C19 6.7966 16.3134 4 12.9997 4ZM12.9997 12.9976C12.1629 12.9976 11.4161 12.5902 10.932 11.956C10.573 11.4862 10.358 10.8922 10.358 10.2472C10.358 8.7304 11.5434 7.4968 13.0003 7.4968C14.4571 7.4968 15.642 8.731 15.642 10.2472C15.642 10.8928 15.427 11.4868 15.068 11.956C14.5833 12.5902 13.8371 12.9976 13.0003 12.9976H12.9997Z" fill="#151515"/>
                                    </svg>
                                    <div className={classNames.suggestionContainer}>
                                        {address.streetLine}
                                        <SmallText>{`${address.city}, ${address.state}, ${address.zipcode}`}</SmallText>
                                    </div>
                                </button>
                            );
                        }
                        if (i === autoCompleteAddresses.length - 1 && !disableAutocomplete) {
                            if (
                                autoCompleteAddresses.filter(
                                    (addressObject) =>
                                    `${addressObject.streetLine}, ${addressObject.city}, ${addressObject.state}, ${addressObject.zipcode}` !== 
                                    `${value.parsedAddress.street}, ${value.parsedAddress.city}, ${value.parsedAddress.state}, ${value.parsedAddress.zip}`
                                ).length <= 0
                            ) {
                                setDisableAutocomplete(true);
                            }
                        }
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        return <Fragment key={i}/>;
                    })
                }
            >
                <>{children}<ConditionalRender condition={addAsterisk}>*</ConditionalRender></>
                <ConditionalRender condition={subLabelText != ""}>
                    <BodyText className={classNames.sublabelText}>{subLabelText}</BodyText>
                </ConditionalRender>
            </TextField>
            <ConditionalRender condition={poweredByText != ""}>
                <BodyText className={classNames.poweredByText}>{poweredByText}</BodyText>
            </ConditionalRender>
            <div
                onFocus={() => {
                    setDisableLine2Autocomplete(false);
                    setAutoCompleteAddresses([]);
                    setDisableAutocomplete(true);
                }}
                onBlur={handleLine2Blur}
                ref={addressLine2Ref}
            >
                <TextField
                    autoComplete="off"
                    initialValue={initialValue?.parsedAddress?.line2}
                    setValueTo={value?.parsedAddress?.line2}
                    name={`${children} line two`}
                    staticValue={secondaryDesignator}
                    // validationKey="line2"
                    // validationFunction={line2ValidationFunction}
                    setIsValidStateTo={line2IsValidState}
                    setValidationMessageTo={line2ValidationMessage}
                    step={step}
                    inputClassName={inputClassName}
                    className={`${classNames.label} ${className}`}
                    placeHolder="Apt 123"
                    onChange={(line2) => {
                        setValue((prev) => ({ parsedAddress: { ...prev.parsedAddress, line2 } }));
                        setLine2IsValidState(true)
                        setLine2ValidationMessage("")
                    }}
                    isDropdownPresent={autoCompleteAddressesLine2.length > 0 && !disableLine2Autocomplete  && !disableAutocompleteProp}
                    dropdown={autoCompleteAddressesLine2.map((address, i) => {
                        if (i < 5 && value?.parsedAddress?.line2 !== address.secondary && address.secondary) {
                            if (disableLine2Autocomplete) {
                                setDisableLine2Autocomplete(false);
                            }
                            return (
                                <button
                                    key={i}
                                    className={`${classNames.button} ${i === 4 ? classNames.buttonLast : '' }`}
                                    // eslint-disable-next-line react/style-prop-object
                                    onClick={() => { 
                                        setValue((prev) => ({
                                            ...prev,
                                            parsedAddress: { ...prev.parsedAddress, line2: address.secondary },
                                        }));
                                        setAutoCompleteAddressesLine2([]);
                                     }}
                                >
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="13" cy="13" r="13" fill="#F0F0F0"/>
                                        <path d="M12.9997 4C9.68607 4 7 6.7966 7 10.2466C7 10.8394 7.08126 11.4124 7.22936 11.9554C7.32041 12.2902 7.42069 12.622 7.57801 12.9226C8.80838 15.268 12.9997 21.9994 12.9997 21.9994C12.9997 21.9994 17.1916 15.2674 18.422 12.9226C18.5793 12.622 18.679 12.2902 18.7706 11.9554C18.9187 11.4118 19 10.8394 19 10.2466C19 6.7966 16.3134 4 12.9997 4ZM12.9997 12.9976C12.1629 12.9976 11.4161 12.5902 10.932 11.956C10.573 11.4862 10.358 10.8922 10.358 10.2472C10.358 8.7304 11.5434 7.4968 13.0003 7.4968C14.4571 7.4968 15.642 8.731 15.642 10.2472C15.642 10.8928 15.427 11.4868 15.068 11.956C14.5833 12.5902 13.8371 12.9976 13.0003 12.9976H12.9997Z" fill="#151515"/>
                                    </svg>
                                    <div className={classNames.suggestionContainer}>
                                        {address.secondary}
                                    </div>
                                </button>
                            );
                        }
                        if (i === autoCompleteAddressesLine2.length - 1 && !disableLine2Autocomplete) {
                            if (
                                autoCompleteAddressesLine2.filter(
                                    (addressObject) =>
                                        value?.parsedAddress?.line2 !== addressObject.secondary &&
                                        addressObject.secondary
                                ).length <= 0
                            ) {
                                setDisableLine2Autocomplete(true);
                            }
                        }
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        return <Fragment key={i}/>;
                    })}
                >
                    {line2Label}
                </TextField>
            </div>
            <TextField
                initialValue={initialValue?.parsedAddress?.city}
                setValueTo={value?.parsedAddress?.city}
                name={`${children} city`}
                // validationKey="city"
                // validationFunction={cityValidationFunction}
                setIsValidStateTo={cityIsValidState}
                setValidationMessageTo={cityValidationMessage}
                step={step}
                inputClassName={inputClassName}
                className={`${classNames.label} ${className}`}
                placeHolder="Anytown"
                onChange={(city) => {
                    setSelectedAutoCompleteOption(false)
                    setValue((prev) => ({ parsedAddress: { ...prev.parsedAddress, city } }));
                    setCityIsValidState(true)
                    setCityValidationMessage("")
                }}
            >
                <>City<ConditionalRender condition={addAsterisk}>*</ConditionalRender></>
            </TextField>
            <div className={classNames.stateZipConatiner}>
                <DropDown
                    fullWidth={true}
                    inputClassName={classNames.stateDropdown}
                    initialValue={initialValue?.parsedAddress?.state}
                    setValueTo={value?.parsedAddress?.state}
                    name={`${children} state`}
                    // validationKey="state"
                    // validationFunction={stateValidationFunction}
                    setIsValidStateTo={stateIsValidState}
                    setValidationMessageTo={stateValidationMessage}
                    step={step}
                    className={`${classNames.label} ${className}`}
                    onChange={(state) => {
                        setSelectedAutoCompleteOption(false)
                        setValue((prev) => ({ parsedAddress: { ...prev.parsedAddress, state } }));
                        setStateIsValidState(true)
                        setStateValidationMessage("")
                    }}
                    options={usState}
                >
                    <>State<ConditionalRender condition={addAsterisk}>*</ConditionalRender></>
                </DropDown>
                <TextField
                    initialValue={initialValue?.parsedAddress?.zip}
                    setValueTo={value?.parsedAddress?.zip}
                    name={`${children} zip code`}
                    // validationKey="zip"
                    // validationFunction={zipValidationFunction}
                    setIsValidStateTo={zipIsValidState}
                    setValidationMessageTo={zipValidationMessage}
                    step={step}
                    inputClassName={inputClassName}
                    className={`${classNames.label} ${className}`}
                    placeHolder="12345"
                    onChange={(zip) => {
                        setSelectedAutoCompleteOption(false)
                        setValue((prev) => ({ parsedAddress: { ...prev.parsedAddress, zip } }));
                        setZipIsValidState(true)
                        setZipValidationMessage("")
                    }}
                >
                    <>Zip Code<ConditionalRender condition={addAsterisk}>*</ConditionalRender></>
                </TextField>
            </div>
        </div>
    );
}

AddressField.propTypes = propTypes;
AddressField.defaultProps = defaultProps;

export default AddressField;
